import React, { createContext, useState } from "react";

// Create the context
export const OnboardingContext = createContext();

const randomId = () => Math.random().toString(36).substring(2, 11);

const DEFAULT_CATEGORIES = [
  {
    name: "Housing",
    emoji: "🏠",
    id: randomId(),
    type: "expense",
  },
  {
    name: "Groceries",
    emoji: "🥦",
    id: randomId(),
    type: "expense",
  },
  {
    name: "Transportation",
    emoji: "🚕",
    id: randomId(),
    type: "expense",
  },
  {
    name: "Utilities",
    emoji: "💡",
    id: randomId(),
    type: "expense",
  },
  {
    name: "Entertainment",
    emoji: "🎉",
    id: randomId(),
    type: "expense",
  },
  // income categories
  {
    name: "Salary",
    emoji: "💼",
    id: randomId(),
    type: "income",
  },
  {
    name: "Freelance Work",
    emoji: "🖥️",
    id: randomId(),
    type: "income",
  },
  {
    name: "Investments",
    emoji: "📈",
    id: randomId(),
    type: "income",
  },
  {
    name: "Business Income",
    emoji: "📊",
    id: randomId(),
    type: "income",
  },
  {
    name: "Gifts & Bonuses",
    emoji: "🎁",
    id: randomId(),
    type: "income",
  },
];

const DEFAULT_WALLETS = [
  {
    name: "Cash",
    icon: "cash",
    id: randomId(),
  },
  {
    name: "Bank",
    icon: "savings",
    id: randomId(),
  },
  {
    name: "E-wallet",
    icon: "wallet",
    id: randomId(),
  },
];

// Create the provider component
export const OnboardingProvider = ({ children, otherState }) => {
  // State for categories
  const [categories, setCategories] = useState(DEFAULT_CATEGORIES);

  // State for wallets
  const [wallets, setWallets] = useState(DEFAULT_WALLETS);

  // Function to add a new category or edit existing category
  const addCategory = (category) => {
    const existingCategoryIndex = categories.findIndex(
      (c) => c.id === category.id
    );
    if (existingCategoryIndex !== -1) {
      const updatedCategories = [...categories];
      updatedCategories[existingCategoryIndex] = category;
      setCategories(updatedCategories);
    } else {
      setCategories([...categories, category]);
    }
  };

  // Function to delete a category
  const deleteCategory = (categoryId) => {
    const updatedCategories = categories.filter((c) => c.id !== categoryId);
    setCategories(updatedCategories);
  };

  // Function to add a new wallet or edit existing wallet
  const addWallet = (wallet) => {
    const existingWalletIndex = wallets.findIndex((w) => w.id === wallet.id);
    if (existingWalletIndex !== -1) {
      const updatedWallets = [...wallets];
      updatedWallets[existingWalletIndex] = wallet;
      setWallets(updatedWallets);
    } else {
      setWallets([...wallets, wallet]);
    }
  };

  // Context value
  const value = {
    categories,
    wallets,
    addCategory,
    deleteCategory,
    addWallet,
    ...otherState,
  };

  // Return the provider with the context value
  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => React.useContext(OnboardingContext);
