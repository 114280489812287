// @TODO

import classNames from "classnames";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";
import React, { useCallback, useEffect, useState } from "react";
import LocaleAmount from "../components/locale-amount";
import useGlobalContext from "../contexts/globalContext";
import { Button } from "components/ui/button";

import { saveToLocalStorage } from "utils/helpers";
import { RefreshCw } from "lucide-react";
import { TooltipWrapper } from "components/ui/tooltip";
import clsx from "clsx";
import CreateNew from "./create-new";
import MinimalLineChart from "./minimal-line-chart";

export default function HomepageHeader({
  isProjection = false,
  projections = [],
}) {
  const { wallets, getWallets, setSettings, settings } = useGlobalContext();

  const [filteredWallets, setfilteredWallets] = useState([]);
  const [showValue, setshowValue] = useState(settings.visibility || false);
  const [loading, setloading] = useState(false);

  const toggleVisibility = () => {
    setshowValue(!showValue);
    const prevSettings = { ...settings };
    prevSettings.visibility = !showValue;
    saveToLocalStorage("settings", prevSettings);
    setSettings(prevSettings);
  };

  useEffect(() => {
    const prevState = [...wallets];
    const walletsList = prevState
      .map((x) => ({
        ...x,
        is_selected: true,
        total: parseFloat(x.income) - parseFloat(x.expense),
        isSame: true,
      }))
      .filter((x) => !x.archived)
      .sort((a, b) => a.name.localeCompare(b.name));

    setfilteredWallets(walletsList);
  }, [wallets]);

  const checkWallet = (id) => {
    const prevState = [...filteredWallets];

    const index = prevState.findIndex((x) => x.id === id);

    prevState[index].is_selected = !filteredWallets[index].is_selected;

    setfilteredWallets(prevState);
  };

  const getLatestWallets = useCallback(async () => {
    setloading(true);
    await getWallets(true, () => setloading(false));
  }, [getWallets]);

  // add displayed property
  const totalIncome = filteredWallets
    .filter((x) => x.is_selected)
    .reduce((acc, obj) => acc + obj.income, 0);
  const totalExpense = filteredWallets
    .filter((x) => x.is_selected)
    .reduce((acc, obj) => acc + obj.expense, 0);

  const totalProjectedIncome = projections.reduce(
    (acc, obj) => acc + obj.income,
    0
  );
  const totalProjectedExpense = projections.reduce(
    (acc, obj) => acc + obj.expense,
    0
  );

  const grandTotal = isProjection
    ? totalIncome + totalProjectedIncome - totalExpense - totalProjectedExpense
    : totalIncome - totalExpense;
  return (
    <>
      <div className="flex justify-center flex-col items-center  py-4 bg-primary/5 rounded-xl my-4 shadow-inner">
        <div className="mb-10 flex px-4 items-start justify-start w-full">
          <TooltipWrapper tooltipText="Synchronize">
            <Button variant="ghost" size="sm" onClick={getLatestWallets}>
              <RefreshCw
                size={16}
                className={clsx(loading && "animate-spin")}
              />
              Refresh
            </Button>
          </TooltipWrapper>

          <TooltipWrapper tooltipText="Show or hide value">
            <Button variant="ghost" size="sm" onClick={toggleVisibility}>
              {showValue ? <Eye size={16} /> : <EyeOff size={16} />}
              {showValue ? "Hide" : "Show"} value
            </Button>
          </TooltipWrapper>
        </div>
        <div className="flex gap-4 justify-center items-center">
          <div className="flex-1 text-center">
            <p className="font-semibold text-base">Current total balance</p>
            <div>
              <div className="text-3xl md:text-5xl">
                ₱
                <span className={classNames(!showValue && "blur-lg")}>
                  <LocaleAmount value={grandTotal} />
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="h-24 w-full">
          <MinimalLineChart />
        </div> */}
        <div>
          <CreateNew />
        </div>
      </div>
    </>
  );
}
