import React from 'react'

export default function CardControls({
  cancelAction = () => alert('Action not set'),
  okAction = () => alert('Action not set'),
  cancelTitle = 'Cancel',
  okTitle = 'Action',
  okDisabled = false,
  okKbdHelper = '',
  cancelKbdHelper = '',

}) {
  return (
    <div>
      <div className="flex justify-between mb-2">
        <div>
          {cancelKbdHelper}
        </div>
        <div>
          {okKbdHelper}
        </div>
      </div>
      <div className="flex justify-between ">
        <button className='btn btn-outline' onClick={cancelAction}>{cancelTitle}</button>
        <button className='btn btn-primary' onClick={okAction} disabled={okDisabled}>{okTitle}</button>
      </div>
    </div>
  )
}
