import classNames from 'classnames'
import React from 'react'
import MainHeader from './mainHeader'

export default function Card({ children, isPage = false, hasShadow, handlers = {}, className }) {
  return (
    <section className={classNames(
      `flex flex-col gap-4 p-6 pb-6 sm:p-6 max-w-lg w-full mx-auto text-base-content bg-base-100 sm:rounded-xl sm:mb-3 overscroll-none sm:shadow-xl ${className}`,
      isPage && 'pt-16 sm:pt-6  sm:mt-20',
      hasShadow && 'shadow-inner'
    )}
      {...handlers}
    >
      {children}
    </section>
  )
}
