import PocketBase from "pocketbase";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useGlobalContext from "../contexts/globalContext";
import { pb } from "../utils/pbConfig";
import MainHeader from "components/mainHeader";
import { Card } from "components/ui/card";
import MainContainer from "components/main-container";
import { Input } from "components/ui/input";
import { Button } from "components/ui/button";
import Footer from "components/footer";
import { useToast } from "components/ui/use-toast";
import { handleError } from "utils/helpers";
import { Loader2 } from "lucide-react";
import { Label } from "components/ui/label";

export default function Login() {
  const navigate = useNavigate();
  const { getUser, setUser, isLoggedIn, user, logoutUser, loadUserData } =
    useGlobalContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { toast } = useToast();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast({
        variant: "destructive",
        title: "Please fill out all fields.",
      });
      return;
    }

    try {
      setLoading(true);
      const authData = await pb
        .collection("users")
        .authWithPassword(email, password);
      await getUser();
    } catch (error) {
      setLoading(false);
      handleError(error, toast);
    } finally {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <main className="flex flex-col h-screen w-screen items-center justify-center p-4 bg-paper">
      <Card className="max-w-md w-full">
        <form
          onSubmit={handleLogin}
          className="flex flex-col gap-y-4 items-center py-10"
        >
          <div className="flex flex-col text-center">
            <h1 className="text-2xl font-semibold tracking-tight mb-8">
              Login to Budget Baboy
            </h1>
          </div>
          <div className="form-control w-full max-w-xs mx-auto">
            <Label className="mb-2">Username or Email</Label>
            <Input
              name="email"
              value={email}
              type="text"
              placeholder="Username or Email"
              className="input input-bordered w-full"
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="username"
            />
          </div>
          <div className="form-control w-full max-w-xs mx-auto">
            <Label className="mb-2">Password</Label>
            <Input
              name="password"
              value={password}
              autoComplete="current-password"
              type="password"
              placeholder="Password"
              className="input input-bordered w-full"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Button
            type="submit"
            onClick={handleLogin}
            className="max-w-xs w-full mt-8"
            disabled={loading}
          >
            <Loader2
              size={16}
              className={loading ? "animate-spin" : "hidden"}
            />
            {loading ? "Loading..." : "Login"}
          </Button>
        </form>
      </Card>
      <Footer />
    </main>
  );
}
