import clsx from "clsx";
import { Button } from "components/ui/button";
import { Card } from "components/ui/card";
import useGlobalContext from "contexts/globalContext";
import { AlertCircle, RefreshCw } from "lucide-react";
import React, { useEffect, useState } from "react";
import { getFromLocalStorage } from "utils/helpers";

const formatTimeString = (time) => {
  // convert time to readable format
  const seconds = time / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  const data = {
    seconds,
    minutes,
    hours,
    days,
  };

  let timeString = "";
  if (data.days >= 1) {
    timeString = `${Math.floor(data.days)} day${
      Math.floor(data.days) > 1 ? "s" : ""
    }`;
  } else if (data.hours >= 1) {
    timeString = `${Math.floor(data.hours)} hour${
      Math.floor(data.hours) > 1 ? "s" : ""
    }`;
  } else if (data.minutes >= 1) {
    timeString = `${Math.floor(data.minutes)} minute${
      Math.floor(data.minutes) > 1 ? "s" : ""
    }`;
  } else {
    timeString = `${Math.floor(data.seconds)} second${
      Math.floor(data.seconds) > 1 ? "s" : ""
    }`;
  }
  return timeString;
};

export default function Sync() {
  const [timeSinceLastSync, setTimeSinceLastSync] = useState(null);
  const [loading, setloading] = useState(false);
  const { getWallets, getCategories, getEntries } = useGlobalContext();

  // periodically check the local storage for the data
  useEffect(() => {
    const interval = setInterval(() => {
      const wallets = getFromLocalStorage("wallets");
      if (!wallets) return;
      const timeSinceLastSync = new Date().getTime() - wallets.timestamp;
      setTimeSinceLastSync(timeSinceLastSync);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    console.log(loading);
  }, [loading]);

  const handleSync = async () => {
    // save the data to the local storage
    try {
      setloading(true);
      await getWallets(true);
      await getEntries(true);
      await getCategories(true);
    } catch (error) {
      console.error(error);
      setloading(false);
    } finally {
      setTimeout(() => {
        setloading(false);
        setTimeSinceLastSync(0);
      }, 1000);
    }
  };

  const isDataOlderThan1Hour = timeSinceLastSync > 60 * 60 * 1000;

  if (!isDataOlderThan1Hour) {
    return null;
  }

  return (
    <div className="">
      <Card
        className={clsx(
          "flex  items-center justify-between gap-20 p-4 w-full max-w-none mt-4",
          loading ? "bg-primary-foreground" : "bg-destructive/20"
        )}
      >
        <div className="flex gap-2 items-center leading-tight">
          {loading ? (
            <RefreshCw size={20} className="animate-spin shrink-0" />
          ) : (
            <AlertCircle size={20} className="shrink-0" />
          )}
          {loading ? (
            "Getting fresh data..."
          ) : (
            <span>
              Data may be outdated. Last updated{" "}
              {formatTimeString(timeSinceLastSync)} ago
            </span>
          )}
        </div>
        <Button disabled={loading} onClick={handleSync}>
          {loading ? "Syncing..." : "Sync"}
        </Button>
      </Card>
    </div>
  );
}
