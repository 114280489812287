import ListItem from "components/listItem";
import { Button } from "components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import useGlobalContext from "contexts/globalContext";
import { ArrowRight } from "feather-icons-react/build/IconComponents";
import { useNavigate } from "react-router-dom";

export default function TopCategories() {
  const { entries } = useGlobalContext();
  const navigate = useNavigate();

  const topCategories = Object.values(
    entries.reduce((acc, obj) => {
      const category = obj.expand.category?.name;
      if (category) {
        if (!acc[category]) {
          acc[category] = { count: 0, ...obj.expand.category };
        }
        acc[category].count += 1;
      }
      return acc;
    }, {})
  )
    .sort((a, b) => b.count - a.count)
    .slice(0, 5);

  const viewCategoryEntries = (data) => {
    const { id, name, type, emoji } = data;
    const slug = name.toLowerCase().replace(/\s/g, "-");
    const state = { id, name, type, emoji };
    navigate(`/app/entries/category/${slug}`, { state: { data: state } });
  };

  return (
    <Card className="select-none mb-2">
      <CardHeader>
        <div className="flex flex-row items-center justify-between">
          <CardTitle>Top categories</CardTitle>
          <Button
            variant="outline"
            size="sm"
            onClick={() => navigate("/app/categories")}
          >
            See all
            <ArrowRight size={16} />
          </Button>
        </div>
        <CardDescription>From your last 100 entries</CardDescription>
      </CardHeader>

      <CardContent className=" -mx-3 md:-mx-4">
        {topCategories.length > 0 &&
          topCategories.map((category) => (
            <ListItem
              key={category.name}
              title={category.name}
              actionComponent={
                <div className="bg-gray-200 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                  {category.count} entries
                </div>
              }
              emoji={category.emoji}
              showIcon
              onClick={() => viewCategoryEntries(category)}
            />
          ))}
      </CardContent>
    </Card>
  );
}
