import classNames from 'classnames';
import { formatISO } from 'date-fns';
import { Plus } from 'feather-icons-react/build/IconComponents';
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Card from '../components/card'
import CardControls from '../components/cardControls';
import NavHeader from '../components/navHeader'
import useGlobalContext from '../contexts/globalContext';
import { pb } from '../utils/pbConfig';

export default function CreateCategory() {
  const navigate = useNavigate();
  const location = useLocation();
  const [type, setType] = useState(location.state?.type || 'income');
  const [name, setName] = useState('');
  const { getCategories, user } = useGlobalContext();


  const goBack = () => {

    if (location?.state?.goTo) {
      navigate(location?.state?.goTo, { state: { type } });
      return;
    }
    navigate(-1, { state: { type } });
  }


  const createWallet = async () => {
    const data = {
      "name": name,
      "type": type,
      "user": user.id
    };

    try {
      const category = await pb.collection('categories').create(data);
      await getCategories();
      goBack();
    } catch (error) {
      console.log('error :>> ', error);
      alert('Something went wrong')
    } finally {
    }
  }

  return (
    <Card isPage>
      <NavHeader isPage title="Create new category" />
      <div className="form-control w-full max-w-xs">
        <label className="label p-0">
          <span className="label-text">Name your category</span>
        </label>
        <input
          value={name}
          onChange={e => setName(e.target.value)}
          type="text" placeholder="Type here" className="input input-bordered w-full max-w-xs" />
      </div>

      <label className="label p-0">
        <span className="label-text">Category Type</span>
      </label>
      <div className="btn-group">
        <button className={
          classNames(
            'btn',
            {
              'btn-success opacity-100': type === 'income',
              'opacity-50': type !== 'income'
            }
          )
        }
          onClick={() => setType('income')}
        >Income</button>
        <button className={
          classNames(
            'btn',
            {
              'btn-error opacity-100': type === 'expense',
              'opacity-50': type !== 'expense'
            }
          )
        }
          onClick={() => setType('expense')}
        >Expense</button>
      </div>
      <CardControls
        cancelAction={goBack}
        okTitle="Create"
        okAction={createWallet}
      />
    </Card>
  )
}
