import * as React from "react";
import {
  Calculator,
  Calendar,
  CreditCard,
  Search,
  Settings,
  Shapes,
  Smile,
  User,
} from "lucide-react";

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from "components/ui/command";
import { Button } from "./ui/button";
import { SIDEBAR_CONSTANTS } from "constants/sidebar.constants";
import { Command } from "feather-icons-react/build/IconComponents";
import { IconComponent } from "./icon";
import useGlobalContext from "contexts/globalContext";
import { useNavigate } from "react-router-dom";

export function CommandBar() {
  const [open, setOpen] = React.useState(false);

  const { categories } = useGlobalContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    const down = (e) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  const incomeCategories = categories
    .filter((category) => category.type === "income" && !category.is_archive)
    .sort((a, b) => a.name.localeCompare(b.name));
  const expenseCategories = categories
    .filter((category) => category.type === "expense" && !category.is_archive)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div class="w-full text-center mb-4 relative">
      <Button
        variant="outline"
        size="lg"
        className=" text-sm justify-between text-muted-foreground max-w-2xl w-full mx-auto px-4"
        onClick={() => setOpen(true)}
      >
        <Search size={20} />
        Search or type a command
        <kbd className="ml-2 pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
          <span className="text-xs">⌘</span>K
        </kbd>
      </Button>

      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Type a command or search..." />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup heading="Suggested">
            <CommandItem
              onSelect={() => {
                setOpen(false);
                navigate("/app/create-entry", {
                  state: {
                    type: "expense",
                  },
                });
              }}
            >
              <IconComponent name="plus" size={18} />
              <span className="ml-2">Add new entry</span>
              <CommandShortcut>⌘N</CommandShortcut>
            </CommandItem>
          </CommandGroup>
          <CommandSeparator />
          {SIDEBAR_CONSTANTS.map((item, index) => {
            return (
              <>
                <CommandGroup key={index} heading={item.title}>
                  {item.items.map((subItem, subIndex) => {
                    return (
                      <CommandItem
                        key={subIndex}
                        onSelect={() => {
                          setOpen(false);
                          navigate(subItem.location, { state: subItem.state });
                        }}
                      >
                        <IconComponent name={subItem.icon} size={18} />
                        <span className="ml-2">{subItem.title}</span>
                        <CommandShortcut>{subItem.shortcut}</CommandShortcut>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
                <CommandSeparator />
              </>
            );
          })}
          {incomeCategories.length > 0 && (
            <>
              <CommandGroup heading="Income Categories">
                {incomeCategories.map((subItem, subIndex) => {
                  return (
                    <CommandItem key={subIndex}>
                      <div className="w-5">
                        {subItem.emoji || <Shapes size={18} />}
                      </div>
                      <span className="ml-2">{subItem.name}</span>
                      <CommandShortcut>{subItem.shortcut}</CommandShortcut>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              <CommandSeparator />
            </>
          )}
          {expenseCategories.length > 0 && (
            <>
              <CommandGroup heading="Expense Categories">
                {expenseCategories.map((subItem, subIndex) => {
                  return (
                    <CommandItem key={subIndex}>
                      <div className="w-5">
                        {subItem.emoji || <Shapes size={18} />}
                      </div>
                      <span className="ml-2">{subItem.name}</span>
                      <CommandShortcut>{subItem.shortcut}</CommandShortcut>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              <CommandSeparator />
            </>
          )}
        </CommandList>
      </CommandDialog>
    </div>
  );
}
