import clsx from "clsx"

export function TypographyH1({ children, className }) {
  return (
    <h1 className={clsx(
      `scroll-m-20 text-4xl font-bold tracking-tight lg:text-5xl`, className
    )}>
      {children}
    </h1>
  )
}

export function TypographyH2({ children, className }) {
  return (
    <h2 className={`scroll-m-20  text-2xl font-semibold tracking-tight first:mt-0 ${className}`}>
      {children}
    </h2>
  )
}

export function TypographyH3({ children, className }) {
  return (
    <h3 className={`scroll-m-20 text-xl font-semibold tracking-tight ${className}`}>
      {children}
    </h3>
  )
}

export function TypographyP({ children, className }) {
  return (
    <p className={`leading-7 [&:not(:first-child)]:mt-6 ${className}`}>
      {children}
    </p>
  )
}

export function TypographySmall({ children, className }) {
  return (
    <small className={`block text-sm text-muted-foreground ${className}`}>{children}</small>
  )
}

export function TypographyLarge({ children, className }) {
  return (
    <div className={`text-lg font-semibold text-muted-foreground ${className}`}>{children}</div>
  )
}
