import React from "react";
import { Card, CardTitle } from "components/ui/card";
import { TooltipWrapper } from "components/ui/tooltip";
import useCtrlKeyDown from "hooks/useCtrlKeyDown";
import { useNavigate } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { Button } from "./ui/button";
import {
  ArrowLeftRightIcon,
  Minus,
  MinusCircle,
  Plus,
  PlusCircle,
} from "lucide-react";

export default function CreateNew() {
  const navigate = useNavigate();

  const goToCreateEntry = (type) => {
    navigate("/app/create-entry", { state: { type } });
  };

  return (
    <div className="flex gap-2 items-center w-full">
      <div className="hidden md:block font-semibold">Create new</div>
      <div className="flex gap-1 w-full md:w-auto">
        <Button
          variant="outline"
          onClick={() => goToCreateEntry("income")}
          className="flex-1 rounded-full px-4 py-1"
        >
          <Plus size={14} />
          <span>Income</span>
        </Button>
        <Button
          variant="outline"
          onClick={() => goToCreateEntry("expense")}
          className="flex-1 rounded-full px-4 py-1"
        >
          <Minus size={14} />
          <span>Expense</span>
        </Button>
        <Button
          variant="outline"
          onClick={() => goToCreateEntry("transfer")}
          className="flex-1 rounded-full px-4 py-1"
        >
          <ArrowLeftRightIcon size={14} />
          <span>Transfer</span>
        </Button>
      </div>
    </div>
  );
}
