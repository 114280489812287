/**
 * Renders the All Entries page.
 *
 * @returns {JSX.Element} The Entries page component.
 */

import { format } from "date-fns";
import React, { useState } from "react";
import Transaction from "../components/transaction";
import useGlobalContext from "../contexts/globalContext";
import { Card } from "components/ui/card";
import MainContainer from "components/main-container";
import DateComponent from "components/date";
import TwoColumnLayoutContainer from "layout/twoColumnLayoutContainer";
import { SearchIcon, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import AppHeader from "components/app-header";
import { Button } from "components/ui/button";
import clsx from "clsx";
import { Input } from "components/ui/input";

export default function Entries() {
  const { entries } = useGlobalContext();
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const groups = entries.reduce((groups, entry) => {
    const date = format(new Date(entry.date), "MMM dd");
    // console.log('date', date)
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(entry);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupEntries = Object.keys(groups).map((date) => {
    return {
      date,
      entries: groups[date].filter((entry) =>
        entry.description.toLowerCase().includes(search.toLowerCase())
      ),
    };
  });

  // console.log("groupentries :>> ", groupEntries);
  const isSearchResultEmpty = groupEntries.every(
    (group) => group.entries.length === 0
  );

  return (
    <>
      <MainContainer className="bg-paper">
        <AppHeader />
        <TwoColumnLayoutContainer
          variant="singleColumn"
          className={"max-w-5xl mx-auto"}
          navComponents={null}
          leftComponents={
            <>
              <div className="flex-col gap-2 flex">
                <p className="font-bold pt-10 text-center md:text-left md:text-2xl">
                  All Entries
                </p>
                {/* description */}
                <p className="text-center md:text-left text-muted-foreground mb-2">
                  Showing all your entries from the past 100 transactions
                </p>

                {/* search input */}
                <div className="w-full flex items-center gap-4">
                  <label className="ml-2" htmlFor="search">
                    <SearchIcon className="h-5 w-5" />
                  </label>
                  <Input
                    id="search"
                    type="text"
                    placeholder="Search recent entries..."
                    // className="w-full bg-base-200 p-2 px-4 rounded-md focus-within:outline-primary"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Button
                    variant="outline"
                    onClick={() => setSearch("")}
                    className="rounded-full"
                    disabled={search.length <= 0}
                  >
                    <X size={18} /> Clear
                  </Button>
                </div>
              </div>
              <hr />
              <Card>
                {groupEntries.length > 0 &&
                  groupEntries.map(({ date, entries }, i) => {
                    if (entries.length <= 0) return null;
                    return (
                      <React.Fragment key={i}>
                        <div className="sticky top-16 z-40 p-3 border-t bg-background first-of-type:border-none first-of-type:rounded-t-md text-center">
                          <DateComponent>{date}</DateComponent>
                        </div>
                        <ul>
                          {entries.length > 0 &&
                            entries.map((entry, index) => (
                              <Transaction
                                keyword={search}
                                clickable
                                hideDate
                                data={entry}
                                key={entry.id}
                                index={i}
                                className={clsx(
                                  "hover:bg-base-200 border-t",
                                  index % 2 === 0 ? "bg-primary-foreground" : ""
                                )}
                              />
                            ))}
                        </ul>
                      </React.Fragment>
                    );
                  })}
                {isSearchResultEmpty && (
                  <div className="text-center p-4 py-16">
                    <p className="">
                      {entries.length <= 0 ? (
                        "No entries found"
                      ) : (
                        <>
                          No entries found for <strong>{search}</strong>
                        </>
                      )}
                    </p>
                  </div>
                )}
              </Card>
            </>
          }
          // rightComponents={
          //   <>
          //     <Alert>
          //       <Hourglass className="h-4 w-4" />
          //       <AlertTitle>Time travel is possible.</AlertTitle>
          //       <AlertDescription>
          //         Here you can see your past entries. But what if you can also
          //         create your future entries?
          //         <br />
          //         Plan your upcoming income and expenses in the Stash +
          //         Projections page.
          //         <Button
          //           variant="outline"
          //           size="sm"
          //           className="mt-2 flex"
          //           onClick={() => navigate("/app/stash")}
          //         >
          //           Take me there
          //           <ArrowRight size={18} />
          //         </Button>
          //       </AlertDescription>
          //     </Alert>
          //     <Alert>
          //       <FileSearch className="h-4 w-4" />
          //       <AlertTitle>Where's the rest of it?</AlertTitle>
          //       <AlertDescription>
          //         Well, only the last 100 entries are shown here. This can
          //         change in the future for power users 😉.
          //         <br />
          //         For now you may click the Category Icon to view the entries
          //         for that.
          //       </AlertDescription>
          //     </Alert>
          //     <Card>
          //       <CardHeader>
          //         <CardTitle>So much extra space...</CardTitle>
          //       </CardHeader>
          //       <CardContent>
          //         Ah right, we can put some ads here. But we don't want to do
          //         that for now.
          //       </CardContent>
          //     </Card>
          //   </>
          // }
          rightComponents={() => {}}
        ></TwoColumnLayoutContainer>
      </MainContainer>
    </>
  );
}
