import { handleError } from "utils/helpers";

const { default: useGlobalContext } = require("contexts/globalContext");
const { useState, useRef } = require("react");
const { useToast } = require("./ui/use-toast");
const { pb } = require("utils/pbConfig");
const { DialogTrigger, Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } = require("./ui/dialog");
const { Button } = require("./ui/button");
const { Plus, Loader2 } = require("lucide-react");
const { clsx } = require("clsx");
const { default: AmountInput } = require("./amount-input");
const { Label } = require("./ui/label");
const { DatePicker } = require("./ui/date-picker");
const { addDays, formatISO } = require("date-fns");
const { Combobox } = require("./ui/combobox");
const { Input } = require("./ui/input");

export const EntryDialog = ({
  callback
}) => {
  const { user, wallets, categories } = useGlobalContext();
  const [open, setOpen] = useState(false);


  const [amount, setAmount] = useState(null);
  const [note, setNote] = useState(null);
  const [date, setDate] = useState();
  const [selectedWallet, setSelectedWallet] = useState('');
  const [selectedCategory, setselectedCategory] = useState('');
  const [shouldShake, setShouldShake] = useState(false);
  const [loading, setLoading] = useState(false);

  const { toast } = useToast();
  // const shake = useShake(containerRef?.current);

  const disabled = !amount || !selectedWallet || !selectedCategory || !date;

  const clearState = () => {
    setAmount(0);
    setNote(null);
    setDate(null);
    setSelectedWallet(null);
    setselectedCategory(null);
  }

  const shake = () => {
    setShouldShake(true);
    setTimeout(() => {
      setShouldShake(false);
    }, 500);
  }


  const createEntry = async (e) => {
    if (disabled) {
      toast({
        variant: "destructive",
        title: "That didn't work.",
        description: "Looks like you're missing some fields.",
      });
      shake();
      return;
    }

    e.preventDefault();
    const entryData = {
      "date": formatISO(new Date(date)),
      "amount": amount,
      "user": user.id,
      "description": note,
      "category": selectedCategory,
      "wallet": selectedWallet,
      "type": "stash",
    };

    try {
      setLoading(true);
      const newEntry = await pb.collection('entries').create(entryData);

      clearState();
      setOpen(false);
      if (callback) {
        callback();
      }
      toast({
        variant: "success",
        title: "Entry created.",
        description: "Your entry was created successfully.",
      });
    } catch (error) {
      setLoading(false);
      // alert('Something went wrong')
      handleError(error);
    }
    finally {
      clearState();
      setLoading(false);
    }

  }

  return (
    <Dialog className="animate-shake" open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Create new stash

          <Plus size={16} />
        </Button>
      </DialogTrigger>
      <DialogContent className=
        "sm:max-w-[425px]"

      >
        <DialogHeader>
          <DialogTitle>Create stash entry</DialogTitle>
          <DialogDescription>
            Created entries will only be added to your stash. This will not affect your balance.
          </DialogDescription>
        </DialogHeader>
        <div className={clsx(
          "grid gap-4 py-4",
          shouldShake && 'animate-shake'
        )}>
          <AmountInput
            autoFocus={false}
            inline
            value={amount} className="rounded-r-none join-item" placeholder="" onChangeValue={setAmount} />
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Target date
            </Label>
            <DatePicker
              date={date} onChange={setDate}
              placeholder="Select a later date"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Wallet
            </Label>
            {/* <Input id="wallet" value="Pedro Duarte" className="col-span-3" />
             */}
            <Combobox
              name="wallet"
              className="col-span-3"
              items={wallets}
              onSelect={setSelectedWallet}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Category
            </Label>
            <Combobox
              name="category"
              className="col-span-3"
              items={categories}
              onSelect={setselectedCategory}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Notes
            </Label>
            <Input id="notes" value={note} placeholder="Add a note" className="col-span-3"
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={createEntry}>Create entry
            {
              loading && (
                <Loader2 size={16} className="animate-spin" />
              )
            }
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>

  )
}
