import MainHeader from "components/mainHeader";
import { TypographyH1, TypographyH2 } from "components/typography";
import { Button } from "components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Skeleton } from "components/ui/skeleton";
// import { Tabs } from 'components/ui/tabs'

import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { ArrowLeft, ArrowRight, Plus } from "lucide-react";
import React from "react";
import Welcome from "./sub-pages/1-welcome";
import Categories from "./sub-pages/2-categories";
import Wallets from "./sub-pages/3-wallets";
import CompleteSetup from "./sub-pages/4-lastly";
import { OnboardingProvider } from "contexts/onboardingContext";
import AppHeader from "components/app-header";
import MainContainer from "components/main-container";
import TwoColumnLayoutContainer from "layout/twoColumnLayoutContainer";
import { useState } from "react";

export default function Onboarding() {
  const [activeTab, setActiveTab] = useState("welcome");
  return (
    <OnboardingProvider
      otherState={{
        setActiveTab,
        activeTab,
      }}
    >
      <MainContainer className="bg-paper">
        <AppHeader hideMenu />
        <TwoColumnLayoutContainer variant="singleColumn">
          <Tabs
            defaultValue="welcome"
            className="max-w-[512px] mx-auto"
            orientation="vertical"
            value={activeTab}
            onValueChange={(value) => setActiveTab(value)}
          >
            <TabsList className="flex">
              <TabsTrigger value="welcome" className="h-8">
                STEP 1
              </TabsTrigger>
              <TabsTrigger value="categories" className="h-8">
                Step 2
              </TabsTrigger>
              <TabsTrigger value="wallets" className="h-8">
                Step 3
              </TabsTrigger>
              <TabsTrigger value="finally" className="h-8">
                Step 4
              </TabsTrigger>
            </TabsList>
            <div className="max-w-[512px] mx-auto">
              <TabsContent value="welcome">
                <Welcome />
              </TabsContent>
              <TabsContent value="categories">
                <Categories />
              </TabsContent>
              <TabsContent value="wallets">
                <Wallets />
              </TabsContent>
              <TabsContent value="finally">
                <CompleteSetup />
              </TabsContent>
            </div>
          </Tabs>
        </TwoColumnLayoutContainer>
      </MainContainer>
    </OnboardingProvider>
  );
}
