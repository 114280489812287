
// Import any necessary modules or components

import clsx from "clsx";
import { Button, buttonVariants } from "components/ui/button";
import { Input } from "components/ui/input";
import { ToastAction } from "components/ui/toast";
import { useToast } from "components/ui/use-toast";
import useGlobalContext from "contexts/globalContext";
import { Github, Loader2, PiggyBank } from "lucide-react";
import { useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { pb } from "utils/pbConfig";

// Define the SignUp component
function SignUp() {
  // Add your sign-up page logic here

  const { isLoggedIn, enableSignUp, getUser, loadUserData } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const formRef = useRef(null);
  const { toast } = useToast();

  if (isLoggedIn) {
    return <Navigate to="/app" />;
  }

  if (!enableSignUp) {
    return <Navigate to="/" />;
  }


  const handleError = (error) => {
    const data = error?.data?.data;
    const mainMessage = error?.message;
    const keys = Object.keys(data);
    const message = keys.map((key) => {
      return `${key.charAt(0).toUpperCase() + key.slice(1)}: ${data[key].message} \n`;
    }).join();
    toast({
      variant: "destructive",
      title: mainMessage || "Something went wrong",
      description: message,
    })
  }


  const handleSignup = async (e) => {
    e.preventDefault();
    // validate if password and confirm password is the same
    const password = formRef.current.querySelector("#password").value;
    const confirmPassword = formRef.current.querySelector("#confirmPassword").value;
    const email = formRef.current.querySelector("#email").value;
    const name = formRef.current.querySelector("#name").value;

    const canProceed = password && confirmPassword && email && name;

    if (password !== confirmPassword) {
      // console.log("Password and confirm password do not match");
      toast({
        variant: "destructive",
        title: "Password and confirm password do not match",
        description: "Please try again.",
      });
      return;
    }

    if (!canProceed) {
      toast({
        variant: "destructive",
        title: "Please fill up all fields",
        description: "",
      });
      return;
    }

    alert('this happened');

    // Continue with sign-up logic

    const data = {
      "username": "",
      "email": email,
      "emailVisibility": true,
      "password": password,
      "passwordConfirm": password,
      "name": name,
      "beta_tester": true,
      "onboarded": false
    }

    const resetFields = () => {
      formRef.current.querySelector("#password").value = '';
      formRef.current.querySelector("#confirmPassword").value = '';
      formRef.current.querySelector("#email").value = '';
      formRef.current.querySelector("#name").value = '';
    }



    try {
      setIsLoading(true);
      const record = await pb.collection('users').create(data);
      toast({
        title: "Account created!",
        description: "You can now login to your account.",
        action: <ToastAction altText="Create an account"
          onClick={() => navigate('/login')}
        >Login now</ToastAction>,
      });
      resetFields();
    } catch (error) {
      // setError(error?.message);
      // console.log('error :>> ', error?.message);

      handleError(error);
    }
    finally {
      setIsLoading(false);
      // toast({
      //   title: "Account created!",
      //   description: "You can now login to your account.",
      // });
    }

  };

  return (
    // Add your sign-up page JSX here
    <>
      <div className="md:hidden">
        {/* @TODO add image if trip ko */}
      </div>
      <div className="container relative h-screen flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <a
          href="/login"
          className={clsx(
            buttonVariants({ variant: "ghost" }),
            "absolute right-4 top-4 md:right-8 md:top-8"
          )}
        >
          Login
        </a>
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
          <div className="absolute inset-0 bg-zinc-900" />
          <Button variant="" href="/" className="relative text-lg font-medium text-white w-min">
            <PiggyBank />
            Budget Baboy
          </Button>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">
                Ang bagong meta sa pagbabudget.
              </p>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 w-[350px] max-w-lg">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Create an account
              </h1>
              <p className="text-sm text-muted-foreground">
                Enter your details below to create an account
              </p>
            </div>
            <div >
              <form onSubmit={handleSignup} ref={formRef}>
                <div className="grid gap-2">
                  <div className="grid gap-1">
                    <label htmlFor="email" className="text-muted-foreground">
                      Email
                    </label>
                    <Input
                      id="email"
                      placeholder="name@example.com"
                      type="email"
                      autoCapitalize="none"
                      autoComplete="email"
                      autoCorrect="off"
                      disabled={isLoading}
                    />
                    <label htmlFor="email" className="text-muted-foreground mt-2">
                      Full name
                    </label>
                    <Input
                      id="name"
                      placeholder="Your Full Name"
                      type="text"
                      autoCapitalize="all"
                      autoComplete="text"
                      autoCorrect="off"
                      disabled={isLoading}
                    />
                    <label htmlFor="email" className="text-muted-foreground mt-2">
                      Password
                    </label>
                    <Input
                      id="password"
                      placeholder="Password"
                      type="password"
                      autoComplete="current-password"
                      disabled={isLoading}
                    />
                    <Input
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      type="password"
                      autoComplete="current-password"
                      disabled={isLoading}
                    />

                  </div>
                  <Button disabled={isLoading}>
                    {isLoading && (
                      <Loader2 className="animate-spin" />
                    )}
                    Continue
                  </Button>
                </div>
              </form>
              {/* <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <span className="w-full border-t" />
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                  <span className="bg-background px-2 text-muted-foreground">
                    Or continue with
                  </span>
                </div>
              </div>
              <Button variant="outline" type="button" disabled={isLoading}>
                {isLoading ? (
                  <Loader2 className="animate-spin" />
                ) : (
                  <Github className="mr-2 h-4 w-4" />
                )}{" "}
                Github
              </Button> */}
            </div>
            <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{" "}
              <a
                href="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

// Export the SignUp component
export default SignUp;
