import MainHeader from 'components/mainHeader'
import { TypographyH1, TypographyH2 } from 'components/typography'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'components/ui/card'
import { Skeleton } from 'components/ui/skeleton'
import { ArrowLeft, ArrowRight, Plus } from 'lucide-react'
export default function CompleteSetup() {
  return (

    <Card>
      <CardHeader>

        <CardTitle className="text-lg">We're almost done</CardTitle>
        <CardDescription>
          Can't think of any more categories or wallets? No worries, you can always add more later.
          Customize categories and wallets at any time in 'Manage'...
        </CardDescription>
      </CardHeader>
      <CardContent>
        <CardDescription>
          Let's choose a theme for your app.

          dark | light | auto
        </CardDescription>

      </CardContent>
      <CardFooter>
        <Button
          variant="ghost"
        >
          <ArrowLeft size={20} />
          Back
        </Button>
        <Button
          className='rounded-full'
          size='lg'
        >Complete setup and start budgeting!
        </Button>
      </CardFooter>
    </Card>
  )
}