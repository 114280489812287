import React, { useState } from "react";
import { PropTypes as PT } from "prop-types";
import classNames from "classnames";
import {
  ChevronLeft,
  Delete,
  Edit,
  Trash2,
  X,
} from "feather-icons-react/build/IconComponents";
import useGlobalContext from "../contexts/globalContext";
import { pb } from "../utils/pbConfig";
import LocaleAmount from "./locale-amount";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import Icon from "./entry-icon";
import { Checkbox } from "./ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Button } from "./ui/button";
import { Loader2Icon, MoreHorizontal } from "lucide-react";
import { useToast } from "./ui/use-toast";
import { handleError } from "utils/helpers";
import DateComponent from "./date";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
  ContextMenuLabel,
  ContextMenuSeparator,
} from "./ui/context-menu";

export default function Transaction(props) {
  const navigate = useNavigate();

  const { getStash, wallets, getEntries, categories, getWallets } =
    useGlobalContext();
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { toast } = useToast();

  const {
    clickable = false,
    hideDate = false,
    disableCategoryClick = false,
    data,
    handleSelect = () => {},
    selectable = false,
    stashedEntry,
    index,
    className,
    keyword,
  } = props;

  const { amount = 0, id } = data;

  const getCategory = () => {
    let type = "";
    let emoji = "";
    let category = "Uncategorized";

    const categoryMatch = categories.find((c) => c.id === data.category);
    if (categoryMatch) {
      type = categoryMatch.type;
      emoji = categoryMatch.emoji;
    }

    if (data?.special_attributes?.special_type) {
      type = data.special_attributes.special_type;
    }

    if (data?.special_attributes?.type === "initial") {
      type = "income";
    }

    if (data.category === "" || !data.category) {
      category = "Uncategorized";
    } else {
      if (categoryMatch) {
        category = categoryMatch.name;
      }
    }

    if (data.special_attributes?.type === "initial") {
      category = "Created new wallet";
    } else if (data.special_attributes?.type === "transfer") {
      category = "Transfer";
    }

    return {
      type,
      emoji,
      category,
    };
  };

  const { type, emoji, category } = getCategory();

  // console.log('type', type);

  const deleteEntry = async (e) => {
    e.preventDefault();
    const { id, amount, wallet: walletId } = data;
    const existingWallet = wallets.find((x) => x.id === walletId);

    const walletData = {
      [type]: parseFloat(existingWallet[type]) - parseFloat(amount),
    };

    try {
      // delete entry
      setLoading(true);
      const entryDelete = await pb.collection("entries").delete(id);
      const walletUpdate = await pb
        .collection("wallets")
        .update(walletId, walletData);

      await getEntries(true);
      await getWallets(true);
      setOpen(false);
    } catch (error) {
      handleError(error, toast);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const deleteStashedEntry = async (e) => {
    e.preventDefault();
    const { id } = data;
    try {
      // delete entry
      setLoading(true);
      const entryDelete = await pb.collection("entries").delete(id);
      await getStash(true, () => setLoading(false));
      setOpen(false);
    } catch (error) {
      handleError(error, toast);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const viewCategoryEntries = (data) => {
    // const category = data?.expand?.category?.name;
    // const id = data?.expand?.category?.id;
    // const name = data?.expand?.category?.name;
    // const type = data?.expand?.category?.type;

    // console.log('data :>> ', data);

    // console.log('category :>> ', category);

    // convert category to slug format
    const slug = category.toLowerCase().replace(/\s/g, "-");

    console.log("slug :>> ", slug);

    const state = { id: data.category, name: category, type, emoji };

    navigate(`/app/entries/category/${slug}`, { state: { data: state } });
  };

  const matchWallet = wallets.find((x) => x.id === data.wallet);

  const wallet = matchWallet?.name;

  // const handlers = useSwipeable({
  //   onSwipedLeft: () => clickable && setClicked(true),
  //   onSwipedRight: () => clickable && setClicked(false),
  //   // onTap: () => clickable && !clicked && setClicked(!clicked),
  // });

  const editEntry = (data) => {
    navigate(`/app/entries/${data.id}`, {
      state: {
        ...data,
        category: data?.category || data?.expand?.category?.id,
        type: type,
        wallet: data?.wallet || data?.expand?.wallet?.id,
      },
    });
  };

  const ContextMenuWrapper = ({ children }) => {
    return (
      <ContextMenu>
        <ContextMenuTrigger>{children}</ContextMenuTrigger>
        <ContextMenuContent>
          <ContextMenuLabel>Item actions</ContextMenuLabel>
          <ContextMenuItem
            onClick={() => {
              editEntry(data);
            }}
          >
            Edit
          </ContextMenuItem>
          <ContextMenuItem
            onClick={() => {
              setOpen(true);
            }}
          >
            Delete
          </ContextMenuItem>
          <ContextMenuSeparator />
          <ContextMenuItem>Reload</ContextMenuItem>
        </ContextMenuContent>
      </ContextMenu>
    );
  };

  const Description = ({ children }) => {
    if (!keyword) {
      return (
        <div className="text-xs text-foreground/50 truncate min-w-0 w-full hc:text-foreground">
          {children}
        </div>
      );
    }
    const highlightKeyword = (text, keyword) => {
      const regex = new RegExp(`(${keyword})`, "gi");
      return text.split(regex).map((part, index) =>
        regex.test(part) ? (
          <span key={index} className="bg-yellow-400">
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    return (
      <div className="text-xs text-foreground/50 truncate min-w-0 w-full hc:text-foreground">
        {highlightKeyword(children, keyword)}
      </div>
    );
  };

  return (
    <ContextMenuWrapper>
      <li
        className={classNames(
          "flex justify-between items-center py-3 gap-2 px-3 md:px-5 w-full transition-all hover:bg-muted",
          {
            "cursor-pointer": clickable && !clicked,
            "bg-base-200": clicked,
          },
          className
        )}
      >
        <div className="gap-2 flex items-center min-w-0">
          <Icon
            className="flex-shrink-0"
            emoji={emoji}
            isTransfer={data?.special_attributes?.type === "transfer"}
            type={type}
            onClick={() =>
              !disableCategoryClick &&
              data.category !== "" &&
              viewCategoryEntries(data)
            }
          />
          <div
            className={classNames(
              "flex flex-auto flex-col truncate items-start",
              {
                "opacity-75": data?.is_special,
              }
            )}
          >
            <button
              className={classNames(
                "text-foreground w-full truncate text-left",
                clickable && data.category !== "" && "hover:underline"
              )}
              onClick={() =>
                !disableCategoryClick &&
                data.category !== "" &&
                viewCategoryEntries(data)
              }
            >
              {category}
            </button>
            {data?.description && <Description>{data.description}</Description>}
          </div>
        </div>

        <div className="text-right shrink-0">
          {!hideDate && (
            <div>
              <DateComponent date={data.date} />
            </div>
          )}
          <div className=" flex min-w-0 flex-row items-center">
            <div className="flex-1 bg-primary/10 text-xs px-2 rounded-full mr-2">
              {wallet}
            </div>
            <div
              className={classNames("inline-block", {
                "text-error": type === "expense",
                "text-success": type === "income",
              })}
            >
              {type == "income" && "+"}
              {type == "expense" && "-"}
              <LocaleAmount value={amount} />
              {/* {props.amount || '0.00'} */}
            </div>
          </div>
        </div>
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Delete entry?</DialogTitle>
              <DialogDescription>
                This action cannot be undone. Are you sure you want to
                permanently delete this entry?
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <DialogClose asChild>
                <Button type="submit" variant="outline">
                  Cancel
                </Button>
              </DialogClose>
              <Button
                onClick={stashedEntry ? deleteStashedEntry : deleteEntry}
                variant="destructive"
                disabled={loading}
              >
                {loading ? "Deleting..." : "Delete"}
                {!!loading && (
                  <Loader2Icon className="animate-spin ml-2" size={16} />
                )}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </li>
    </ContextMenuWrapper>
  );
}

// Transaction.propTypes = {
//   category: PT.string,
//   note: PT.string,
//   amount: PT.number,
//   date: PT.string,
//   wallet: PT.string,
//   type: PT.string,
//   clickable?: PT.boolean
// }
