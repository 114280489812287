
import { useEffect, useState } from 'react';

const useSecretCode = (callback) => {
  const [konamiCode, setKonamiCode] = useState('');

  useEffect(() => {
    const handleKeyDown = (event) => {
      setKonamiCode((prevCode) => prevCode + event.key);
    };

    const konamiCodeSequence = 'gustokongbaboy';

    if (konamiCode === konamiCodeSequence) {
      callback();
      setKonamiCode('');
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [konamiCode, callback]);

  return konamiCode;
};

export default useSecretCode;
