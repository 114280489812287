import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useGlobalContext from "../contexts/globalContext";
import { pb } from "../utils/pbConfig";
import Page from "components/page";
import PageNav from "components/page-nav";
import { Button } from "components/ui/button";
import {
  Calculator,
  Lightbulb,
  Wallet2,
  Loader2,
  EyeOff,
  Banknote,
  Landmark,
  Coins,
  Check,
  CheckCheck,
  CheckIcon,
} from "lucide-react";
import PageContent from "components/page-content";
import Icon from "components/entry-icon";
import { TypographyH1, TypographyH2 } from "components/typography";
import { Alert, AlertDescription, AlertTitle } from "components/ui/alert";
import LocaleAmount from "components/locale-amount";
import dayjs from "dayjs";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordion";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
  DialogClose,
  DialogDescription,
  DialogTitle,
} from "components/ui/dialog";
import { handleError } from "utils/helpers";
import { useToast } from "components/ui/use-toast";
import { Input } from "components/ui/input";
import { Switch } from "components/ui/switch";
import { Label } from "components/ui/label";
import { motion } from "framer-motion";
import { useCallback } from "react";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { subMinutes } from "date-fns";
import { useEffect } from "react";
import { Skeleton } from "components/ui/skeleton";
import Transaction from "components/transaction";
import IconAndDialog from "components/wallet-icon-picker";

export default function ManageWallet() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const { getWallets } = useGlobalContext();
  const [name, setName] = useState(data?.name || "invalid");
  const [icon, setIcon] = useState(data?.icon || "wallet");
  const [deleteName, setDeleteName] = useState();
  const [isArchived, setIsArchived] = useState(data.archived);
  const [showRename, setShowRename] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);
  const [entries, setEntries] = useState([]);
  const nameInputRef = React.useRef(null);

  const getEntries = useCallback(async () => {
    setLocalLoader(true);

    try {
      // you can also fetch all records at once via getFullList
      // console.log('getDBDate(startdate) :>> ', getDBDate(startdate));
      const records = await pb
        .collection("entries")
        .getList(1, 5 /* batch size */, {
          sort: "-date",
          expand: "wallet,category",
          filter: `wallet.id = "${id}"`,
        });
      setEntries(records);
      // setLoading(false);
      setLocalLoader(false);
    } catch (error) {
      setLocalLoader(false);
    } finally {
      setLocalLoader(false);
    }
  }, [setLocalLoader]);

  useEffect(() => {
    getEntries();
  }, []);

  const id = data.id;
  const [loading, setLoading] = useState(false);

  const { toast } = useToast();

  // actions
  const archiveWallet = async () => {
    const walletData = {
      archived: !isArchived,
    };

    try {
      const wallet = await pb.collection("wallets").update(id, walletData);
      setIsArchived(wallet.archived);
      console.log("wallet :>> ", wallet);

      toast({
        title: "Wallet updated",
        description: `Wallet ${name} was successfully ${
          wallet.archived ? "archived" : "unarchived"
        }`,
      });

      await getWallets(true);
    } catch (error) {
      handleError(error, toast);
    }
  };

  const renameWallet = async () => {
    // get the value of the input

    const newName = nameInputRef?.current.value;

    if (!newName) {
      toast({
        title: "Invalid name",
        description: "Wallet name cannot be empty",
        type: "error",
      });
      return;
    }

    const walletData = {
      name: newName,
    };

    try {
      setLoading(true);
      const wallet = await pb.collection("wallets").update(id, walletData);
      setName(wallet.name);
      await getWallets(true);
      setShowRename(false);
      toast({
        title: "Wallet updated",
        description: `Wallet was successfully renamed`,
      });
    } catch (error) {
      setLoading(false);
      handleError(error, toast);
    } finally {
      setLoading(false);
    }
  };

  const changeIcon = async (icon, callback) => {
    const walletData = {
      icon,
    };

    try {
      setLoading(true);
      const wallet = await pb.collection("wallets").update(id, walletData);
      setIcon(wallet.icon);
      await getWallets(true);
      setShowRename(false);
      toast({
        title: "Wallet updated",
        description: `Wallet icon was successfully updated`,
      });
      callback();
    } catch (error) {
      setLoading(false);
      handleError(error, toast);
    } finally {
      setLoading(false);
    }
  };

  const deleteWallet = async () => {
    setLoading(true);
    try {
      await pb.collection("wallets").delete(id);
      await getWallets(true);
      navigate(-1);
      toast({
        title: "Wallet deleted",
        message: `Wallet ${name} was successfully deleted`,
      });
    } catch (error) {
      handleError(error, toast);
    } finally {
      setLoading(false);
    }
  };

  const balance = parseFloat(data.income) - parseFloat(data.expense);
  const deleteConfirmText = data.name.toLowerCase().split(" ").join("-");

  // sub components
  const WalletNameAndDialog = () => {
    return (
      <Dialog open={showRename} onOpenChange={setShowRename}>
        <DialogTrigger asChild>
          <Button variant="ghost" className="px-1">
            <TypographyH2 className="text-2xl font-semibold">
              {name}
            </TypographyH2>
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Rename Wallet</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <Input type="text" defaultValue={name} ref={nameInputRef} />
          </DialogDescription>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="secondary">Cancel</Button>
            </DialogClose>
            <Button onClick={renameWallet} disabled={loading}>
              {loading ? (
                <Loader2 size={18} className="animate-spin" />
              ) : (
                "Rename"
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <motion.main
      initial={{ opacity: 0, x: "50%" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "50%" }}
      transition={{ duration: 0.1 }}
    >
      <Page>
        <PageNav backTitle="Back"></PageNav>
        <PageContent>
          <div className="flex items-center gap-2">
            <IconAndDialog
              icon={icon}
              updateIcon={changeIcon}
              loading={loading}
            />
            <WalletNameAndDialog />
          </div>
          <div className="text-center">
            <CardTitle>Current Total Balance</CardTitle>
            <TypographyH1 className="font-normal font-poppins">
              <span className="text-4xl text-primary opacity-50">₱</span>
              <LocaleAmount value={balance} />
            </TypographyH1>
          </div>

          <CardHeader>
            <CardTitle>Wallet Insights</CardTitle>
          </CardHeader>
          {isArchived && (
            <Alert className="w-full">
              <EyeOff className="mr-2" size={16} />
              <AlertTitle>This wallet is hidden.</AlertTitle>
              <AlertDescription>
                This wallet is archived and will not show to your list of
                wallets. Scroll to Wallet Controls section to unarchive it.
              </AlertDescription>
            </Alert>
          )}
          <Alert className="w-full">
            <Calculator className="mr-2" size={16} />
            {/* <AlertTitle>Need to create entries for later?</AlertTitle> */}
            <AlertDescription>
              You earned a total of{" "}
              <strong>
                ₱<LocaleAmount value={parseFloat(data.income)} />
              </strong>{" "}
              and spent a total of{" "}
              <strong>
                ₱<LocaleAmount value={parseFloat(data.expense)} />
              </strong>{" "}
              since you created this wallet on{" "}
              {dayjs(data.created).format("MMM DD, YYYY")}
            </AlertDescription>
          </Alert>
          <Alert className="w-full">
            <Lightbulb className="mr-2" size={16} />
            <AlertTitle>Need to rename or change icon?</AlertTitle>
            <AlertDescription>
              Click the name or the icon to change it.
            </AlertDescription>
          </Alert>

          <CardHeader>
            <CardTitle>Recent transactions</CardTitle>
          </CardHeader>

          <Card>
            {localLoader ? (
              <>
                <Skeleton className="h-12 m-6 w-[95%] mx-auto block" />
                <Skeleton className="h-12 m-6 w-[95%] mx-auto block" />
                <Skeleton className="h-12 m-6 w-[95%] mx-auto block" />
              </>
            ) : (
              <>
                {entries?.items?.map((entry) => (
                  <Transaction key={entry.id} data={entry} />
                ))}
                {/* empty */}
                {entries?.items?.length <= 0 && (
                  <div className="flex flex-col items-center justify-center h-24">
                    <p className="text-muted-foreground">No entry yet</p>
                  </div>
                )}
              </>
            )}
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Wallet controls</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex items-center space-x-2 mb-2">
                <Switch
                  id="is_archive"
                  checked={isArchived}
                  onCheckedChange={archiveWallet}
                />
                <Label htmlFor="is_archive">Archive this wallet?</Label>
              </div>
              <p className="text-xs text-muted-foreground">
                Archiving this wallet will remove it from the list of wallets.
                You can still view it by clicking the{" "}
                <strong>Show Archived</strong> button in the wallets page.
              </p>
            </CardContent>
          </Card>
          <Accordion type="single" collapsible>
            <AccordionItem value="item-1" className="border-none">
              <AccordionTrigger className="text-destructive">
                Danger Zone
              </AccordionTrigger>
              <AccordionContent>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant="destructive">Delete this wallet</Button>
                  </DialogTrigger>

                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Are you sure?</DialogTitle>
                      <DialogDescription>
                        This will permanently delete this wallet, total budget
                        tally will be affected. Once you delete you can no
                        longer recover this wallet. Any entry that is using this
                        wallet will <strong>NOT</strong> be removed and will
                        simply be unlinked to this wallet. You can consider
                        archiving this instead.
                        <hr className="my-2 block" />
                        <p className="mb-2">
                          To delete this wallet, type{" "}
                          <strong>{deleteConfirmText}</strong> below:
                        </p>
                        <Input
                          type="text"
                          value={deleteName}
                          onChange={(e) => setDeleteName(e.target.value)}
                        />
                      </DialogDescription>
                    </DialogHeader>

                    <DialogFooter>
                      <DialogClose asChild>
                        <Button variant="secondary" disabled={loading}>
                          Cancel
                        </Button>
                      </DialogClose>
                      <Button
                        variant="destructive"
                        disabled={loading || deleteName !== deleteConfirmText}
                        onClick={deleteWallet}
                      >
                        {loading ? (
                          <Loader2 size={18} className="animate-spin" />
                        ) : (
                          "Delete"
                        )}
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </PageContent>
      </Page>
    </motion.main>
  );
}
