import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import More from "../cards/more";
import Transactions from "../cards/transactions";
import useGlobalContext from "../contexts/globalContext";
import TwoColumnLayoutContainer from "../layout/twoColumnLayoutContainer";
import MonthlyInsight from "../cards/your-insights";
import MainContainer from "components/main-container";
import IncomeExpenseTrend from "cards/income-expense-trend";
import HomepageHeader from "components/homepage-header";
import AppHeader from "components/app-header";
import WalletsBalance from "cards/wallets-balance";
import TopCategories from "cards/top-categories";
import PWAPrompt from "react-ios-pwa-prompt";
import useBreakpoint from "hooks/useBreakpoint";
import Sync from "cards/sync";
import CreateNew from "components/create-new";
import useInView from "hooks/useInView";
import clsx from "clsx";

export default function Overview() {
  const navigate = useNavigate();
  const { user } = useGlobalContext();
  const isOnboarded = user?.onboarded;
  const { isDesktop, isMobile } = useBreakpoint();
  const appHeaderRef = useRef();
  const { visible: isHeaderVisible } = useInView(appHeaderRef);

  if (!isOnboarded) {
    navigate("onboarding");
  }

  return (
    <>
      <PWAPrompt
        timesToShow={2}
        promptOnVisit={2}
        copyClosePrompt="Close"
        copyDescription="Using a browser? Add it to your home screen to use it like an app!"
      />
      <MainContainer className="bg-paper">
        <div ref={appHeaderRef}>
          <AppHeader />
        </div>

        <TwoColumnLayoutContainer
          variant="sidebar"
          leftComponents={
            <>
              <MonthlyInsight />
              {/* <Wallets /> */}
              <More />
              <TopCategories />
            </>
          }
          rightComponents={
            <>
              {isDesktop && <WalletsBalance />}

              <Transactions />
              <IncomeExpenseTrend showStats />
            </>
          }
        >
          <Sync />
          <HomepageHeader />
          {/* <div className="relative">
            <hr className="absolute inset-0 top-1/2" />
            <CommandBar />
          </div> */}
          {!isDesktop && <WalletsBalance />}

          {isMobile && (
            <div
              className={clsx(
                "fixed left-0 right-0 bottom-[0svh] backdrop-blur-lg flex p-4 z-10 transition-all ease-in-out",
                !isHeaderVisible
                  ? "translate-y-0 opacity-100"
                  : "translate-y-full opacity-0"
              )}
            >
              <CreateNew />
            </div>
          )}
        </TwoColumnLayoutContainer>
      </MainContainer>
    </>
  );
}
