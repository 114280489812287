import MainHeader from "components/mainHeader";
import { TypographyH1, TypographyH2 } from "components/typography";
import { Button } from "components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Skeleton } from "components/ui/skeleton";
import { useOnboarding } from "contexts/onboardingContext";
import {
  ArrowLeft,
  ArrowRight,
  HandCoins,
  MonitorSmartphone,
  NotebookPen,
  Plus,
} from "lucide-react";

export default function Welcome() {
  const { setActiveTab } = useOnboarding();
  return (
    <Card>
      <CardHeader>
        <TypographyH2>Hello there</TypographyH2>
      </CardHeader>

      <CardContent className="text-foreground">
        <p>Welcome nga pala sa Budget Baboy—Ang bagong meta ng pagbabudget.</p>
        <br />
        <p className="mb-2">Here's what you can do with Budget Baboy:</p>
        <ul className="flex flex-col gap-2">
          <li className="flex gap-2">
            <HandCoins className="shrink-0" />
            <p>
              Track your income and expenses
              <div className="text-sm text-muted-foreground">
                with customizable categories and wallets. Learn where your money
                goes!
              </div>
            </p>
          </li>
          <li className="flex gap-2">
            <NotebookPen className="shrink-0" />
            <p>
              Get financial insights
              <div className="text-sm text-muted-foreground">
                Access monthly reports and so-so analytics (we're honest)
              </div>
            </p>
          </li>
          <li className="flex gap-2">
            <MonitorSmartphone className="shrink-0" />
            <p>
              Access across your devices
              <div className="text-sm text-muted-foreground">
                Budget Baboy is available on the web, iOS and Android as a PWA
                (Progressive Web App)
              </div>
            </p>
          </li>
        </ul>
        <br />
        <hr />
        <br />
        <p>Ready to get started?</p>
      </CardContent>
      <CardFooter className="flex justify-end items-center">
        <Button size="lg" onClick={() => setActiveTab("categories")}>
          Let's go
          <ArrowRight size={20} />
        </Button>
      </CardFooter>
    </Card>
  );
}
