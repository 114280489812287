"use client";

import { useState } from "react";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "components/ui/card";
import { useToast } from "./ui/use-toast";
import { pb } from "utils/pbConfig";
import { handleError } from "utils/helpers";

export function WaitlistForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  // clear form
  const clearForm = (e) => {
    e.preventDefault();
    e.target.reset();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const data = {
      email: event.target.email.value,
      name: event.target.name.value,
      message: event.target.message.value,
    };

    try {
      const record = await pb.collection("waitlist").create(data);
    } catch (error) {
      handleError(error, toast);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
      toast({
        // variant: "success",
        title: "🎉 Success!",
        description:
          "We have received your submission. We'll follow up on your email soon. (no assurance)",
      });
      clearForm(event);
    }
  };

  return (
    <Card className="w-full max-w-md mx-auto shadow-2xl">
      <CardHeader>
        <CardTitle>Sign up to be notified when we launch! </CardTitle>
        <CardDescription>(tinamad gumawa ng sign-up form)</CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="you@example.com"
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input id="name" placeholder="Jepoy Dizon" required />
          </div>
          <div className="space-y-2">
            <Label htmlFor="message">Message (Optional)</Label>
            <Textarea
              id="message"
              placeholder="Tell us why you're excited to join!"
            />
          </div>
          <Button type="submit" className="w-full" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Join Waitlist"}
          </Button>
        </form>
      </CardContent>
      <CardFooter className="text-sm text-muted-foreground">
        We respect your privacy and will never share your information.
      </CardFooter>
    </Card>
  );
}
