
import React from 'react'
import { PiggyBankIcon } from 'lucide-react';
import { Button } from './ui/button';

export default function Footer() {
  return (
    <div className=" items-center  gap-5  p-3 sm:px-6 " >
      <div className="flex items-center justify-between max-w-7xl mx-auto">
        <div className='flex items-center'>
          <PiggyBankIcon className='mr-4' />
          <div className="">
            Built by <Button variant="link" className="text-primary px-2" href="https://andreitrinidad.dev" target="_blank">andreitrinidad</Button>
          </div>
        </div>
      </div>
    </div >
  )
}
