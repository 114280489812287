import { addDays, formatISO } from "date-fns";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import AmountInput from "../components/amount-input";
import useGlobalContext from "../contexts/globalContext";
import { pb } from "../utils/pbConfig";
import { useHotkeys } from "react-hotkeys-hook";
import { Combobox } from "components/ui/combobox";
import { DatePicker } from "components/ui/date-picker";
import { Button } from "components/ui/button";
import { Tabs, TabsList, TabsTrigger } from "components/ui/tabs";
import {
  ArrowLeftRight,
  ArrowRight,
  ChevronLeft,
  ListMinus,
  ListPlus,
  Loader2Icon,
} from "lucide-react";
import { TypographyLarge } from "components/typography";
import clsx from "clsx";
import { KbdTooltipWrapper } from "components/ui/tooltip";
import { useToast } from "components/ui/use-toast";
import PageNav from "components/page-nav";
import Page from "components/page";
import { saveToLocalStorage } from "utils/helpers";
import { motion } from "framer-motion";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { Switch } from "components/ui/switch";
import CurrencyInputWithNumpad from "components/currency-input";

const CreateEntries = ({ edit }) => {
  const {
    categories,
    wallets,
    user,
    getEntries,
    getWallets,
    setEntries,
    entries,
  } = useGlobalContext();
  const location = useLocation();

  const {
    type: typeProp = "income",
    amount: amountProp,
    category: categoryProp = "",
    wallet: walletProp = "",
    description: noteProp = "",
    date: dateProp,
    is_special: isSpecial,
    id,
  } = location?.state || {};

  const [amount, setAmount] = useState(amountProp || "");
  const [note, setNote] = useState(noteProp);
  const [type, setType] = useState(typeProp);
  const [date, setDate] = useState(dateProp ? new Date(dateProp) : new Date());
  const [selectedWallet, setSelectedWallet] = useState(walletProp);
  const [transferToWallet, setTransferToWallet] = useState("");
  const [selectedCategory, setselectedCategory] = useState(categoryProp);
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [hasConvenienceFee, setHasConvenienceFee] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { toast } = useToast();

  const categoriesList = categories.filter(
    (x) => x.type === type && !x.archived
  );
  const transferToWallets = wallets.filter((x) => x.id !== selectedWallet);

  // clear category when changing type
  const selectType = (type) => {
    setType(type);
    setselectedCategory("");
    // setSelectedWallet('');
  };

  // clear transfer to wallet when changing "from" wallet
  const selectFromWallet = (id) => {
    setSelectedWallet(id);
    setTransferToWallet("");
  };

  const computeDate = (date, amount) => {
    setDate(addDays(date, amount));
  };

  // const date = new Date(Date.now());

  const amountNumber =
    typeof amount === "string" ? parseFloat(amount.replace(/,/g, "")) : amount;

  const createEntry = async (e) => {
    e.preventDefault();

    if (!amount || !selectedWallet || !selectedCategory) {
      shake();
      toast({
        variant: "destructive",
        title: "That didn't work.",
        description: "Looks like you're missing some fields.",
      });
      return;
    }

    // replace comma from amount and convert to number

    const entryData = {
      date: formatISO(date),
      amount: amountNumber,
      user: user.id,
      description: note,
      category: selectedCategory,
      wallet: selectedWallet,
    };

    // convert amount string to number

    const existingWallet = wallets.find((x) => x.id == selectedWallet);

    const data = {
      [type]: amountNumber + parseFloat(existingWallet[type]),
    };

    console.log("data :>> ", data);

    console.log("entryData :>> ", entryData);

    try {
      setLoading(true);
      const newEntry = await pb.collection("entries").create(entryData);
      const record = await pb
        .collection("wallets")
        .update(selectedWallet, data);

      await getWallets(true);
      const updatedEntries = [...entries];
      updatedEntries.unshift(newEntry);
      setEntries(updatedEntries);
      saveToLocalStorage("entries", updatedEntries);
      toast({
        title: `Created: New ${type} entry`,
        description: "Your entry has been created.",
      });
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      alert("Something went wrong");
    } finally {
      setLoading(false);
      navigate(-1);
    }
  };

  const createTransfer = async () => {
    const fromWallet = wallets.find((x) => x.id === selectedWallet);
    const toWallet = wallets.find((x) => x.id === transferToWallet);

    const fromData = {
      expense:
        amountNumber +
        parseFloat(fromWallet["expense"]) +
        parseFloat(convenienceFee),
    };

    const toData = {
      income: amountNumber + parseFloat(toWallet["income"]),
    };

    const bindingId = `${fromWallet.id}-${toWallet.id}-${Date.now()}`;

    const fromWalletData = {
      date: formatISO(date),
      amount: amountNumber + parseFloat(convenienceFee),
      user: user.id,
      description: `Transfer to ${toWallet.name} ${
        hasConvenienceFee ? `with convenience fee of ${convenienceFee}` : ""
      }`,
      is_special: true,
      wallet: fromWallet.id,
      special_attributes: {
        type: "transfer",
        special_type: "expense",
        binding_id: bindingId,
        convenience_fee: convenienceFee,
      },
    };
    const toWalletData = {
      date: formatISO(date),
      amount: amountNumber,
      user: user.id,
      description: `Transfer from ${fromWallet.name}`,
      is_special: true,
      wallet: toWallet.id,
      special_attributes: {
        type: "transfer",
        special_type: "income",
        binding_id: bindingId,
      },
    };

    try {
      // create entries
      setLoading(true);
      const createEntryFrom = await pb
        .collection("entries")
        .create(fromWalletData);
      const createEntryTo = await pb.collection("entries").create(toWalletData);
      // update wallets
      const updateFromWallet = await pb
        .collection("wallets")
        .update(selectedWallet, fromData);
      const updateToWallet = await pb
        .collection("wallets")
        .update(transferToWallet, toData);
      await getEntries(true);
      await getWallets(true);
      toast({
        title: `Created: New ${type} entries`,
        description: "New entries have been created.",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "There was a problem with your request.",
      });
    } finally {
      setLoading(false);
      navigate(-1);
    }
  };

  const updateEntry = async () => {
    const prevValue = amountProp;
    const existingWallet = wallets.find((x) => x.id == walletProp);

    const revertedAmount = existingWallet[type] - prevValue;

    const walletData = {
      [typeProp]: revertedAmount + amountNumber,
    };

    const updatedEntry = {
      amount: amountNumber,
      description: note,
      date,
      category: selectedCategory,
    };

    console.log("note :>> ", note);

    try {
      setLoading(true);
      const recordWallet = await pb
        .collection("wallets")
        .update(walletProp, walletData);
      const updatedEntryResponse = await pb
        .collection("entries")
        .update(id, updatedEntry);
      await getWallets(true);
      await getEntries(true);

      // const updatedEntries = [...entries];
      // const index = updatedEntries.findIndex(x => x.id === id);
      // updatedEntries[index] = updatedEntryResponse;

      // setEntries(updatedEntries);
      // saveToLocalStorage('entries', updatedEntries);
      toast({
        title: `Updated: ${type} entry`,
        description: "Your entry has been updated.",
      });
      navigate(-1);
    } catch (error) {
      console.error("error", error);

      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "There was a problem with your request.",
      });
    } finally {
      setLoading(false);
    }
  };

  const isTransfer = type === "transfer";

  const disableSubmit =
    selectedWallet === "" ||
    !selectedWallet ||
    (!isTransfer && (!selectedCategory || selectedCategory === "")) ||
    (isTransfer && (!transferToWallet || transferToWallet === "")) ||
    amount === null ||
    amount <= 0;

  const types = ["income", "expense", "transfer"];

  const swipeType = (setIndex) => {
    const currentTypeIndex = types.indexOf(type);
    const newIndex = currentTypeIndex + setIndex;

    if (newIndex < 0 || newIndex > types.length - 1) return;

    selectType(types[newIndex]);
  };
  const handlers = useSwipeable({
    onSwipedLeft: () => swipeType(1),
    onSwipedRight: () => swipeType(-1),
  });

  const sortedWallets = wallets
    .sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    })
    .filter((x) => !x.archived);

  // const dateRef = useRef();

  const [shouldShake, setShouldShake] = useState(false);

  const shake = () => {
    setShouldShake(true);
    setTimeout(() => {
      setShouldShake(false);
    }, 500);
  };

  const convenienceFees = [5, 10, 15, 18, 25];

  // useHorizontalScroll(dateRef);
  useHotkeys("ctrl+enter, meta+enter", createEntry, {
    preventDefault: true,
    enableOnFormTags: true,
  });
  useHotkeys("esc", () => navigate(-1), {
    preventDefault: true,
    enableOnFormTags: false,
  });

  const submit = (e, value) => {
    e.preventDefault();
    if (disableSubmit) {
      shake();
      return;
    }
    createEntry();
  };

  return (
    <motion.main
      initial={{ opacity: 0, x: "50%" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "50%" }}
      transition={{ duration: 0.1 }}
    >
      <Page>
        <PageNav className="hidden md:flex">
          <KbdTooltipWrapper tooltipText="⌘Enter">
            <Button
              disabled={disableSubmit || loading}
              type="submit"
              size="lg"
              onClick={
                edit ? updateEntry : isTransfer ? createTransfer : createEntry
              }
            >
              {loading ? "Saving" : "Save"}
              {!!loading && (
                <Loader2Icon className="animate-spin ml-2" size={16} />
              )}
            </Button>
          </KbdTooltipWrapper>
        </PageNav>
        <Button
          variant="icon"
          className="md:hidden absolute top-0.5 left-0"
          onClick={() => navigate(-1)}
        >
          <ChevronLeft size={25} />
        </Button>
        <div className="mx-auto max-w-xl w-full flex flex-col flex-1">
          <Label className="mb-4 text-center md:text-left block">
            {edit ? `Edit ${type} entry` : `Create new ${type} entry`}
          </Label>
          {!edit && (
            <Tabs className="w-full md:w-max mb-4" defaultValue={type}>
              <TabsList className="grid w-full grid-cols-3">
                <TabsTrigger
                  value="income"
                  onClick={() => selectType("income")}
                >
                  <ListPlus size={18} className="mr-2" />
                  Income
                </TabsTrigger>
                <TabsTrigger
                  value="expense"
                  onClick={() => selectType("expense")}
                >
                  <ListMinus size={18} className="mr-2" />
                  Expense
                </TabsTrigger>
                <TabsTrigger
                  value="transfer"
                  onClick={() => selectType("transfer")}
                >
                  <ArrowLeftRight size={18} className="mr-2" />
                  Transfer
                </TabsTrigger>
              </TabsList>
            </Tabs>
          )}

          {!isTransfer && (
            <div className="flex gap-2 mb-6">
              <Combobox
                name="category"
                items={categoriesList}
                onSelect={setselectedCategory}
                resetTrigger={type}
                managePage="/app/categories"
                manageText="Manage Categories"
                defaultValue={selectedCategory}
                edit={isSpecial}
              />
              <Combobox
                name="wallet"
                items={sortedWallets}
                onSelect={selectFromWallet}
                managePage="/app/wallets"
                manageText="Manage Wallets"
                defaultValue={selectedWallet}
                edit={edit}
              />
            </div>
          )}

          {/* Wallets */}
          {isTransfer && (
            <div className="flex items-center gap-2">
              <Combobox
                name="From Wallet"
                items={sortedWallets}
                onSelect={selectFromWallet}
                managePage="/wallets"
              />
              {isTransfer && <ArrowRight className="shrink-0" size={20} />}

              <Combobox
                name="To Wallet"
                items={transferToWallets}
                onSelect={setTransferToWallet}
              />
            </div>
          )}

          {isTransfer && (
            <>
              <div className="flex items-center space-x-2 mt-3 mb-2">
                <Switch
                  id="initial"
                  checked={hasConvenienceFee}
                  onCheckedChange={() => {
                    setHasConvenienceFee(!hasConvenienceFee);
                  }}
                />
                <Label htmlFor="initial">
                  Has convenience fee?
                  <span className="block text-xs text-gray-500 mt-1">
                    Amount will be deducted on "From" wallet
                  </span>
                </Label>
              </div>
              {hasConvenienceFee && (
                <div className="mb-3">
                  <div className="flex gap-2">
                    {convenienceFees.map((fee, index) => (
                      <Button
                        key={index}
                        variant={
                          convenienceFee == fee ? "default" : "secondary"
                        }
                        onClick={() => setConvenienceFee(fee)}
                        size="sm"
                      >
                        {fee}
                      </Button>
                    ))}
                    <Input
                      id="notes"
                      placeholder="custom"
                      value={convenienceFee}
                      className="col-span-3 w-24 h-[36px] text-base text-center"
                      onChange={(e) => setConvenienceFee(e.target.value)}
                      type="number"
                      min={0}
                      size="sm"
                    />
                  </div>
                </div>
              )}
            </>
          )}

          <CurrencyInputWithNumpad
            value={amount || ""}
            setValue={setAmount}
            date={date}
            onChangeDate={setDate}
            onChangeNote={setNote}
            note={note}
            showNote={!isTransfer}
            onCancel={() => navigate(-1)}
            onConfirm={
              edit ? updateEntry : isTransfer ? createTransfer : createEntry
            }
            loading={loading}
          />

          {/* Calendar picker */}

          {/* Category */}
        </div>
      </Page>
    </motion.main>
  );
};

export default CreateEntries;
