import classNames from "classnames";
import React from "react";
import Icon from "./entry-icon";
import { ChevronRight, Settings, Settings2 } from "lucide-react";
import { Button } from "./ui/button";

export default function ListItem({
  action = () => {},
  actionComponent = null,
  title,
  emoji = "",
  archived = false,
  showIcon = false,
  onClick = () => {},
  selected = false,
  className = "",
}) {
  return (
    <li
      className={classNames(
        "group w-full flex justify-between items-center p-2 px-4 hover:bg-base-200 rounded-lg cursor-pointer hover:bg-muted",
        {
          "opacity-50": archived,
          "bg-base-200": selected,
        },
        className
      )}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <div className="items-center flex">
        {showIcon && <Icon emoji={emoji} className="mr-4" />}
        {title}
      </div>
      {actionComponent || (
        <Button
          variant="outline"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            action();
          }}
        >
          <Settings2 size={16} />
        </Button>
      )}
    </li>
  );
}
