export const SIDEBAR_CONSTANTS = [
  {
    items: [
      {
        title: 'Dashboard',
        location: '/app',
        icon: 'dashboard'
      },
      {
        title: 'All Entries',
        location: '/app/entries',
        icon: 'list'
      },
      {
        title: 'Monthly Report',
        location: '/app/reports/monthly-spending',
        icon: 'pie'
      },
      {
        title: 'Stash + Projections',
        location: '/app/stash',
        icon: 'line-chart'
      },
    ],
  },
  {
    title: 'Manage',
    items: [
      {
        title: 'Wallets',
        location: '/app/wallets',
        icon: 'wallet'
      },
      {
        title: 'Categories',
        location: '/app/categories',
        icon: 'shapes'
      },
    ],
  },
  {
    title: 'Shortcuts',
    items: [
      {
        title: 'Add income',
        location: '/app/create-entry',
        state: { type: 'income' },
        icon: 'plus-circle'
      },
      {
        title: 'Add expense',
        location: '/app/create-entry',
        state: { type: 'expense' },
        icon: 'minus-circle'
      },
      {
        title: 'New transfer',
        location: '/app/create-entry',
        state: { type: 'transfer' },
        icon: 'minus-circle'
      },
    ],
  },
  {
    items: [
      {
        title: 'Settings',
        location: '/app/settings',
        icon: 'settings'
      },
    ],
  }
];

