import Icon from "components/entry-icon";
import LocaleAmount from "components/locale-amount";
import MainHeader from "components/mainHeader";
import {
  TypographyH1,
  TypographyH2,
  TypographyH3,
} from "components/typography";
import { Button } from "components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Skeleton } from "components/ui/skeleton";
import IconAndDialog from "components/wallet-icon-picker";
import { useOnboarding } from "contexts/onboardingContext";
import {
  ArrowLeft,
  ArrowRight,
  Banknote,
  Coins,
  Edit2,
  Landmark,
  Plus,
  Trash,
  Wallet2,
} from "lucide-react";
import { useState } from "react";

const ListItemWithDialog = ({
  name,
  id,
  type,
  icon,
  deleteAction,
  editAction,
}) => {
  // const [isDeleting, setIsDeleting] = useState(false);
  const handleDelete = () => {
    // setIsDeleting(true);
    deleteAction(id);
  };

  const handleEdit = () => {
    editAction(id);
  };

  const ICONS = {
    wallet: Wallet2,
    cash: Banknote,
    savings: Landmark,
    coins: Coins,
  };
  const IconComponent = ICONS[icon || "wallet"];

  return (
    <Dialog>
      <div className="flex justify-between w-full">
        <IconComponent size={18} />
        <TypographyH3>{name}</TypographyH3>
        <div className="flex items-center gap-3">
          <LocaleAmount value={0} />
          <DialogTrigger asChild>
            <Button variant="secondary" size="icon">
              <Edit2 size={16} />
            </Button>
          </DialogTrigger>
        </div>
      </div>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit wallet</DialogTitle>
          <DialogDescription>
            Update the wallet details here. You can change the name, icon, and
            the initial balance.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Icon
            </Label>
            <IconAndDialog icon={icon} updateIcon={() => {}} loading={false} />
            {/* <Input
                id="name"
                defaultValue="Pedro Duarte"
                className="col-span-3"
              /> */}
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Name
            </Label>
            <Input
              id="username"
              defaultValue="@peduarte"
              className="col-span-3"
            />
          </div>
        </div>
        <DialogFooter>
          <Button type="submit">Save changes</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default function Wallets() {
  const { wallets, setWallets } = useOnboarding();
  const createNewWallet = () => {
    console.log("TEST");
  };
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg">Setting up Wallets</CardTitle>
        <CardDescription>
          Now, let's organize your money into wallets. Think of these as virtual
          pockets for your different accounts or purposes. Add wallets for your
          bank accounts, cash, e-wallets and more. We already added a few for
          you.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex justify-between text-sm pb-2 mb-2 border-b border-muted">
          <Label className="!text-sm text-muted-foreground">Wallet name</Label>
          <Label className="!text-sm text-muted-foreground">
            Starting fund
          </Label>
        </div>
        {wallets.map((wallet) => (
          <ListItemWithDialog
            key={wallet.id}
            id={wallet.id}
            name={wallet.name}
            icon={wallet.icon}
            deleteAction={setWallets}
            editAction={setWallets}
          />
        ))}
        <Button variant="outline">
          Add a wallet
          <Plus size={18} />
        </Button>
        <CardDescription>
          Tip: Input your current balance for each wallet so that you don't have
          to manually add it as an income entry.
        </CardDescription>
      </CardContent>
      <CardFooter>
        <Button variant="ghost">
          <ArrowLeft size={20} />
          Back
        </Button>
        <Button className="rounded-full" size="lg">
          Next
          <ArrowRight size={20} />
        </Button>
      </CardFooter>
    </Card>
  );
}
