import clsx from "clsx";
import React from "react";
import { Sidebar } from "./sidebar";

export default function MainContainer({ children, className }) {
  return (
    <div
      className={clsx(
        "flex justify-center w-full justify-between max-h-screen relative overflow-hidden",
        className
      )}
    >
      {/* <Sidebar /> */}
      <div className="flex-1 h-screen overflow-y-scroll overflow-x-hidden pb-10">
        <div className="max-w-7xl mx-auto">{children}</div>
      </div>
    </div>
  );
}
