import React from "react";
import { useUpdateCheck } from "react-update-notification";
import { Button } from "./ui/button";
import { Rocket, Sparkles } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";

export const VersionChecker = () => {
  const { status, reloadPage } = useUpdateCheck({
    type: "interval",
    interval: 10000,
  });

  if (status === "checking" || status === "current") {
    return null;
  }

  return (
    <div>
      <Alert className="w-full my-4">
        <Rocket className="mr-2" size={16} />
        <AlertTitle>New version available!!!</AlertTitle>
        <AlertDescription>
          There is a new version of Budget Baboy available. Want to know what's
          new?{" "}
          <Button
            variant="link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://the-baboy-collective.canny.io/changelog"
          >
            See what's changed
          </Button>
          <br className="lg:hidden" />
          <Button
            variant=""
            className="mt-2 lg:mt-0 lg:ml-8"
            onClick={reloadPage}
          >
            Update now
            <span class="animate-ping  inline-flex h-2 w-2 rounded-full bg-background border-2 border-primary-foreground"></span>
          </Button>
          {/* see wahts change */}
          {/* <Button variant="link" onClick={() => navigate('/app/settings')}>Update now <span><Sparkles size="12" /></span></Button> */}
        </AlertDescription>
      </Alert>
    </div>
  );
};
