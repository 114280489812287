import useGlobalContext from "./contexts/globalContext";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import CreateEntries from "./pages/create-entries";
import ManageWallets from "./pages/manage-wallets";
import CreateWallet from "./cards/createWallet";
import ManageCategories from "./pages/manage-categories";
import CreateCategory from "./cards/createCategory";
import LoginPage from "./pages/login";
import ManageCategory from "./pages/create-category";
import ManageWallet from "./pages/manage-wallet";
import Entries from "./pages/entries";
import EditEntry from "./cards/editEntry";
import Settings from "./pages/settings";
import { useEffect, useState } from "react";
import { themeChange } from "theme-change";
import MonthlySpending from "./pages/monthly-report";
import PerCategoryEntry from "./pages/entries-by-category";
import Stash from "./cards/stash";
import Overview from "./pages/dashboard";
import Home from "./pages/home";
import { Toaster } from "components/ui/toaster";
import clsx from "clsx";
import SignUp from "pages/signup";
import Onboarding from "pages/onboarding/onboarding";
import {
  Toast,
  ToastDescription,
  ToastProvider,
  ToastTitle,
} from "components/ui/toast";
import { AnimatePresence } from "framer-motion";

function PrivateRoute({ children, fallbackPage, ...rest }) {
  const { isLoggedIn } = useGlobalContext();

  if (fallbackPage) {
    return isLoggedIn ? children : <Navigate to={fallbackPage} />;
  }
  return isLoggedIn ? children : <Navigate to="/login" />;
}

function App() {
  const { loading } = useGlobalContext();
  const location = useLocation();
  const [showSplash, setShowSplash] = useState(true);

  // check data-theme and set the meta-theme
  useEffect(() => {
    document.querySelector("html");
    // get data-theme from html
    const theme = document.documentElement.getAttribute("data-theme");

    // set the meta theme tag
    const metaTheme = document.querySelector('meta[name="theme-color"]');
    metaTheme.setAttribute("content", theme === "dark" ? "#000" : "#fff");

    // hide splash screen
    setTimeout(() => {
      setShowSplash(false);
    }, 3000);
  }, []);

  return (
    <div
    // className={clsx(
    //   'h-screen w-screen overflow-x-hidden overflow-y-auto overscroll-none bg-primary-foreground select-none',
    //   // darkMode && 'dark'
    // )}
    >
      {/* splash screen */}
      <div
        className={clsx(
          "fixed inset-0 z-[1000] w-screen h-screen bg-primary-foreground flex justify-center items-center pointer-events-none",
          showSplash ? "opacity-100" : "opacity-0",
          "transition-opacity duration-300"
        )}
      >
        <div className="flex flex-col items-center">
          <img
            src="/ios/512.png"
            alt="logo"
            className="w-20 h-20 mb-2 animate-bounce"
          />
          <h1 className="text-lg font-semibold">Budget Baboy</h1>
          <span className="text-xs text-primary">Loading...</span>
        </div>
      </div>

      <div className="font-inter">
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<Home />} />
            {/* <Route path='/' element={<Stash />} /> */}
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/app">
              <Route
                index
                element={
                  <PrivateRoute>
                    <Overview />
                  </PrivateRoute>
                }
              />
              <Route
                path="settings"
                element={
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                }
              />
              <Route
                path="onboarding"
                element={
                  <PrivateRoute>
                    <Onboarding />
                  </PrivateRoute>
                }
              />
              <Route
                path="entries/:id"
                element={
                  <PrivateRoute>
                    <CreateEntries edit />
                  </PrivateRoute>
                }
              />
              <Route path="entries">
                <Route
                  index
                  element={
                    <PrivateRoute>
                      <Entries />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="category/:id"
                  element={
                    <PrivateRoute>
                      <PerCategoryEntry />
                    </PrivateRoute>
                  }
                />
              </Route>

              <Route
                path="create-entry"
                element={
                  <PrivateRoute>
                    <CreateEntries />
                  </PrivateRoute>
                }
              />
              <Route
                path="stash"
                element={
                  <PrivateRoute>
                    <Stash />
                  </PrivateRoute>
                }
              />
              <Route path="wallets">
                <Route
                  index
                  element={
                    <PrivateRoute>
                      <ManageWallets />
                    </PrivateRoute>
                  }
                />

                <Route
                  path=":id"
                  element={
                    <PrivateRoute>
                      <ManageWallet />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                  path="create"
                  element={
                    <PrivateRoute>
                      <CreateWallet />
                    </PrivateRoute>
                  }
                /> */}
              </Route>
              <Route path="categories">
                <Route
                  index
                  element={
                    <PrivateRoute>
                      <ManageCategories />
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":id"
                  element={
                    <PrivateRoute>
                      <ManageCategory />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                  path="create"
                  element={
                    <PrivateRoute>
                      <CreateCategory />
                    </PrivateRoute>
                  }
                /> */}
              </Route>
              <Route path="reports">
                {/* <Route index element={
              <PrivateRoute>
                <ManageCategories />
              </PrivateRoute>
            } /> */}
                <Route
                  path="monthly-spending"
                  element={
                    <PrivateRoute>
                      <MonthlySpending />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="create"
                  element={
                    <PrivateRoute>
                      <CreateCategory />
                    </PrivateRoute>
                  }
                />
              </Route>
            </Route>

            {/* dynamic royutes */}
          </Routes>
        </AnimatePresence>
      </div>
      <Toaster />
    </div>
  );
}

export default App;
