import React, { PureComponent, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Button } from "components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import {
  getFromLocalStorage,
  optimizedFetch,
  saveToLocalStorage,
} from "utils/helpers";
import { pb } from "utils/pbConfig";
import { endOfMonth, format, set, startOfMonth, subMonths } from "date-fns";
import colors from "tailwindcss/colors";
import LocaleAmount from "components/locale-amount";
import { PlusCircle } from "feather-icons-react/build/IconComponents";
import { Skeleton } from "components/ui/skeleton";
import { LineChartIcon, Zap } from "lucide-react";
import { addMonths } from "date-fns";
import useGlobalContext from "contexts/globalContext";
import { MobileCardCarousel } from "components/mobile-card-carousel";
const green = colors.teal[500]; // #16a34a
const red = colors.red[400]; // #16a34a
const black = colors.black; // #16a34a
const black50 = colors.neutral[400]; // #16a34a

// const data = [
//   {
//     month: 'Jan',
//     income: 4000,
//     expense: 2400,

//   },
//   {
//     month: 'Feb',
//     income: 3000,
//     expense: 1398,

//   },
//   {
//     month: 'Mar',
//     income: 2000,
//     expense: 9800,

//   },
//   {
//     month: 'Apr',
//     income: 2780,
//     expense: 3908,

//   },
//   {
//     month: 'Jun',
//     income: 1890,
//     expense: 4800,

//   },
//   {
//     month: 'Jul',
//     income: 2390,
//     expense: 3800,
//     amt: 2500,
//   },
//   {
//     month: 'Aug',
//     income: 3490,
//     expense: 4300,

//   },
// ];

export default function IncomeExpenseTrend({ showStats = false }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [asOfText, setAsOfText] = useState("");
  const { wallets } = useGlobalContext();

  const totalIncome = wallets.reduce((acc, wallet) => {
    return acc + wallet.income;
  }, 0);

  const totalExpense = wallets.reduce((acc, wallet) => {
    return acc + wallet.expense;
  }, 0);

  const totalBalance = totalIncome - totalExpense;

  // check if data is in local storage
  // if yes, use that data
  // if no, fetch from database

  const getSixMonthsEntry = async () => {
    setLoading(true);

    // get start of month 6 months ago
    const startDate = format(
      subMonths(startOfMonth(new Date()), 12),
      "yyyy-MM-dd HH:mm:ss"
    );
    const endDate = format(endOfMonth(new Date()), "yyyy-MM-dd HH:mm:ss");
    const records = await pb
      .collection("entries")
      .getFullList(200 /* batch size */, {
        sort: "-date",
        filter: `date <= "${endDate}" && date >= "${startDate}" && category != "" && type != "stash"`,
        expand: "wallet,category",
      });

    // format data to be used in chart

    const flattenData = records.map((record) => {
      return {
        ...record,
        type: record.expand.category.type,
      };
    });

    const groupByMonth = flattenData.reduce((acc, record) => {
      const month = format(new Date(record.date), "yyy-MM");
      const type = record.type;
      const amount = record.amount;

      if (!acc[month]) {
        acc[month] = {};
      }

      if (!acc[month][type]) {
        acc[month][type] = 0;
      }

      acc[month][type] += amount;

      // count the number of entries
      if (!acc[month].count) {
        acc[month].count = 0;
      }

      acc[month].count += 1;

      return acc;
    }, []);

    const formattedData = Object.keys(groupByMonth)
      .map((month) => {
        return {
          month,
          income: groupByMonth[month]?.income || 0,
          expense: groupByMonth[month]?.expense || 0,
          count: groupByMonth[month]?.count || 0,
        };
      })
      .sort((a, b) => {
        return new Date(a.month) - new Date(b.month);
      });

    // save to local storage
    saveToLocalStorage("incomeExpenseTrend", formattedData);

    // const trendlineNext3Months = format(subMonths(endOfMonth(new Date()), -3), 'yyyy-MM-dd HH:mm:ss');

    setLoading(false);

    setData(formattedData);
    setAsOfText(format(new Date(), "MMM dd, yyyy, h:mm a"));
  };

  useEffect(() => {
    const localData = getFromLocalStorage("incomeExpenseTrend");

    // if timestamp is more than 1 day, fetch from database
    if (localData && localData.timestamp) {
      const now = new Date().getTime();
      const diff = now - localData.timestamp;
      const oneDay = 1000 * 60 * 60 * 24;
      if (diff > oneDay) {
        getSixMonthsEntry();
      } else {
        setData(localData.data);
        setAsOfText(
          format(new Date(localData.timestamp), "MMM dd, yyyy, h:mm a")
        );
      }
    } else {
      getSixMonthsEntry();
    }

    // return () => {
    //   second
    // }
  }, []);

  const refresh = () => {
    getSixMonthsEntry();
  };

  // console.log("data :>> ", data);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="bg-primary-foreground p-4 rounded-md shadow-md">
          <p className="font-semibold mb-2">
            {format(new Date(label), "MMM yyyy")}
          </p>
          <p className="text-sm flex justify-between gap-4">
            Income:
            <span className="text-sm text-green-500 font-semibold font-mono">
              <LocaleAmount value={payload[1].value} />
            </span>
          </p>
          <p className="text-sm flex justify-between gap-4">
            Expense:
            <span className="text-sm text-red-500 font-semibold font-mono">
              <LocaleAmount value={payload[0].value} />
            </span>
          </p>
        </div>
      );
    }

    return null;
  };

  // create a shallow copy of data using JSON.parse(JSON.stringify(data))
  const dataCopy = JSON.parse(JSON.stringify(data));

  const last6Months = dataCopy.slice(-6);

  const averageIncome =
    last6Months.reduce((acc, record) => {
      return acc + record.income;
    }, 0) / last6Months.length;

  const averageExpense =
    last6Months.reduce((acc, record) => {
      return acc + record.expense;
    }, 0) / last6Months.length;

  const lastMonthData = dataCopy[dataCopy.length - 1];
  const lastIncome = lastMonthData?.income || 0;
  const lastExpense = lastMonthData?.expense || 0;

  const averageDailyIncome = lastIncome / 30;
  const averageDailyExpense = lastExpense / 30;
  const currentTotalBalance3Months = (lastIncome - lastExpense) * 3;
  const projectedBalance = totalBalance + currentTotalBalance3Months;

  const GlanceComponent = ({ title, value, isCurrency, footNote }) => {
    return (
      <Card className="">
        <CardHeader>
          <CardTitle className="text-center">{title}</CardTitle>
        </CardHeader>
        <CardContent className="text-2xl leading-none font-semibold flex items-center justify-center">
          {isCurrency && "₱"}
          {loading ? (
            <Skeleton className="h-6 w-12 inline-block" />
          ) : (
            <LocaleAmount value={value} shorter />
          )}
        </CardContent>
        {footNote && (
          <CardFooter className="text-xs justify-center">
            <div className="text-center">{footNote}</div>
          </CardFooter>
        )}
      </Card>
    );
  };

  return (
    <Card>
      <CardHeader className="space-y-1 mb-0">
        <CardTitle className="flex items-center justify-between">
          <div className="flex">
            <h3>Income and Expense Trend</h3>
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={refresh}
            className="flex items-center gap-2"
          >
            Update now
          </Button>
        </CardTitle>
        <CardDescription>
          {asOfText && `As of ${asOfText}, updates daily`}
        </CardDescription>
      </CardHeader>
      <CardContent className="grid  -mx-6 h-60">
        {loading ? (
          <div className="flex flex-col items-center justify-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary mb-4"></div>
            <p className="text-primary text-xs">
              Generating income and expense trend...
            </p>
          </div>
        ) : (
          <>
            {data.length > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 5,
                    right: 50,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis
                    dataKey="month"
                    tickFormatter={(item) => {
                      return format(new Date(item), "MMM");
                    }}
                    fontSize={12}
                    dy={10}
                    axisLine={false}
                    tickLine={false}
                  />
                  <YAxis
                    tickFormatter={(item) => {
                      return item / 1000 + "k";
                    }}
                    fontSize={12}
                    dx={-10}
                    axisLine={false}
                    tickLine={false}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  {["expense", "income"].map((type, index) => (
                    <Line
                      key={index}
                      type="monotone"
                      dataKey={type}
                      stroke={type === "expense" ? red : green}
                      activeDot={{
                        r: 8,
                        onClick: (event, payload) => {
                          console.log("payload :>> ", payload);
                        },
                      }}
                      strokeWidth={2}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <div className="flex flex-col items-center justify-center h-full">
                <LineChartIcon size={48} className="text-primary" />
                <p className="text-primary text-xs">
                  Not enough data to generate trend.
                </p>
              </div>
            )}
          </>
        )}
      </CardContent>

      {showStats && (
        <>
          <hr />
          <CardHeader className="space-y-1 mb-0">
            <CardTitle className=" ">
              <h3 className="text-center">Quick Glance</h3>
            </CardTitle>
          </CardHeader>
          <CardContent className="">
            <MobileCardCarousel
              cards={[
                {
                  title: "It costed you",
                  value: averageDailyExpense,
                  footNote: "per day this month",
                  isCurrency: true,
                },
                {
                  title: "You are worth",
                  value: averageDailyIncome,
                  footNote: "per day this month",
                  isCurrency: true,
                },
                {
                  title: "So far, you made",
                  value: lastMonthData?.count || 0,
                  footNote: "transactions this month",
                  isCurrency: false,
                },
                {
                  title: "On average, you spent",
                  value: averageExpense,
                  footNote: "a month, for the last 6 months",
                  isCurrency: true,
                },
                {
                  title: "On average, you earned",
                  value: averageIncome,
                  footNote: "a month, for the last 6 months",
                  isCurrency: true,
                },
                {
                  title: "Your balance will be",
                  value: projectedBalance,
                  footNote: `in 3 months, based on your current trend`,
                  isCurrency: true,
                },
              ].map((glance, index) => (
                <GlanceComponent key={index} {...glance} />
              ))}
            />
          </CardContent>
        </>
      )}
    </Card>
  );
}
