import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  MinusSquare,
  PlusSquare,
  RefreshCcw,
} from "feather-icons-react/build/IconComponents";
import useGlobalContext from "../contexts/globalContext";
import {
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
  subMinutes,
} from "date-fns";
import { pb } from "../utils/pbConfig";
import LocaleAmount from "../components/locale-amount";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import {
  MinusCircle,
  PlusCircle,
  RefreshCcwDot,
  RefreshCw,
  TrendingDown,
  TrendingUp,
} from "lucide-react";
import { Tabs } from "@radix-ui/react-tabs";
import { TabsList, TabsTrigger } from "components/ui/tabs";
import { Button } from "components/ui/button";
import { Skeleton } from "components/ui/skeleton";
import { formatDistanceToNow } from "date-fns";
import clsx from "clsx";

export default function MonthlyInsight({
  data = [],
  noFetch = false,
  className,
  date = new Date(),
}) {
  const { setLoading, loading } = useGlobalContext();
  const [entries, setEntries] = useState(data);
  const [entriesByType, setEntriesByType] = useState([]);
  const [localLoader, setLocalLoader] = useState(false);
  const [filter, setFilter] = useState("month");
  const [lastUpdated, setLastUpdated] = useState(new Date());

  // @TODO Store in Global context, make it 3 months, as well as for recent entries
  const getEntries = useCallback(async () => {
    setLocalLoader(true);

    const initialDate = startOfMonth(new Date());
    const startdate = format(
      subMinutes(initialDate, -initialDate.getTimezoneOffset()),
      "yyyy-MM-dd HH:mm:ss"
    );
    const endDate = format(endOfMonth(new Date()), "yyyy-MM-dd HH:mm:ss");

    try {
      // you can also fetch all records at once via getFullList
      // console.log('getDBDate(startdate) :>> ', getDBDate(startdate));
      const records = await pb
        .collection("entries")
        .getFullList(200 /* batch size */, {
          sort: "-date",
          filter: `date <= "${endDate}" && date >= "${startdate}" && category != "" && type != "stash"`,
          expand: "wallet,category",
        });
      setEntries(records);
      // setLoading(false);
      setLocalLoader(false);
      setLastUpdated(new Date());
    } catch (error) {
      setLocalLoader(false);
    }
  }, [setLocalLoader]);

  useEffect(() => {
    if (!noFetch) {
      getEntries();
    }

    setLocalLoader(true);

    setTimeout(() => {
      setLocalLoader(false);
    }, 1000);
  }, []);

  const navigate = useNavigate();

  const navigateToReports = (type) => {
    if (noFetch) {
      return;
    }

    navigate("/app/reports/monthly-spending", {
      state: {
        data: {
          type,
          date: format(startOfMonth(new Date()), "yyyy-MM-dd"),
        },
      },
    });
  };

  // filter by month, week, day
  const filteredEntries = useMemo(() => {
    const initialDate = startOfMonth(new Date(date));
    const offset = initialDate.getTimezoneOffset();
    const startDate = format(
      subMinutes(initialDate, -offset),
      "yyyy-MM-dd HH:mm:ss"
    );
    const startDateWeek = format(
      subMinutes(startOfDay(subMinutes(new Date(), -offset)), 7 * 24 * 60),
      "yyyy-MM-dd HH:mm:ss"
    );
    const startDateDay = format(
      startOfDay(subMinutes(new Date(), -offset)),
      "yyyy-MM-dd HH:mm:ss"
    );
    const endDate = format(endOfMonth(new Date()), "yyyy-MM-dd HH:mm:ss");

    const filtered = entries.filter((entry) => {
      if (filter === "month") {
        console.log("startDate :>> ", startDate);
        return entry.date <= endDate && entry.date >= startDate;
      }
      if (filter === "week") {
        return entry.date <= endDate && entry.date >= startDateWeek;
      }
      if (filter === "day") {
        return entry.date <= endDate && entry.date >= startDateDay;
      }
    });
    return filtered;
  }, [entries, filter]);

  const getTotals = (type) => {
    const total =
      filteredEntries.length > 0
        ? filteredEntries
            .filter((entry) => entry.expand.category.type === type)
            .reduce((total, entry) => total + entry.amount, 0)
        : 0;
    return total;
  };

  const incomeTotal = getTotals("income");
  const expenseTotal = getTotals("expense");
  const balance = incomeTotal - expenseTotal;
  const lastUpdatedText = formatDistanceToNow(lastUpdated, { addSuffix: true });

  return (
    <Card>
      <CardHeader className="">
        <div className="flex flex-col md:flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="flex items-center w-full md:w-auto mb-2 md:mb-0 justify-between md:justify-start">
            {!noFetch ? "Your insights" : "Insights"}
            {!noFetch && (
              <Button
                variant="secondary"
                size="icon"
                onClick={getEntries}
                className="ml-2"
              >
                <RefreshCw
                  size={16}
                  className={localLoader ? "animate-spin" : ""}
                />
              </Button>
            )}
          </CardTitle>
          {!noFetch && (
            <Tabs className="w-full md:w-max mb-4" defaultValue="month">
              <TabsList className="grid w-full grid-cols-3">
                <TabsTrigger value="day" onClick={() => setFilter("day")}>
                  Day
                </TabsTrigger>
                <TabsTrigger value="week" onClick={() => setFilter("week")}>
                  Week
                </TabsTrigger>
                <TabsTrigger value="month" onClick={() => setFilter("month")}>
                  Month
                </TabsTrigger>
              </TabsList>
            </Tabs>
          )}
        </div>

        <CardDescription className="">
          {/* @TODO make this stateful */}
          {noFetch
            ? `   For the month of ${format(new Date(date), "MMMM yyyy")}`
            : `Last updated ${lastUpdatedText}`}
        </CardDescription>
      </CardHeader>

      <CardContent className="grid gap-4 grid-cols-2">
        <Card
          className={clsx(
            "bg-gradient-to-tr from-teal-500/50 to-teal-500/10 transition-transform",
            !noFetch && "hover:scale-105 cursor-pointer"
          )}
          onClick={() => navigateToReports("income")}
        >
          <CardHeader className="flex flex-row items-center justify-between">
            <CardTitle>Total Income</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl leading-none font-bold flex items-center">
              ₱
              {localLoader ? (
                <Skeleton className="h-6 w-full inline-block" />
              ) : (
                <LocaleAmount value={incomeTotal} shorter />
              )}
            </div>
            {/* <p className="text-xs text-muted-foreground">
              +20.1% from last month
            </p> */}
          </CardContent>
        </Card>
        <Card
          className={clsx(
            "bg-gradient-to-tr from-red-500/50 to-red-500/10 transition-transform",
            !noFetch && "hover:scale-105 cursor-pointer"
          )}
          onClick={() => navigateToReports("expense")}
        >
          <CardHeader className="flex flex-row items-center justify-between">
            <CardTitle>Total Expense</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl leading-none font-bold flex items-center">
              ₱
              {localLoader ? (
                <Skeleton className="h-6 w-full inline-block" />
              ) : (
                <LocaleAmount value={expenseTotal} shorter />
              )}
            </div>
            {/* <p className="text-xs text-muted-foreground">
              +20.1% from last month
            </p> */}
          </CardContent>
        </Card>
      </CardContent>
      <CardFooter className="flex items-center justify-between">
        <p className="w-1/2 ">Balance for this {filter}</p>
        <div className="flex items-center gap-2 font-semibold">
          {localLoader ? (
            <Skeleton className="h-6 w-32" />
          ) : (
            <>
              <LocaleAmount value={balance} />{" "}
              {balance < 0 ? (
                <TrendingDown size={18} className="stroke-red-500" />
              ) : (
                <TrendingUp size={18} className="stroke-green-500" />
              )}
            </>
          )}
        </div>
      </CardFooter>
    </Card>
  );
}
