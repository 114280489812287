import { ArrowDown, ArrowUp, Grid } from 'feather-icons-react/build/IconComponents'
import { Shapes } from 'lucide-react'
import React from 'react'
import { Button } from './ui/button'
import { clsx } from 'clsx'

const IconContainer = ({ children, className = "", style = {}, type, ...rest }) => {
  return (
    <Button
      variant="secondary"
      size="icon"
      className={clsx(
        'rounded-full ',
        type === 'income' && 'bg-green-500/10 hover:bg-green-500/20 text-primary',
        type === 'expense' && 'bg-red-500/10 hover:bg-red-500/20 text-destructive',
        className
      )}
      style={{...style}}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default function Icon(props) {
  const { isTransfer, type, emoji, children } = props;
  if (isTransfer) {
    return (
      <IconContainer {...props}>
        {
          type === 'income' && <ArrowDown size={20} />
        }
        {
          type === 'expense' && <ArrowUp size={20} />
        }
      </IconContainer>
    )

  }
  return (
    <IconContainer {...props}>
      {emoji || children || <Shapes className="stroke-foreground/80" size={20} />}
    </IconContainer>
  )
}
