import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";

import { cn } from "utils/lib";

const buttonVariants = cva(
  "inline-flex gap-1.5 items-center justify-center whitespace-nowrap rounded-md  ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground hover:bg-primary/90 font-medium text-sm ",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90 font-medium text-sm ",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground font-medium text-sm hc:border hc:border-primary",
        back: "border border-input md:border-none bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80 font-medium text-sm hc:border hc:border-primary",
        ghost: "text-sm hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 underline !p-0 mt-0",
        "link-hover":
          "text-primary underline-offset-4 hover:underline !p-0 mt-0",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-9 w-9 justify-center",
        "tiny-icon": "h-5 w-5 justify-center",
        back: "h-10 w-10 md:w-auto md:px-4 md:py-2",
        link: "h-auto w-auto p-0 m-0",
        inline: "h-auto w-auto p-0 px-2 m-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button = React.forwardRef(
  ({ className, variant, size, asChild = false, href, ...props }, ref) => {
    const Comp = asChild ? Slot : href ? "a" : "button";

    // if variant is link, make the size to link
    if (variant === "link" || variant === "link-hover") {
      size = "link";
    }

    const commonProps = {
      className: cn(buttonVariants({ variant, size, className })),
      ref,
      ...props,
    };

    return (
      <Comp
        {...(href ? { href } : {})}
        {...commonProps}
        // className="hc:bg-red-500"
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
