import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListItem from "../components/listItem";

import useGlobalContext from "../contexts/globalContext";
import Page from "components/page";
import PageContent from "components/page-content";
import PageNav from "components/page-nav";
import { TypographyH2 } from "components/typography";
import {
  Banknote,
  Coins,
  Eye,
  EyeOff,
  Landmark,
  Lightbulb,
  Loader2,
  Plus,
  Wallet,
  Wallet2,
} from "lucide-react";
import { Button } from "components/ui/button";
import { motion } from "framer-motion";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "components/ui/sheet";
import { pb } from "utils/pbConfig";
import { useToast } from "components/ui/use-toast";
import { handleError } from "utils/helpers";
import { Switch } from "components/ui/switch";
import { Alert, AlertDescription, AlertTitle } from "components/ui/alert";
import { formatISO } from "date-fns";
import { ChevronRight } from "feather-icons-react/build/IconComponents";
import LocaleAmount from "components/locale-amount";

const ICONS = {
  wallet: Wallet2,
  cash: Banknote,
  savings: Landmark,
  coins: Coins,
};

export default function ManageWallets() {
  const { wallets, getWallets } = useGlobalContext();
  const navigate = useNavigate();
  const [showArchived, setShowArchived] = useState(false);
  // const [showPicker, setShowPicker] = useState(false);
  // const [emoji, setEmoji] = useState('');
  const [data, setData] = useState({});
  const [initialFund, setInitialFund] = useState(true);

  const [loading, setLoading] = useState(false);
  const [openSideSheet, setOpenSideSheet] = useState(false);
  const { toast } = useToast();

  const manageWallet = (data) => {
    navigate(`${data.id}`, { state: data });
  };

  const filterWallet = () => {
    if (showArchived) {
      return wallets;
    }
    return wallets.filter((x) => !x.archived);
  };

  const createWallet = async () => {
    setLoading(true);

    try {
      const entry = {
        ...data,
        user: pb.authStore.model.id,
      };

      Object.keys(data).forEach((key) => data[key] == null && delete data[key]);

      const newWallet = await pb.collection("wallets").create(entry);

      if (data?.income) {
        const entryData = {
          date: formatISO(new Date()),
          amount: data.income,
          user: pb.authStore.model.id,
          description: "Initial fund",
          is_special: true,
          special_attributes: {
            type: "initial",
          },
        };
      }

      await getWallets(true, () => setLoading(false));

      setOpenSideSheet(false);
      setData({});
      toast({
        title: "Wallet created",
        description: `Wallet ${newWallet.name} created successfully`,
      });
    } catch (error) {
      console.log("error :>> ", error);
      handleError(error, toast);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = data;
    createWallet(formData);
  };

  const handleChange = (e) => {
    if (!e.target) return; // Add null check for e.target

    const { id, value } = e.target;

    setData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  useEffect(() => {
    console.log("data :>> ", data);
  }, [data]);

  const walletsList = filterWallet();

  return (
    <motion.main
      initial={{ opacity: 0, x: "50%" }}
      animate={{ opacity: 1, x: 0 }}
      // exit={{ opacity: 0, x: "50%" }}
      transition={{ duration: 0.1 }}
    >
      <Page>
        <PageNav backTitle="Back">
          <Sheet
            onSubmit={handleSubmit}
            onOpenChange={setOpenSideSheet}
            open={openSideSheet}
          >
            <SheetTrigger asChild>
              <Button
                variant="outline"
                size="lg"
                onClick={() => {
                  setOpenSideSheet(true);
                }}
              >
                Create new <Plus size={18} />
              </Button>
            </SheetTrigger>
            <SheetContent
              side="right"
              className="overflow-y-scroll max-h-screen"
            >
              <SheetHeader>
                <SheetTitle>Create New Wallet</SheetTitle>
                <SheetDescription>
                  Add a new wallet to your account.
                </SheetDescription>
              </SheetHeader>
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 py-4">
                  {/* @TODO Integrate wallet icon */}
                  {/* <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="emoji" className="text-right">
                      Icon
                    </Label>
                    <Dialog open={showPicker} onOpenChange={setShowPicker}>
                      <DialogTrigger asChild>
                        <Button variant="secondary" size="icon" className="rounded-full">
                          {emoji || <Shapes className="stroke-primary" size={18} />}
                        </Button>
                      </DialogTrigger>
                    </Dialog>
                    <Input id="emoji" value={emoji} on type="hidden" className="col-span-3"
                    // onChange={e => setEmoji(e.target.value)}
                    />
                  </div> */}
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="name" className="text-right">
                      Name
                    </Label>
                    <Input
                      id="name"
                      className="col-span-3"
                      placeholder="Wallet name"
                      onChange={(e) => handleChange(e)}
                      value={data?.name}
                      // maxLength={20}
                    />
                  </div>
                  {/* @TODO Integrate Account Number first */}
                  {/* <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="account" className="text-right">
                      Account Number
                    </Label>
                    <Input id="account" className="col-span-3" placeholder="XXXXXX (optional)"
                      onChange={e => handleChange(e)}
                      value={data?.account}
                    />
                  </div> */}
                  <hr />
                  <div className="flex items-center space-x-2">
                    <Switch
                      id="initial"
                      checked={initialFund}
                      onCheckedChange={() => {
                        setInitialFund(!initialFund);
                        handleChange(!initialFund);
                      }}
                    />
                    <Label htmlFor="initial">Also setup initial fund?</Label>
                  </div>
                  {initialFund && (
                    <>
                      <Alert className="w-full">
                        <Lightbulb className="mr-2" size={16} />
                        <AlertTitle>Initial fund?</AlertTitle>
                        <AlertDescription>
                          Initial fund will be added to the wallet balance and
                          will create a special entry.
                        </AlertDescription>
                      </Alert>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="income" className="text-right">
                          Initial balance
                        </Label>
                        <Input
                          id="income"
                          type="number"
                          className="col-span-3"
                          placeholder="0.00"
                          onChange={(e) => handleChange(e)}
                          value={data?.balance}
                        />
                      </div>
                    </>
                  )}
                </div>
                <SheetFooter className="mb-6">
                  <SheetClose asChild>
                    <Button variant="secondary" size="sm" className="mr-2">
                      Cancel
                    </Button>
                  </SheetClose>

                  <Button type="submit">
                    {loading ? (
                      <Loader2 size={18} className="animate-spin" />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </SheetFooter>
              </form>
            </SheetContent>
          </Sheet>
        </PageNav>
        <PageContent>
          <div>
            <TypographyH2 className="mt-4">Wallets</TypographyH2>
            <div className="flex flex-col gap-2 md:flex-row md:justify-between md:items-center">
              <p className="text-muted-foreground">
                Create and manage your wallets here
              </p>
              <Button
                variant="secondary"
                size="sm"
                className="self-start"
                onClick={() => setShowArchived(!showArchived)}
              >
                {showArchived ? (
                  <>
                    <EyeOff size={15} />
                    Hide archived
                  </>
                ) : (
                  <>
                    <Eye size={15} />
                    Show archived
                  </>
                )}
              </Button>
            </div>
          </div>
          <ul>
            {walletsList.length > 0 &&
              walletsList.map((wallet) => {
                const IconComponent = ICONS[wallet.icon] || Wallet2;
                const walletbalance =
                  parseFloat(wallet.income) - parseFloat(wallet.expense);

                return (
                  <ListItem
                    key={wallet.id}
                    title={wallet.name}
                    description={wallet.description}
                    emoji={<IconComponent size={24} />}
                    onClick={() => manageWallet(wallet)}
                    showIcon
                    className="mb-2 last:border-b-0 border-b rounded-none"
                    actionComponent={
                      <div className="flex items-center gap-3">
                        <div className="bg-secondary rounded-full p-1 px-2">
                          <LocaleAmount value={walletbalance} />
                        </div>
                        <ChevronRight size={16} />
                      </div>
                    }
                  />
                );
              })}
          </ul>
          {walletsList.length <= 0 && (
            <div className="flex flex-col items-center justify-center h-full">
              <Wallet size={48} className="text-primary" />
              <p className="text-primary text-xs">
                You have no wallets yet. Create one to get started.
              </p>
            </div>
          )}
        </PageContent>
      </Page>
    </motion.main>
  );
}
