import {
  Banknote,
  CheckIcon,
  Coins,
  Landmark,
  Loader2,
  Wallet2,
} from "lucide-react";
import { useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import Icon from "./entry-icon";
import { Button } from "./ui/button";

const IconAndDialog = ({ icon, updateIcon, loading }) => {
  const ICONS = {
    wallet: Wallet2,
    cash: Banknote,
    savings: Landmark,
    coins: Coins,
  };
  const [iconType, setIconType] = useState(icon || "wallet");
  const [showIconPicker, setShowIconPicker] = useState(false);

  const IconComponent = ICONS[icon];
  return (
    <Dialog open={showIconPicker} onOpenChange={setShowIconPicker}>
      <DialogTrigger asChild>
        <Icon>
          <IconComponent
            size={18}
            className={iconType === icon ? "outline-primary" : ""}
          />
        </Icon>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Pick a wallet icon</DialogTitle>
        </DialogHeader>
        <DialogDescription className="flex gap-4 items-center">
          {/* e-wallet */}
          {Object.keys(ICONS).map((key) => {
            const IconPicker = ICONS[key];
            return (
              <div className="relative">
                <Icon
                  key={key}
                  onClick={() => setIconType(key)}
                  className={`relative cursor-pointer ${
                    key === iconType ? "outline outline-primary" : ""
                  }`}
                >
                  <IconPicker size={24} />
                </Icon>
                {key === iconType && (
                  <div className="rounded-full p-1 absolute -bottom-2 -right-2 text-white bg-primary">
                    <CheckIcon size={12} />
                  </div>
                )}
              </div>
            );
          })}
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button
            disabled={loading}
            onClick={() => {
              updateIcon(iconType, () => setShowIconPicker(false));
            }}
          >
            {loading ? (
              <Loader2 size={18} className="animate-spin" />
            ) : (
              "Choose"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default IconAndDialog;
