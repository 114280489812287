import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavHeader from "../components/navHeader";
import ListItem from "../components/listItem";

import useGlobalContext from "../contexts/globalContext";
import Page from "components/page";
import PageContent from "components/page-content";
import PageNav from "components/page-nav";
import { TypographyH2 } from "components/typography";
import { ListMinus, ListPlus, Loader2, Plus, Shapes } from "lucide-react";
import { Button } from "components/ui/button";
import { motion } from "framer-motion";
import { Tabs, TabsList, TabsTrigger } from "components/ui/tabs";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "components/ui/sheet";
import EmojiPicker from "emoji-picker-react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
  DialogClose,
  DialogDescription,
  DialogTitle,
} from "components/ui/dialog";
import { pb } from "utils/pbConfig";
import { useToast } from "components/ui/use-toast";
import { handleError } from "utils/helpers";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordion";
import { Switch } from "components/ui/switch";
import { CardContent, Card, CardHeader } from "components/ui/card";

export default function ManageCategories() {
  const navigate = useNavigate();
  const location = useLocation();

  const [type, setType] = useState(location.state?.type || "income");
  const [editingType, setEditingType] = useState("income"); // income or expense
  const { categories, getCategories } = useGlobalContext();
  const [emoji, setEmoji] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const [openSideSheet, setOpenSideSheet] = useState(false);
  const [name, setName] = useState("");
  const [isNew, setIsNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [isArchive, setIsArchive] = useState(false);

  const { toast } = useToast();

  const categoriesCopy = JSON.parse(JSON.stringify(categories));
  const filteredCategories = categories.filter(
    (x) => x.type === type && x.archived === false
  );
  const archivedCategories = categoriesCopy.filter(
    (x) => x.archived === true && x.type === type
  );
  const toggleType = () => {
    if (type === "income") {
      setType("expense");
    } else {
      setType("income");
    }
  };

  const onEmojiClick = (emojiObject) => {
    setEmoji(emojiObject.emoji);
    setShowPicker(false);
  };

  const reset = () => {
    setIsNew(true);
    setName("");
    setEmoji(null);
    setEditingType("income");
    setEditingId(null);
    setIsArchive(null);
  };

  const createCategory = async (data) => {
    setLoading(true);
    try {
      const record = await pb.collection("categories").create(data);
      await getCategories(true);
      setOpenSideSheet(false);
    } catch (error) {
      handleError(error, toast);
    } finally {
      setLoading(false);
    }
  };

  const updateCategory = async (data) => {
    setLoading(true);
    try {
      const record = await pb.collection("categories").update(editingId, data);
      await getCategories(true);
      setOpenSideSheet(false);
    } catch (error) {
      handleError(error, toast);
    } finally {
      setLoading(false);
    }
  };

  const editCategory = (data) => {
    setName(data.name);
    setEmoji(data.emoji);
    setEditingType(data.type);
    setOpenSideSheet(true);
    setIsNew(false);
    setEditingId(data.id);
    setIsArchive(data.archived);
  };

  const deleteCategory = async () => {
    setLoading(true);
    try {
      const record = await pb.collection("categories").delete(editingId);
      await getCategories(true);
      setOpenSideSheet(false);
    } catch (error) {
      handleError(error, toast);
    } finally {
      setLoading(false);
      reset();
    }
  };

  const viewCategoryEntries = (data) => {
    // const category = data?.expand?.category?.name;
    // const id = data?.expand?.category?.id;
    // const name = data?.expand?.category?.name;
    // const type = data?.expand?.category?.type;

    // console.log('data :>> ', data);

    // console.log('category :>> ', category);

    // convert category to slug format

    console.log("data :>> ", data);

    const { id, name, type, emoji } = data;

    const slug = name.toLowerCase().replace(/\s/g, "-");

    const state = { id, name, type, emoji };

    navigate(`/app/entries/category/${slug}`, { state: { data: state } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      emoji: emoji,
      type: editingType,
      user: pb.authStore.model.id,
      archive: isArchive,
    };

    // remove null values
    Object.keys(data).forEach((key) => data[key] == null && delete data[key]);

    isNew ? createCategory(data) : updateCategory(data);

    // console.log('data :>> ', data);
  };

  return (
    <motion.main
      initial={{ opacity: 0, x: "50%" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "50%" }}
      transition={{ duration: 0.1 }}
    >
      <Page>
        <PageNav backTitle="Back">
          <Sheet
            onSubmit={handleSubmit}
            onOpenChange={setOpenSideSheet}
            open={openSideSheet}
          >
            <SheetTrigger asChild>
              <Button
                variant="outline"
                size="lg"
                onClick={() => {
                  reset();
                  setOpenSideSheet(true);
                }}
              >
                Create new <Plus size={18} />
              </Button>
            </SheetTrigger>
            <SheetContent
              side="right"
              className="overflow-y-scroll max-h-screen"
            >
              <SheetHeader>
                <SheetTitle>
                  {isNew ? "Create new category" : "Edit category"}
                </SheetTitle>
                <SheetDescription>
                  {isNew
                    ? "Add new category here. Click save when you're done."
                    : "Edit category here. Click save when you're done."}
                </SheetDescription>
              </SheetHeader>
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="emoji" className="text-right">
                      Icon
                    </Label>
                    <Dialog open={showPicker} onOpenChange={setShowPicker}>
                      <DialogTrigger asChild>
                        <Button
                          variant="secondary"
                          size="icon"
                          className="rounded-full"
                        >
                          {emoji || (
                            <Shapes className="stroke-primary" size={18} />
                          )}
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="sm:max-w-[425px]">
                        <DialogHeader>
                          <DialogTitle>Pick an icon</DialogTitle>
                        </DialogHeader>
                        <EmojiPicker
                          onEmojiClick={onEmojiClick}
                          emojiStyle="native"
                          skinTonesDisabled
                          suggestedEmojisMode=""
                        />
                        <DialogFooter>
                          <DialogClose asChild>
                            <Button className="btn btn-error mr-2">
                              Cancel
                            </Button>
                          </DialogClose>
                        </DialogFooter>
                      </DialogContent>
                    </Dialog>
                    <Input
                      id="emoji"
                      value={emoji}
                      on
                      type="hidden"
                      className="col-span-3"
                      // onChange={e => setEmoji(e.target.value)}
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="name" className="text-right">
                      Name
                    </Label>
                    <Input
                      id="name"
                      className="col-span-3"
                      placeholder="Category name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="type" className="text-right">
                      Type
                    </Label>
                    <div className="col-span-3 flex">
                      <Tabs
                        className=""
                        defaultValue={editingType}
                        // disabled={!isNew}
                      >
                        <TabsList className="grid w-full grid-cols-3">
                          <TabsTrigger
                            value="income"
                            disabled={!isNew}
                            onClick={() => setEditingType("income")}
                          >
                            <ListPlus size={16} className="mr-2" />
                            Income
                          </TabsTrigger>
                          <TabsTrigger
                            value="expense"
                            disabled={!isNew}
                            onClick={() => setEditingType("expense")}
                          >
                            <ListMinus size={16} className="mr-2" />
                            Expense
                          </TabsTrigger>
                        </TabsList>
                      </Tabs>
                    </div>
                  </div>
                  {!isNew && (
                    <Card>
                      <CardHeader>
                        <div className="flex items-center space-x-2">
                          <Switch
                            id="is_archive"
                            checked={isArchive}
                            onCheckedChange={() => setIsArchive(!isArchive)}
                          />
                          <Label htmlFor="is_archive">Archive category?</Label>
                        </div>
                      </CardHeader>
                      <CardContent>
                        <p className="text-sm text-muted-foreground">
                          Archived categories will not be shown in the list of
                          categories when creating new entries.
                        </p>
                      </CardContent>
                    </Card>
                  )}
                </div>
                <SheetFooter className="mb-6">
                  <Button type="submit">
                    {loading ? (
                      <Loader2 size={18} className="animate-spin" />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </SheetFooter>

                {!isNew && (
                  <>
                    <hr />
                    <Accordion type="single" collapsible>
                      <AccordionItem value="item-1" className="border-none">
                        <AccordionTrigger className="text-destructive">
                          Danger Zone
                        </AccordionTrigger>
                        <AccordionContent>
                          <Dialog>
                            <DialogTrigger asChild>
                              <Button variant="destructive">
                                Delete category
                              </Button>
                            </DialogTrigger>

                            <DialogContent>
                              <DialogHeader>
                                <DialogTitle>Are you sure?</DialogTitle>
                                <DialogDescription>
                                  This action cannot be undone. You can consider
                                  archiving this instead. If you click delete,
                                  all entries under this category will become
                                  uncategorized. Note that you cannot assign an
                                  entry to another category.
                                </DialogDescription>
                              </DialogHeader>

                              <DialogFooter>
                                <DialogClose asChild>
                                  <Button
                                    variant="secondary"
                                    disabled={loading}
                                  >
                                    Cancel
                                  </Button>
                                </DialogClose>
                                <Button
                                  variant="destructive"
                                  onClick={deleteCategory}
                                >
                                  {loading ? (
                                    <Loader2
                                      size={18}
                                      className="animate-spin"
                                    />
                                  ) : (
                                    "Delete"
                                  )}
                                </Button>
                              </DialogFooter>
                            </DialogContent>
                          </Dialog>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </>
                )}
              </form>
            </SheetContent>
          </Sheet>
        </PageNav>
        <PageContent>
          <div>
            <TypographyH2 className="mt-4">Categories</TypographyH2>
            <p className="text-muted-foreground">
              Create and manage your income and expense categories here.
            </p>
          </div>
          <div className="flex justify-between">
            <Tabs className="w-full md:w-max mb-4" defaultValue={type}>
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="income" onClick={toggleType}>
                  Income
                </TabsTrigger>
                <TabsTrigger value="expense" onClick={toggleType}>
                  Expense
                </TabsTrigger>
              </TabsList>
            </Tabs>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                setLoading(true);
                getCategories(true, () => setLoading(false));
              }}
            >
              {/* Refresh */}
              {loading ? (
                <Loader2 size={18} className="animate-spin" />
              ) : (
                "Refresh"
              )}
            </Button>
          </div>

          {filteredCategories.length <= 0 && (
            <div className="flex flex-col items-center justify-center h-full">
              <Shapes size={48} className="text-primary" />
              <p className="text-primary text-xs">
                You have no {type} categories yet. Create one to get started.
              </p>
            </div>
          )}

          <ul>
            {filteredCategories.length > 0 &&
              filteredCategories.map((category) => (
                <ListItem
                  key={category.name}
                  title={category.name}
                  action={() => editCategory(category)}
                  emoji={category.emoji}
                  showIcon
                  onClick={() => viewCategoryEntries(category)}
                />
              ))}
            {archivedCategories.length > 0 && (
              <div className="p-3 border-t bg-background text-center text-xs uppercase tracking-widest opacity-50">
                Archived
              </div>
            )}

            {archivedCategories.length > 0 &&
              archivedCategories.map((category) => (
                <ListItem
                  key={category.name}
                  title={category.name}
                  action={() => editCategory(category)}
                  emoji={category.emoji}
                  showIcon
                />
              ))}
          </ul>
        </PageContent>
      </Page>
      {/* edit and create sheet */}

      <div className="grid grid-cols-2 gap-2"></div>
    </motion.main>
  );
}
