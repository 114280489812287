"use client";

import { useEffect, useState } from "react";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Button } from "../components/ui/button";
import { Calendar, Check, Delete, Loader2 } from "lucide-react";
import { DatePicker } from "./ui/date-picker";
import { format } from "date-fns";
import { isToday } from "date-fns";
import { e } from "mathjs";

export default function CurrencyInputWithNumpad({
  onCancel,
  onConfirm,
  date,
  onChangeDate,
  value,
  setValue,
  loading,
  showNote,
  note,
  onChangeNote,
}) {
  //   const [value, setValue] = useState("");

  const formatCurrency = (cents) => {
    return (cents / 100)
      .toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .replace("$", "");
  };

  const handleInputChange = (input) => {
    const cents = parseInt(input, 10);
    if (isNaN(cents)) {
      setValue("");
    } else {
      setValue(formatCurrency(cents));
    }
  };

  const handleNumpadClick = (digit) => {
    const currentCents = parseInt(value.replace(/[^0-9]/g, ""), 10) || 0;
    const newCents = currentCents * 10 + parseInt(digit, 10);

    if (digit === "00") {
      handleInputChange((newCents * 10).toString());
    } else {
      handleInputChange(newCents.toString());
    }
  };

  const handleClear = () => {
    setValue("");
  };

  const handleBackspace = () => {
    const currentCents = parseInt(value.replace(/[^0-9]/g, ""), 10) || 0;
    const newCents = Math.floor(currentCents / 10);
    handleInputChange(newCents.toString());
  };

  const numpadButtons = [
    "7",
    "8",
    "9",
    "4",
    "5",
    "6",
    "1",
    "2",
    "3",
    "0",
    "00",
  ];

  useEffect(() => {
    // check if value is a number then convert to string with this format 0.00
    if (typeof value === "number") {
      setValue(formatCurrency(value * 100));
    }
  }, []);

  return (
    <div className="w-full flex flex-col justify-between max-w-sm space-y-4 flex-1 mx-auto">
      <div className="text-center">
        <Label htmlFor="currency-input" className="text-center">
          Enter Amount
        </Label>
        <div className="relative my-2">
          <Input
            id="currency-input"
            type="text"
            inputMode="numeric"
            value={value}
            onChange={(e) =>
              handleInputChange(e.target.value.replace(/[^0-9]/g, ""))
            }
            className="p-6 text-right text-3xl font-semibold font-mono border-2 "
            placeholder="0.00"
          />
          <span className="absolute left-6 top-1/2 -translate-y-1/2 text-gray-500 text-2xl">
            ₱
          </span>
        </div>
        {showNote && (
          <textarea
            rows="2"
            name="description"
            className="textarea text-base rounded-none p-0 mb-0 outline-none w-full bg-transparent text-center"
            placeholder="Add a note/comment..."
            onChange={(e) => onChangeNote(e.target.value)}
          >
            {note}
          </textarea>
        )}
      </div>
      <div className="grid grid-cols-4 gap-2 pb-4 mt-auto">
        {numpadButtons.map((btn) => (
          <Button
            key={btn}
            variant="outline"
            className="text-lg w-full h-auto aspect-square rounded-full text-2xl font-semibold"
            onClick={() => handleNumpadClick(btn)}
          >
            {btn}
          </Button>
        ))}
        {/* <Button
          variant="outline"
          className="text-lg h-12 rounded-full col-span-2"
          onClick={handleClear}
        >
          btn
        </Button> */}
        <Button
          variant="destructive"
          className="text-lg w-full h-auto aspect-square rounded-full bg-destructive/80 col-start-4 row-start-1"
          onClick={handleBackspace}
        >
          <Delete size={20} />
        </Button>

        <Button
          variant="default"
          className="text-lg w-full rounded-full h-full col-start-4 row-start-3 row-span-2"
          onClick={onConfirm}
          disabled={!value}
        >
          {loading ? <Loader2 className="animate-spin" /> : <Check size={20} />}
        </Button>

        <DatePicker
          date={date}
          onChange={onChangeDate}
          triggerComponent={
            <Button
              variant="outline"
              className="flex-col gap-1 text-lg w-full h-auto aspect-square rounded-full col-start-4 row-start-2"
              // onClick={handleBackspace}
            >
              <Calendar size={20} />
              <div className="text-xs uppercase font-bold">
                {isToday(date) ? "Now" : format(date, "MMM d")}
              </div>
            </Button>
          }
        />

        <Button
          variant="destructive"
          className="text-xs uppercase font-bold w-full h-auto aspect-square rounded-full col-start-1 row-start-4"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
