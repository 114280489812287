import { Copy } from "lucide-react";
import React from "react";
import { Button } from "./ui/button";
import { useToast } from "./ui/use-toast";
import { clsx } from "clsx";

export default function LocaleAmount({ value, shorter = false, className }) {
  const numberFormatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  });

  const { toast } = useToast();

  function numberShortFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(1).replace(rx, "$1") + item.symbol
      : "0";
  }

  if (shorter) {
    return <span>{numberShortFormatter(value)}</span>;
  }

  let textValue = numberFormatter.format(value);

  // fix negative on zero due to bug in js float
  if (textValue === "-0.00") {
    return "0.00";
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textValue);
    toast({
      title: "Copied to clipboard",
      description: "The value has been copied to your clipboard",
      status: "success",
    });
  };

  return (
    <span className={clsx("relative group font-mono", className)}>
      {/* Use Hover Card for this */}
      {/* <Button
        variant="outline"
        className="hidden group-hover:flex absolute top-1/2 -left-5 -translate-y-1/2"
        size="tiny-icon"
        onClick={copyToClipboard}
      >
        <Copy size={10} />
      </Button> */}
      {textValue}
    </span>
  );
}
