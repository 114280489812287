import { ArrowDownUp, BookHeart, MinusCircle, LayoutDashboard, PieChart, PlusCircle, Shapes, Wallet, UserCircle2, Settings, ArrowLeftRight, Bookmark, Refrigerator, LineChart, List } from "lucide-react"


const icons = {
  "plus-circle": PlusCircle,
  "minus-circle": MinusCircle,
  "arrow-down-up": ArrowDownUp,
  "dashboard": LayoutDashboard,
  "pie": PieChart,
  "wallet": Wallet,
  "shapes": Shapes,
  "book-heart": BookHeart,
  "account": UserCircle2,
  "settings": Settings,
  "transfer": ArrowLeftRight,
  "bookmark": Bookmark,
  "refrigerator": Refrigerator,
  "line-chart": LineChart,
  "list": List,
}


export const IconComponent = ({ name, size = 18, className }) => {
  const Icon = icons[name]
  return Icon && <Icon size={size} className={className} />
}