import React, { useState } from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "utils/lib";
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover";

export function DatePicker({ onChange, name, date, placeholder, triggerComponent }) {

  const handleDateChange = (selectedDate) => {
    if (selectedDate.toDateString() === date?.toDateString()) {
      return; // Same day selected, no change
    }
    onChange(selectedDate);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        {triggerComponent || (
          <Button
            variant="secondary"
            className={cn(
              "flex-col h-16 w-16 text-xs gap-0 ",
              !date && "text-muted-foreground"
            )}
          >
            <div className="uppercase font-semibold">
              {date && format(date, "MMM")}
            </div>

            <div className="text-lg">{date && format(date, "dd")}</div>
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={onChange}
          initialFocus={true}
          minDate={new Date()}
        />
      </PopoverContent>
    </Popover>
  );
}
