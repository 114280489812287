import MainHeader from "components/mainHeader";
import {
  TypographyH1,
  TypographyH3,
  TypographyLarge,
  TypographySmall,
} from "components/typography";
import { Button } from "components/ui/button";
import useGlobalContext from "contexts/globalContext";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordion";
import Footer from "components/footer";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { ChevronDown, Eye, EyeOff } from "lucide-react";
import { useEffect, useState } from "react";
import clsx from "clsx";
import LocaleAmount from "components/locale-amount";
import homepageHero from "./../asset/image/app.png";
import homepageHeroMobile from "./../asset/image/mobile.jpeg";
import HomepageBg from "components/homepage-bg/homepage-bg";
import useSecretCode from "hooks/useSecretCode";
import { useToast } from "components/ui/use-toast";
import { ToastAction } from "components/ui/toast";
import { Avatar, AvatarImage } from "components/ui/avatar";
import { MobileCardCarousel } from "components/mobile-card-carousel";
import { WaitlistForm } from "components/waitlist-form";

const mockWallets = [
  {
    id: 1,
    name: "Cash",
    is_selected: true,
    total: 10000,
  },
  {
    id: 2,
    name: "Bank account",
    is_selected: true,
    total: 69,
  },
  {
    id: 3,
    name: "E-wallet",
    is_selected: true,
    total: 420,
  },
];

const Home = () => {
  const { isLoggedIn, setEnableSignUp } = useGlobalContext();
  const [showValue, setshowValue] = useState(true);
  const [wallets, setWallets] = useState(mockWallets);

  const { toast } = useToast();
  const navigate = useNavigate();

  useSecretCode(() => {
    setEnableSignUp(true);
    toast({
      title: "Cheat activated!",
      description: "You unlocked the early access to sign up.",
      action: (
        <ToastAction
          altText="Create an account"
          onClick={() => navigate("sign-up")}
        >
          Go to sign up
        </ToastAction>
      ),
    });
  });

  useEffect(() => {
    // check if installed as PWA
    const isPWA = window.matchMedia("(display-mode: standalone)").matches;
    if (isPWA) {
      navigate("/app");
    }
  }, [navigate]);

  if (isLoggedIn) {
    return <Navigate to="/app" />;
  }

  const toggleVisibility = () => {
    setshowValue(!showValue);
  };

  const checkWallet = (id) => {
    const prevState = [...wallets];
    const index = prevState.findIndex((x) => x.id === id);
    prevState[index].is_selected = !prevState[index].is_selected;
    setWallets(prevState);
  };

  const total = wallets
    .filter((x) => x.is_selected)
    .reduce((acc, curr) => acc + curr.total, 0);

  return (
    <div className="bg-paper">
      <MainHeader />
      <section className="w-full relative ">
        {/* <HomepageBg /> */}
        <div className="relative text-center py-20 px-4 t flex flex-col max-w-7xl mx-auto gap-4 justify-center items-center">
          <TypographyH1 className="lg:text-7xl max-w-4xl">
            Goodbye spreadsheets,
            <br /> hello <span className="animate-pulse">Budget Baboy</span>!
          </TypographyH1>
          <TypographyLarge className="max-w-xl">
            Eww, budget tracking sa spreadsheets is so 2020s. Budget Baboy is
            the way to go
          </TypographyLarge>
          <div className="flex gap-4 mb-8">
            <Button
              variant="outline"
              className="self-center px-6"
              onClick={() => {
                alert("It's up to you, buhay mo yan.");
              }}
            >
              Nah, I'd pass
            </Button>
            <Button
              onClick={() => {
                window.location.href = "/login";
              }}
              className="self-center px-6"
            >
              It's baboy time!
            </Button>
          </div>
          <picture>
            <source
              srcSet={homepageHeroMobile}
              type="image/jpeg"
              media="(max-width: 767px)"
            />
            <source
              srcSet={homepageHero}
              type="image/png"
              media="(min-width: 768px)"
            />
            <img
              src={homepageHero}
              alt="piggy"
              className="mx-auto rounded-[40px] w-[80%] md:rounded-none shadow-xl shadow-black/50 md:w-full md:shadow-none"
            />
          </picture>
        </div>
      </section>
      <section className="py-20 px-4 text-center max-w-7xl mx-auto flex flex-col gap-4 justify-center items-center">
        <TypographyH1 className="lg:text-5xl max-w-4xl">
          Feeling mo ba wala ka ng pera pero meron pa talaga?
        </TypographyH1>
        <TypographyLarge className="max-w-xl mb-8">
          'Di ka kasi nagtra-track ng budget.
        </TypographyLarge>
        <div className="flex flex-col md:flex-row gap-x-24 gap-y-10 items-center">
          <Card className="shadow-2xl w-72 relative">
            <p className="font-caveat absolute -top-2 -right-4 rotate-30 text-3xl">
              Try me!
            </p>
            <CardHeader>
              <CardTitle>Current Total Balance</CardTitle>
              <h1 className="flex items-center  gap-4 text-4xl font-poppins leading-none">
                <div>
                  ₱
                  <span className={clsx(!showValue && "blur-lg")}>
                    <LocaleAmount value={total} />
                  </span>
                </div>
                <div
                  className="tooltip tooltip-top"
                  data-tip="Hide or show values"
                >
                  <Button
                    variant="secondary"
                    size="icon"
                    onClick={toggleVisibility}
                  >
                    {showValue ? <Eye size={20} /> : <EyeOff size={20} />}
                  </Button>
                </div>
              </h1>
            </CardHeader>
            <CardContent>
              <hr />
              <ul>
                {wallets.length > 0 &&
                  wallets.map((wallet) => (
                    <li
                      className="flex justify-between items-center py-2 gap-4"
                      key={wallet.id}
                    >
                      <input
                        type="checkbox"
                        checked={wallet.is_selected}
                        onChange={() => checkWallet(wallet.id)}
                        className="checkbox checkbox-sm"
                      />
                      <div className="flex-1 text-left">{wallet.name}</div>
                      <span className={clsx(!showValue && "blur-sm")}>
                        <LocaleAmount value={wallet.total} />
                      </span>
                    </li>
                  ))}
              </ul>
            </CardContent>
          </Card>
          <p className="w-96 md:text-left">
            Dito sa Budget Baboy pwede mag-dagdag ng multiple wallets,
            pangalanan mo kung anong gusto mo.
            <br /> <br />
            GCash, Cash, Maya, Bangko, Barya. Ikaw bahala!
          </p>
        </div>
      </section>
      <section className="py-20 px-4 text-center max-w-7xl mx-auto flex flex-col gap-4 justify-center items-center ">
        <TypographyH1 className="lg:text-7xl max-w-4xl ">
          Gastos. Lista. Repeat. <p className="font-caveat">Basic!</p>
        </TypographyH1>
        <TypographyLarge className="max-w-xl">
          Make budget tracking a habit of yours. It's easy to use, promise!
        </TypographyLarge>
      </section>

      <section
        className="py-20 px-8 text-center max-w-7xl mx-auto flex flex-col gap-4 justify-center items-center bg-primary/10 shadow-inner md:rounded-2xl"
        id="faq"
      >
        <TypographyH1 className="lg:text-7xl max-w-4xl">Mga FAQ</TypographyH1>
        <Accordion
          type="multiple"
          collapsible
          className="w-full text-left w-3/4 "
        >
          <AccordionItem value="item-1">
            <AccordionTrigger>
              Pamalit ba to sa Maya at GCash?{" "}
            </AccordionTrigger>
            <AccordionContent>
              Budget tracker nga diba? TRACKER mamen. Nag-tratrack ka ng budget
              mo. ‘Di ko hahandle yang pera mo noh.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger>Libre ba 'tong Budget Baboy?</AccordionTrigger>
            <AccordionContent>
              Ngayon oo, bukas pwede pa rin siguro. Plano ko gawing premium yung
              ibang features. Pero yung basics libre ko na. Pero di ko guarantee
              ha! May bayad ang server at hosting nito. Baka sa susunod may
              makita ka na mga ads. Bahala na.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger> Meron bang app sa phone?</AccordionTrigger>
            <AccordionContent>
              Wala sa appstore / playstore pero pwede i-install? PWA baby! Login
              ka lang tapos may mag-prompt naman kung paano i-install sundin mo
              lang. Madali lang, ang laki laki mo na e.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-4">
            <AccordionTrigger>
              Teka, bakit Budget Baboy? At saka bakit ka gumawa nitong Budget
              baboy?
            </AccordionTrigger>
            <AccordionContent>
              'Di ko rin alam brad, basta kumakain ako ng baboy tapos naisip ko
              ipangalan na din as app. Frustrated kasi ako sa mga budget
              tracking apps, minsan di kaya yung gusto ko gawin. Atleast ito
              tailored based sa experience ko i-handle yung pera ko.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-5">
            <AccordionTrigger>Open Source?</AccordionTrigger>
            <AccordionContent>
              Hindi pa, tara usap tayo. No man’s an island nga daw.
              Collaborators are welcome. Public pa naman source code nito.
              Hahaha pakyu mga college student na mangongopya ng code.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </section>
      <section className="py-20 px-4 text-center max-w-7xl mx-auto flex flex-col gap-4 justify-center items-center">
        <TypographyH1 className="lg:text-5xl max-w-4xl">
          Budget Baboy is one of the budget tracking apps of all all time.
        </TypographyH1>
        <TypographyLarge className="max-w-xl">
          Tagline here, tagline here
        </TypographyLarge>
      </section>
      <section
        className="py-20 px-4  max-w-7xl mx-auto flex flex-col gap-4 "
        id="team"
      >
        <TypographyH1 className="lg:text-5xl text-left max-w-4xl">
          Meet the team
        </TypographyH1>
        <TypographyLarge className="max-w-xl mb-4 text-left">
          We are The Baboy Collective, developers of Budget Baboy
        </TypographyLarge>
        <div className="flex gap-16 w-full">
          <MobileCardCarousel
            opts={{
              align: "center",
              // loop: true,
              skipSnaps: true,
              inViewThreshold: 0.7,
            }}
            cards={[
              {
                name: "Ughndrei",
                role: "UX/UI designer",
                image: "/images/dev-1.jpeg",
              },
              {
                name: "Andrei T.",
                role: "Project Manager",
                image: "/images/dev-2.jpeg",
              },
              {
                name: "Andrei yung pogi",
                role: "Chief Technology Officer",
                image: "/images/dev-3.jpeg",
              },
              {
                name: "Andreas Trinitius",
                role: "Founder/CEO",
                image: "/images/dev-4.jpeg",
              },
              {
                name: "Andrés dela Trinidad",
                role: "Lead Fullstack Developer",
                image: "/images/dev-5.jpeg",
              },
            ].map((member, index) => (
              <div
                key={index}
                className="mr-4 text-center flex flex-col items-center min-w-[200px] border border-primary rounded-2xl p-4"
              >
                <Avatar className="h-24 w-24 md:h-48 md:w-48 group mb-2">
                  <AvatarImage
                    className="group-hover:scale-125 transition ease-in-out"
                    src={member.image}
                  />
                </Avatar>
                <TypographyH3>{member.name}</TypographyH3>
                <TypographySmall>{member.role}</TypographySmall>
              </div>
            ))}
          />
        </div>
      </section>
      <section
        id="waitlist"
        className="py-20 px-4 max-w-7xl mx-auto flex flex-col gap-4 justify-center items-center bg-primary/10 shadow-inner md:rounded-2xl"
      >
        <TypographyH1 className="lg:text-5xl text-left max-w-4xl">
          Join the waitlist
        </TypographyH1>
        <WaitlistForm />
      </section>
      <section className="py-20 px-4 text-center max-w-7xl mx-auto flex flex-col gap-4 justify-center items-center">
        <TypographyH1 className="lg:text-5xl max-w-4xl">
          Wala ka man ipon, at least alam mo kung saan napupunta.
        </TypographyH1>
        <TypographyLarge className="max-w-xl">
          Hindi ka na shocked na wala ka na palang pera. Pero wala ka pa ring
          pera.
        </TypographyLarge>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
