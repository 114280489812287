import { ChevronDown, Grid, Trash } from 'feather-icons-react/build/IconComponents';
import React, { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Card from '../components/card'
import CardControls from '../components/cardControls';
import NavHeader from '../components/navHeader'
import useGlobalContext from '../contexts/globalContext';
import { pb } from '../utils/pbConfig';
import EmojiPicker from 'emoji-picker-react';
import { useHotkeys } from 'react-hotkeys-hook';
import { handleError } from 'utils/helpers';
import { useToast } from 'components/ui/use-toast';

export default function ManageCategory() {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  const [type, setType] = useState(data.type || 'income');
  const [name, setName] = useState(data.name || 'invalid');
  const [icon, setIcon] = useState(data.emoji || null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { getCategories, user } = useGlobalContext();
  const id = data.id;

  const { toast } = useToast();


  const goBack = () => {
    navigate('/categories', { state: { type } });
  }





  const updateCategory = async () => {
    const data = {
      "name": name,
      "emoji": icon,
    };

    try {
      const record = await pb.collection('categories').update(id, data);
      await getCategories();
      goBack();
    } catch (error) {
      handleError(error, toast);
    }
  }

  const deleteCategory = async () => {
    if (window.confirm("Deleting this category will make entries related to this uncategorized. Continue?") == true) {
      try {
        const record = await pb.collection('categories').delete(id);
        await getCategories();
        goBack();
      } catch (error) {
        console.log('error :>> ', error);
        alert('Something went wrong')
      }
    }
  }

  useHotkeys('esc', goBack)
  useHotkeys('ctrl+s', updateCategory, { preventDefault: true })

  return (
    <Card isPage>
      <NavHeader isPage title="Manage category" rightComponent={
        <button className='btn btn-ghost btn-circle btn-sm' onClick={deleteCategory}>
          <Trash size={20} className='stroke-error' />
        </button>

      } />

      <div className="collapse">
        <button className="px-0 w-full  flex items-center gap-2" onClick={() => setShowEmojiPicker(!showEmojiPicker)} >
          <span className='btn btn-square btn-lg bg-base-300 border-base-300 text-4xl mr-4'>{icon || <Grid className="stroke-primary" size={32} />}</span>
          Pick an emoji icon
          <ChevronDown className="mr-4" />
        </button>


        <div className=" px-0" >
          {
            showEmojiPicker && <EmojiPicker onEmojiClick={data => {
              setIcon(data.emoji)
            }} emojiStyle="native" />
          }
          {/*  */}

        </div>
      </div>

      <div className="form-control w-full max-w-xs">
        <label className="label p-0">
          <span className="label-text">Category Name</span>
        </label>
        <input
          value={name}
          onChange={e => setName(e.target.value)}
          type="text" placeholder="Type here" className="input input-bordered w-full max-w-xs" />
      </div>
      <div className="form-control w-full max-w-xs">
        <label className="label p-0">
          <span className="label-text">Category Type</span>
        </label>
        <input
          disabled
          value={type}
          type="text" className="input input-bordered w-full max-w-xs" />
      </div>

      <CardControls
        cancelAction={() => navigate(-1)}
        okTitle="Save"
        okAction={updateCategory}
        cancelKbdHelper={<kbd className="kbd kbd-xs">esc</kbd>}
        okKbdHelper={<div className='flex items-center'><kbd className="kbd kbd-xs">ctrl</kbd> + <kbd className="kbd kbd-xs">s</kbd></div>}
      />


    </Card>
  )
}
