import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { GlobalContextProvider } from './contexts/globalContext';

const Refocus = ({ children }) => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    ref.current.focus();
    console.log('this happened :>> ');
  }, []);
  return <div ref={ref}>{children}</div>;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <GlobalContextProvider>
      <Refocus>
        <App />
      </Refocus>
    </GlobalContextProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
