

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
dayjs.extend(calendar);

export default function DateComponent(props) {
  const { children, date } = props;
  const formattedDate = dayjs(date).isValid() ? dayjs(date).format('MMM DD') : '';

  return (
    <div className="text-xs uppercase tracking-widest">
      {children || formattedDate}
    </div>
  );
}

DateComponent.propTypes = {
  children: PropTypes.node,
  date: PropTypes.string,
};
