import React from "react";

export default function TwoColumnLayoutContainer({
  navComponents,
  leftComponents,
  rightComponents,
  className,
  variant = "default",
  children,
}) {
  const variantClasses = {
    default: {
      container: "flex flex-col w-full lg:flex-row gap-4 justify-center",
      left: "lg:w-1/2 flex flex-col gap-4",
      right: "lg:w-1/2 flex flex-col gap-4",
    },
    sidebar: {
      container: "flex flex-col w-full lg:flex-row gap-4 justify-center",
      left: "lg:w-2/5 flex flex-col gap-4",
      right: "lg:w-3/5 flex flex-col gap-4",
    },
    sidebarRight: {
      container: "flex flex-col w-full lg:flex-row gap-4 justify-center",
      left: "lg:w-3/5 flex flex-col gap-4",
      right: "lg:w-2/5 flex flex-col gap-4",
    },
    singleColumn: {
      container: "flex flex-col w-full max-w-[800px]",
      left: "flex flex-col gap-4",
      right: "flex flex-col gap-4",
    },
  };

  const { container, left, right } = variantClasses[variant];

  return (
    <div className="w-full">
      {navComponents && (
        <div className="flex px-4 min-h-[54px] flex-col gap-4 lg:flex-row lg:justify-between lg:items-center w-full sticky top-0 z-50 border-b mb-4">
          {navComponents}
        </div>
      )}
      <div className="px-4 max-w-2xl lg:max-w-7xl mx-auto">{children}</div>
      <div
        className={`flex flex-col ${container} ${className} max-w-2xl lg:max-w-7xl mx-auto px-4 `}
      >
        <div className={left}>{leftComponents || "left component"}</div>
        <div className={right}>{rightComponents || "right component"}</div>
      </div>
    </div>
  );
}
