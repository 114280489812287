import { addMonths, startOfMonth, subMonths } from 'date-fns';
import { format } from 'date-fns/esm';
import React from 'react'
import { useSwipeable } from 'react-swipeable';
import { Card, CardContent, CardHeader } from './ui/card';
import { ArrowLeft, Calendar, ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from './ui/button';

function BottomDateNav({ date, setDate, children, loading }) {
  const prevMonth = () => {
    const currentDate = new Date(date);
    const newDate = subMonths(currentDate, 1);
    setDate(format(startOfMonth(new Date(newDate)), 'yyyy-MM-dd'))
  }

  const nextMonth = () => {
    const currentDate = new Date(date);
    const newDate = addMonths(currentDate, 1);
    setDate(format(startOfMonth(new Date(newDate)), 'yyyy-MM-dd'))
  }

  const handlers = useSwipeable({
    onSwipedLeft: nextMonth,
    onSwipedRight: prevMonth,
  });

  return (
    <div className="sticky bottom-0 z-40"
      {...handlers}
    >
      <Card className="p-4">
        {/* <CardHeader> */}

        <div className="flex gap-2 justify-between items-center">
          <div className='flex items-center gap-4'>
            <Button variant="outline" size="icon" onClick={prevMonth} disabled={loading}>
              <ChevronLeft />
            </Button>


            <div className="flex gap-2 items-center">

              <Calendar size="15" />
              {format(new Date(date), 'MMM yyyy')}
            </div>
            <Button variant="outline" size="icon" onClick={nextMonth} disabled={loading}>
              <ChevronRight />
            </Button>
          </div>
          {children}
        </div>
        {/* </CardHeader> */}

      </Card>
    </div>
  )
}

export default BottomDateNav