import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { pb } from "../utils/pbConfig";
import {
  getFromLocalStorage,
  optimizedFetch,
  saveToLocalStorage,
  clearLocalStorage,
} from "utils/helpers";
import { Toast, ToastDescription, ToastTitle } from "components/ui/toast";
import { useToast } from "components/ui/use-toast";

const GlobalContext = createContext();

export function GlobalContextProvider({ children }) {
  // global states
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [entries, setEntries] = useState([]);
  const [stash, setStash] = useState([]);
  const [categories, setCategories] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({});
  const [enableSignUp, setEnableSignUp] = useState(false);

  const { toast } = useToast();

  const getUser = useCallback(() => {
    const authenticated = pb.authStore.isValid;
    setLoggedIn(authenticated);
    if (authenticated) {
      setUser({
        username: pb.authStore.model.username,
        name: pb.authStore.model.name,
        avatar: pb.authStore.model.avatar,
        id: pb.authStore.model.id,
        email: pb.authStore.model.email,
        onboarded: pb.authStore.model.onboarded,
      });

      // get local settings
      const localSettings = getFromLocalStorage("settings");
      if (localSettings?.data) {
        setSettings(localSettings.data);
      }
    }
  }, []);

  // const getStash = async () => {
  //   const records = await pb.collection('stash').getList(1, 100, {
  //     expand: 'wallet,category',
  //     sort: 'date',
  //   })
  //   setStash(records.items);
  // };

  const getWallets = useCallback(
    (force, callback) => {
      optimizedFetch({
        key: "wallets",
        collection: "wallets",
        stateSetter: setWallets,
        force,
        callback,
      });
    },
    [setWallets]
  );

  const getCategories = useCallback(
    (force, callback) => {
      optimizedFetch({
        key: "categories",
        collection: "categories",
        stateSetter: setCategories,
        perPage: 50,
        force,
        callback,
      });
    },
    [setCategories]
  );

  const getEntries = useCallback(
    (force, callback) => {
      optimizedFetch({
        key: "entries",
        collection: "entries",
        stateSetter: setEntries,
        perPage: 100,
        force,
        callback,
        options: {
          expand: "wallet,category",
          sort: "-date",
          filter: 'type != "stash"',
        },
      });
    },
    [setEntries]
  );

  const getStash = useCallback(
    (force, callback) => {
      optimizedFetch({
        key: "stash",
        collection: "entries",
        stateSetter: setStash,
        perPage: 100,
        force,
        callback,
        options: {
          expand: "wallet,category",
          sort: "-date",
          filter: 'type = "stash"',
        },
      });
    },
    [setStash]
  );

  const clearData = () => {
    setWallets([]);
    setCategories([]);
    setUser({});
    setStash([]);
    console.log("data cleared!");
  };

  const loadUserData = useCallback(() => {
    setLoading(true);
    getWallets();
    getCategories();
    getEntries();

    setLoading(false);
  }, [getWallets, getCategories, getEntries]);

  const logoutUser = useCallback(async () => {
    const logout = await pb.authStore.clear();
    await clearData();
    await clearLocalStorage();
    setLoggedIn(false);
  }, [clearData]);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      loadUserData();
    }
  }, [isLoggedIn, loadUserData]);

  const handleWalletUpdate = useCallback(
    (e) => {
      const updatedWallet = e.record;
      const updatedWallets = [...wallets];
      const walletIndex = updatedWallets.findIndex(
        (wallet) => wallet.id === updatedWallet.id
      );
      updatedWallets[walletIndex] = updatedWallet;
      setWallets(updatedWallets);
      saveToLocalStorage("wallets", updatedWallets);
    },
    [wallets]
  );

  // const handleCategoryUpdate = useCallback((e) => {
  //   const updatedCategory = e.record;
  //   const updatedCategories = [...categories];
  //   const categoryIndex = updatedCategories.findIndex(category => category.id === updatedCategory.id);
  //   updatedCategories[categoryIndex] = updatedCategory;
  //   setCategories(updatedCategories);
  //   saveToLocalStorage('categories', updatedCategories);
  // }, [categories]);

  // useEffect(() => {
  //   pb.collection('wallets').subscribe('*', handleWalletUpdate);

  //   return () => {
  //     pb.collection('wallets').unsubscribe();
  //   }
  // }, [handleWalletUpdate]);

  const memoizedValues = useMemo(
    () => ({
      // states
      isLoggedIn,
      setLoggedIn,
      user,
      setUser,
      categories,
      setCategories,
      wallets,
      entries,
      stash,
      setStash,
      setEntries,
      loading,
      setLoading,
      settings,
      setSettings,
      enableSignUp,
      setEnableSignUp,
      // helper functions
      getUser,
      getWallets,
      getStash,
      logoutUser,
      getCategories,
      getEntries,
      loadUserData,
    }),
    [
      isLoggedIn,
      setLoggedIn,
      user,
      setUser,
      categories,
      setCategories,
      wallets,
      entries,
      stash,
      setStash,
      setEntries,
      loading,
      setLoading,
      settings,
      setSettings,
      enableSignUp,
      setEnableSignUp,
      getUser,
      getWallets,
      getStash,
      logoutUser,
      getCategories,
      getEntries,
      loadUserData,
    ]
  );

  return (
    <GlobalContext.Provider value={memoizedValues}>
      {children}
    </GlobalContext.Provider>
  );
}

const useGlobalContext = () => useContext(GlobalContext);

export default useGlobalContext;
