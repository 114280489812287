// @TODO

import classNames from "classnames";
import {
  BarChart2,
  ChevronDown,
  DollarSign,
  Eye,
  EyeOff,
  Grid,
  List,
  MinusSquare,
  MoreHorizontal,
  MoreVertical,
  Package,
  PlusSquare,
  RefreshCcw,
  Settings,
} from "feather-icons-react/build/IconComponents";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LocaleAmount from "../components/locale-amount";
import useGlobalContext from "../contexts/globalContext";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Button } from "components/ui/button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordion";
import { Checkbox } from "components/ui/checkbox";
import { saveToLocalStorage } from "utils/helpers";
import { Banknote, Coins, Landmark, RefreshCw, Wallet2 } from "lucide-react";
import { Tooltip, TooltipWrapper } from "components/ui/tooltip";
import clsx from "clsx";
import { MobileCardCarousel } from "components/mobile-card-carousel";

export default function Wallets({ isProjection = false, projections = [] }) {
  const navigate = useNavigate();
  const { wallets, getWallets, setSettings, settings } = useGlobalContext();

  const [filteredWallets, setfilteredWallets] = useState([]);
  const [showValue, setshowValue] = useState(settings.visibility || false);
  const [loading, setloading] = useState(false);

  const ICONS = {
    wallet: Wallet2,
    cash: Banknote,
    savings: Landmark,
    coins: Coins,
  };

  const toggleVisibility = () => {
    setshowValue(!showValue);
    const prevSettings = { ...settings };
    prevSettings.visibility = !showValue;
    saveToLocalStorage("settings", prevSettings);
    setSettings(prevSettings);
  };

  useEffect(() => {
    const prevState = [...wallets];
    const walletsList = prevState
      .map((x) => ({
        ...x,
        is_selected: true,
        total: parseFloat(x.income) - parseFloat(x.expense),
        isSame: true,
      }))
      .filter((x) => !x.archived)
      .sort((a, b) => a.name.localeCompare(b.name));

    // if (isProjection && projections.length > 0) {
    //   projections.forEach(projection => {
    //     const index = walletsList.findIndex(x => x.id === projection.walletId);

    //     if (index >= 0) {
    //       walletsList[index].currentTotal = walletsList[index].total;
    //       walletsList[index].income = projection.income + walletsList[index].income;
    //       walletsList[index].expense = projection.expense + walletsList[index].expense;
    //       walletsList[index].total = walletsList[index].income - walletsList[index].expense;
    //       walletsList[index].isUp = walletsList[index].total > walletsList[index].currentTotal;
    //       walletsList[index].isDown = walletsList[index].total < walletsList[index].currentTotal;
    //       walletsList[index].isSame = walletsList[index].total === walletsList[index].currentTotal;
    //     }
    //   })
    // }

    setfilteredWallets(walletsList);
  }, [wallets]);

  // useEffect(() => {

  const checkWallet = (id) => {
    const prevState = [...filteredWallets];

    const index = prevState.findIndex((x) => x.id === id);

    prevState[index].is_selected = !filteredWallets[index].is_selected;

    setfilteredWallets(prevState);
  };

  // console.log('total :>> ', totalProjectedIncome);

  // const finalWallets = [...filteredWallets];

  // if (isProjection && projections.length > 0) {
  //   projections.forEach(projection => {
  //     const index = finalWallets.findIndex(x => x.id === projection.walletId);

  //     if (index >= 0) {
  //       finalWallets[index].currentTotal = finalWallets[index].total;
  //       finalWallets[index].income = projection.income + finalWallets[index].income;
  //       finalWallets[index].expense = projection.expense + finalWallets[index].expense;
  //       finalWallets[index].total = finalWallets[index].income - finalWallets[index].expense;
  //       finalWallets[index].isUp = finalWallets[index].total > finalWallets[index].currentTotal;
  //       finalWallets[index].isDown = finalWallets[index].total < finalWallets[index].currentTotal;
  //       finalWallets[index].isSame = finalWallets[index].total === finalWallets[index].currentTotal;
  //     }
  //   })
  // }

  const getLatestWallets = useCallback(async () => {
    setloading(true);
    await getWallets(true, () => setloading(false));
  }, [getWallets]);

  // makeFinalValues();

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  const numberFormatter = Intl.NumberFormat("en-us", {
    minimumFractionDigits: 2,
  });

  // add displayed property
  const totalIncome = filteredWallets
    .filter((x) => x.is_selected)
    .reduce((acc, obj) => acc + obj.income, 0);
  const totalExpense = filteredWallets
    .filter((x) => x.is_selected)
    .reduce((acc, obj) => acc + obj.expense, 0);

  const totalProjectedIncome = projections.reduce(
    (acc, obj) => acc + obj.income,
    0
  );
  const totalProjectedExpense = projections.reduce(
    (acc, obj) => acc + obj.expense,
    0
  );

  const grandTotal = isProjection
    ? totalIncome + totalProjectedIncome - totalExpense - totalProjectedExpense
    : totalIncome - totalExpense;
  return (
    <Card>
      {isProjection && (
        <CardHeader>
          <CardTitle>
            {`${isProjection ? "Projected" : "Current"} total balance`}
          </CardTitle>
          <h1 className="flex items-center  gap-4 text-4xl font-poppins leading-none">
            <div>
              ₱
              <span className={classNames(!showValue && "blur-lg")}>
                <LocaleAmount value={grandTotal} />
              </span>
            </div>
            <div className="flex gap-2">
              <TooltipWrapper tooltipText="Manage wallets">
                <Button
                  variant="secondary"
                  size="icon"
                  onClick={toggleVisibility}
                >
                  {showValue ? <Eye size={16} /> : <EyeOff size={16} />}
                </Button>
              </TooltipWrapper>
              <TooltipWrapper tooltipText="Synchronize">
                <Button variant="ghost" size="icon" onClick={getLatestWallets}>
                  <RefreshCw
                    size={16}
                    className={clsx(loading && "animate-spin")}
                  />
                </Button>
              </TooltipWrapper>
            </div>
          </h1>
        </CardHeader>
      )}
      <CardHeader>
        <CardTitle>Wallets balance breakdown</CardTitle>
      </CardHeader>
      <CardContent className="pb-0">
        {isProjection && (
          <div className="flex gap-2 mb-4">
            <Card className="uppercase text-xs font-semibold p-3 flex justify-between items-center gap-2">
              income
              <div className="bg-muted p-1 px-2 rounded-full text-green-500">
                {totalProjectedIncome > 0 && "+"}
                {kFormatter(totalProjectedIncome)}
              </div>
            </Card>
            <Card className="uppercase text-xs font-semibold p-3 flex justify-between items-center gap-2">
              expense
              <div className="bg-muted p-1 px-2 rounded-full text-red-500">
                {totalProjectedExpense > 0 && "-"}
                {kFormatter(totalProjectedExpense)}
              </div>
            </Card>
          </div>
        )}
        {wallets.length <= 0 && <p className="mb-4">No Wallet yet. </p>}
        <ul className="border rounded-lg mb-4">
          {filteredWallets.length > 0 &&
            filteredWallets.map((wallet) => {
              let isUp = false,
                isDown = false,
                isSame = false,
                total = wallet.total,
                toggleTip;

              const index = projections.findIndex(
                (x) => x.walletId === wallet.id
              );
              if (isProjection && index !== -1) {
                total =
                  total +
                  (projections[index].income - projections[index].expense);
                isUp = total > wallet.total;
                isDown = total < wallet.total;
                isSame = total === wallet.total;
                toggleTip = `Current: ₱${numberFormatter.format(
                  wallet.total
                )} \n Income: ₱${numberFormatter.format(
                  projections[index].income
                )} \n Expense: ₱${numberFormatter.format(
                  projections[index].expense
                )}`;
              }

              const IconComponent = ICONS[wallet.icon || "wallet"];
              return (
                <li
                  className="flex justify-between items-center p-4 border-b gap-4 text-sm"
                  key={wallet.id}
                >
                  <div className="flex gap-4 items-center">
                    <IconComponent size={20} />
                    <Button
                      variant="link-hover"
                      className="shrink-0 w-min"
                      onClick={() => {
                        navigate(`/app/wallets/${wallet.id}`, {
                          state: wallet,
                        });
                      }}
                    >
                      {wallet.name}
                    </Button>
                  </div>
                  <div
                    className="tooltip tooltip-left whitespace-pre-wrap"
                    data-tip={toggleTip}
                  >
                    <span className={classNames(!showValue && "blur-sm")}>
                      {isUp && (
                        <span className="text-green-500 font-bold">↑ </span>
                      )}
                      {isDown && (
                        <span className="text-red-500 font-bold">↓ </span>
                      )}
                      {isSame && (
                        <span className="text-gray-500 font-bold">{"="} </span>
                      )}
                      <LocaleAmount value={total} />
                    </span>
                  </div>
                </li>
              );
            })}
        </ul>

      </CardContent>
      {!isProjection && (
        <CardFooter className="gap-2">
          <Button
            className="flex-1"
            variant="secondary"
            onClick={() => navigate("/app/stash")}
          >
            See Projections
          </Button>
          <Button
            className="flex-1"
            variant="secondary"
            onClick={() => navigate("/app/wallets")}
          >
            Manage wallets
          </Button>
        </CardFooter>
      )}
    </Card>
  );
}
