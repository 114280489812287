import clsx from 'clsx';
import { AlertCircle } from 'feather-icons-react/build/IconComponents';
import { AlertTriangle, Loader2, Sparkle, Sparkles } from 'lucide-react';
import React, { useState, useEffect, useRef } from 'react'

export default function AmountInput({ inline, value, onChangeValue, className, placeholder, disabled, autoFocus = true }) {

  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState(value || '');
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);
  const [content, setContent] = useState(value || '0.00');
  const [width, setWidth] = useState(0);
  const span = useRef();

  function computeExpression(expression) {
    try {

      // remove all commas
      expression = expression.replace(/,/g, '');

      let result = eval(expression);
      return result;
    } catch (error) {
      // console.error('Invalid math expression');
      return null;
    }
  }

  useEffect(() => {
    setWidth(span.current.offsetWidth);
  }, [content]);


  useEffect(() => {
    setLoading(true);
    if (timer) clearTimeout(timer);
    setTimer(setTimeout(() => {
      let result = computeExpression(inputValue);
      if (result !== null) {
        setError(false);
        // onChange(result);
        setContent(result ? result : '0.00');
        setInputValue(result);
        onChangeValue(result);
        setLoading(false);
      } else {
        setError(true);
      }
      setLoading(false);
    }, 1000));
    return () => clearTimeout(timer);
  }, [inputValue]);

  const classes = clsx(
    'text-6xl w-[unset] inline-block outline-none bg-background',
    'text-center',
    error && 'text-error',
    className
  );

  const handlechange = (e) => {
    setInputValue(e.target.value);
    setContent(e.target.value === "" ? '0.00' : e.target.value);
  }

  return (
    <div className='relative'>
      <span id="hide" ref={span} className='absolute opacity-0 text-6xl pointer-events-none'>
        <span className="text-4xl text-primary opacity-50">₱</span>
        {content}
      </span>
      <div className='text-center'>
        {/* /peso sign */}
        <span className="text-4xl text-primary opacity-50">₱</span>
        <input
          style={{ width }}
          type='text'
          name='amount'
          placeholder={placeholder || "0.00"}
          className={classes}
          value={inputValue}
          onChange={handlechange}
          // autoFocus={autoFocus}
          disabled={disabled}
          autoComplete='off'
        />
      </div>
      {/* error text */}
      {/* {error && !loading && !inline && (

        <p className="absolute -bottom-3 w-full text-xs flex items-center justify-center gap-1 opacity-60">
          <Sparkles size={12} />You can enter math expressions like 10+20+30.
        </p>
      )}
      {loading && inputValue && !inline && (
        <p className="absolute -bottom-3 w-full text-xs flex items-center justify-center gap-1 opacity-60">
          <Loader2 size={12} className='animate-spin inline-block' />Calculating...
        </p>
      )} */}
    </div>

  )
}