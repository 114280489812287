// @TODO

import { ArrowRight } from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LocaleAmount from "../components/locale-amount";
import useGlobalContext from "../contexts/globalContext";
import { Card, CardContent, CardTitle } from "components/ui/card";
import { Button } from "components/ui/button";
import { Banknote, Coins, Landmark, Wallet2 } from "lucide-react";
import { MobileCardCarousel } from "components/mobile-card-carousel";

export default function WalletsBalance() {
  const navigate = useNavigate();
  const { wallets } = useGlobalContext();

  const [filteredWallets, setfilteredWallets] = useState([]);

  const ICONS = {
    wallet: Wallet2,
    cash: Banknote,
    savings: Landmark,
    coins: Coins,
  };

  useEffect(() => {
    const prevState = [...wallets];
    const walletsList = prevState
      .map((x) => ({
        ...x,
        total: parseFloat(x.income) - parseFloat(x.expense),
      }))
      .filter((x) => !x.archived)
      .sort((a, b) => a.name.localeCompare(b.name));

    setfilteredWallets(walletsList);
  }, [wallets]);

  return (
    <div className="select-none mb-2">
      <div className="flex justify-between items-center px-3 md:px-5">
        <CardTitle>Wallets balance</CardTitle>
        <Button
          size="sm"
          variant="ghost"
          onClick={() => navigate("/app/wallets")}
        >
          Manage
          <ArrowRight size={18} />
        </Button>
      </div>

      <MobileCardCarousel
        cards={filteredWallets.map((wallet) => {
          let total = wallet.total;
          const IconComponent = ICONS[wallet.icon || "wallet"];
          return (
            <Card
              className="hover:bg-secondary cursor-pointer"
              onClick={() => {
                navigate(`/app/wallets/${wallet.id}`, {
                  state: wallet,
                });
              }}
            >
              <CardContent className="flex items-center justify-center !p-3 !px-4 !pr-6">
                <div class="flex  w-full gap-4">
                  <div className="flex items-center gap-2">
                    <div className="rounded-full bg-secondary w-8 h-8 flex items-center justify-center hc:border hc:border-primary">
                      <IconComponent size={18} />
                    </div>
                  </div>
                  <div>
                    <p className="text-sm">{wallet.name}</p>
                    <LocaleAmount
                      className="font-bold text-center"
                      value={total}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          );
        })}
      />
    </div>
  );
}
