/**
 * A component that wraps its children in a page layout.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The children elements to be wrapped.
 * @returns {JSX.Element} The rendered page component.
 */

import clsx from "clsx";

const Page = ({ children, className }) => {
  return (
    <div
      className={clsx(
        "relative min-h-screen overscroll-none w-full flex flex-col gap-2 p-4 md:p-10 bg-paper dt:bg-background hc:bg-primary-foreground",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Page;
