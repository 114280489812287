import classNames from "classnames";
import { startOfDay, startOfMonth, subMinutes } from "date-fns";
import { endOfMonth, format } from "date-fns/esm";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BottomDateNav from "../components/bottom-date-nav";
import LocaleAmount from "../components/locale-amount";
import Transaction from "../components/transaction";
import useGlobalContext from "../contexts/globalContext";
import { handleError } from "../utils/helpers";
import { pb } from "../utils/pbConfig";
import { motion } from "framer-motion";
import Page from "components/page";
import PageNav from "components/page-nav";
import PageContent from "components/page-content";
import { Button } from "components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import Icon from "components/entry-icon";
import { Shapes } from "lucide-react";
import { TypographyH2 } from "components/typography";
import { Skeleton } from "components/ui/skeleton";
import { useToast } from "components/ui/use-toast";
import { MobileCardCarousel } from "components/mobile-card-carousel";
import { clsx } from "clsx";

function PerCategoryEntry() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading, loading } = useGlobalContext();
  const [data] = useState(location.state?.data);
  const { id, name, type, date: stateDate = "", backTo = false } = data;
  const [entries, setEntries] = useState([]);
  const [date, setDate] = useState(
    stateDate !== ""
      ? stateDate
      : format(startOfMonth(new Date()), "yyyy-MM-dd")
  );

  const { toast } = useToast();

  const getCategoryEntries = useCallback(async () => {
    setLoading(true);
    const initialDate = startOfDay(new Date(date));
    const startdate = format(
      subMinutes(initialDate, -initialDate.getTimezoneOffset()),
      "yyyy-MM-dd HH:mm:ss"
    );
    const endDate = format(endOfMonth(new Date(date)), "yyyy-MM-dd HH:mm:ss");

    // console.log('startDate :>> ', getDBDate(startdate));
    try {
      // you can also fetch all records at once via getFullList
      const records = await pb
        .collection("entries")
        .getFullList(200 /* batch size */, {
          sort: "-date",
          filter: `date <= "${endDate}" && date >= "${startdate}" && category.id = "${id}"`,
          expand: "wallet,category",
        });
      setEntries(records);
    } catch (error) {
      console.log("error :>> ", error);
      handleError(error, toast);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [date, id, setLoading]);

  const groups = entries.reduce((groups, entry) => {
    const date = format(new Date(entry.date), "MMM dd");
    // console.log('date', date)
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(entry);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupEntries = Object.keys(groups).map((date) => {
    return {
      date,
      entries: groups[date],
    };
  });

  const total = entries.reduce((acc, cur) => {
    return acc + cur.amount;
  }, 0);

  useEffect(() => {
    if (data?.id !== "") {
      getCategoryEntries();
    }
  }, [data, date, getCategoryEntries]);

  console.log("data :>> ", data);

  return (
    <motion.main
      initial={{ opacity: 0, x: "50%" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "50%" }}
      transition={{ duration: 0.1 }}
    >
      <Page>
        <PageNav backTitle="Back">
          <Button variant="outline" onClick={getCategoryEntries}>
            Refresh
          </Button>
        </PageNav>
        <PageContent>
          <div className="flex items-center gap-2 justify-between">
            <div className="flex gap-2 items-center">
              <Icon
                className="flex-shrink-0"
                emoji={data.emoji}
                // isTransfer={data?.special_attributes?.type === "transfer"}
                type={data.type}
                // onClick={() =>
                //   !disableCategoryClick &&
                //   data.category !== "" &&
                //   viewCategoryEntries(data)
                // }
              />

              {/* <TypographyH2 className='text-2xl !font-normal'>Entries</TypographyH2>
               */}

              <TypographyH2 className="text-2xl font-semibold">
                {name}
              </TypographyH2>
            </div>

            <div
              className={clsx(
                "p-2 rounded-full  uppercase text-white text-xs font-semibold inline-block",
                {
                  "bg-red-500": type === "expense",
                  "bg-green-500": type === "income",
                }
              )}
            >
              {type}
            </div>
          </div>
          <BottomDateNav
            date={date}
            setDate={setDate}
            loading={loading}
          ></BottomDateNav>
          <CardHeader>
            <CardTitle>Insights</CardTitle>
          </CardHeader>

          <MobileCardCarousel
            customCardClass="basis-1/3 md:basis-auto"
            cards={[
              <Card className="h-24 min-w-[150px]">
                <CardHeader className="pb-0">
                  <CardTitle>Total Amount</CardTitle>
                </CardHeader>
                <CardContent className="!pt-1">
                  {!loading ? (
                    <h3
                      className={classNames("text-lg font-bold", {
                        "text-red-500": type === "expense",
                        "text-green-500": type === "income",
                      })}
                    >
                      {type == "income" && "+"}
                      {type == "expense" && "-"}
                      ₱<LocaleAmount value={total} />
                    </h3>
                  ) : (
                    <Skeleton className="h-8 w-24 rounded-full" />
                  )}
                </CardContent>
              </Card>,
              <Card className="h-24 min-w-[150px]">
                <CardHeader className="pb-0">
                  <CardTitle>That's</CardTitle>
                </CardHeader>
                <CardContent className="!pt-1">
                  {!loading ? (
                    <>
                      <div className="font-bold text-lg">
                        ₱<LocaleAmount value={(total / 30).toFixed(2)} />
                      </div>
                      <p className="text-muted-foreground text-xs">
                        daily average
                      </p>
                    </>
                  ) : (
                    <Skeleton className="h-8 w-24 rounded-full" />
                  )}
                </CardContent>
              </Card>,
              <Card className="h-24 min-w-[150px]">
                <CardHeader className="pb-0">
                  <CardTitle>Entry count</CardTitle>
                </CardHeader>
                <CardContent className="!pt-1">
                  {!loading ? (
                    <h3 className="text-lg font-bold">{entries.length}</h3>
                  ) : (
                    <Skeleton className="h-8 w-24 rounded-full" />
                  )}
                </CardContent>
              </Card>,
            ]}
          />

          <CardHeader>
            <CardTitle>Entries in this category</CardTitle>
          </CardHeader>
          <Card>
            {!loading && entries.length <= 0 && (
              <div className="flex flex-col items-center justify-center h-64">
                <p className="text-muted-foreground">No entry.</p>
              </div>
            )}

            {!!loading && (
              <>
                {Array.from({ length: 5 }).map((_, index) => (
                  <div
                    className="flex gap-4 items-center px-6 mb-4 last-of-type:mb-0"
                    key={index}
                  >
                    <Skeleton className="h-10 w-10 rounded-full" />
                    <Skeleton className="h-8 flex-1 my-4" />
                  </div>
                ))}
              </>
            )}
            {!loading &&
              entries.length > 0 &&
              entries.map((entry) => (
                <Transaction
                  clickable
                  // hideDate
                  disableCategoryClick
                  data={entry}
                  key={entry.id}
                />
              ))}
          </Card>
        </PageContent>
      </Page>
    </motion.main>
  );
}

export default PerCategoryEntry;
