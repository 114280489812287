/**
 * Renders a navigation component for a page. Accepts children to be rendered on the right side of the navigation.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content to be rendered inside the component.
 * @returns {JSX.Element} The rendered PageNav component.
 */

import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import { Button } from "components/ui/button";
import { TooltipWrapper } from "components/ui/tooltip";
import clsx from "clsx";

const PageNav = ({ children, backTitle = "Cancel", className }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1, { replace: true });
  };
  return (
    <div
      className={clsx(
        "sticky top-0 flex justify-between w-full max-w-7xl mx-auto",
        className
      )}
    >
      <TooltipWrapper tooltipText="Esc">
        <Button variant="outline" size="back" onClick={goBack}>
          <ChevronLeft size={16} />
          <span className="hidden md:inline">{backTitle}</span>
        </Button>
      </TooltipWrapper>
      <div>{children}</div>
    </div>
  );
};

export default PageNav;
