
import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router-dom';

const useCtrlKeyDown = () => {
  const [isCtrlKeyDown, setIsCtrlKeyDown] = useState(false);
  const location = useLocation();
  const options = {
    preventDefault: true,
    enableOnFormTags: false,
    keyup: true,
    keydown: true
  }

  useEffect(() => {
    console.log('this fired');
    setTimeout(() => {
      setIsCtrlKeyDown(false);
    }, 1000);
    // setIsCtrlKeyDown(false);
    // 
  }, [location]);


  useHotkeys('meta, ctrl', () => setIsCtrlKeyDown(!isCtrlKeyDown), options)

  return isCtrlKeyDown;
};

export default useCtrlKeyDown;
