import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useGlobalContext from "../contexts/globalContext";
import { MessageSquarePlus, PiggyBankIcon, User } from "lucide-react";
import { Button } from "./ui/button";
import { themeChange } from "theme-change";
import { CommandBar } from "./command-bar";
import { VersionChecker } from "./version-checker";
import clsx from "clsx";

const MENU_ITEMS = [
  {
    label: "Dashboard",
    path: "/app",
  },
  {
    label: "Entries",
    path: "/app/entries",
  },
  {
    label: "Reports",
    path: "/app/reports/monthly-spending",
  },
  {
    label: "Settings",
    path: "/app/settings",
  },
];

export default function AppHeader({ hideMenu = false }) {
  const navigate = useNavigate();
  const menuRef = React.createRef();

  useEffect(() => {
    themeChange(false);
  }, []);

  // center of active menu item
  useEffect(() => {
    if (menuRef.current) {
      const activeItem = menuRef.current.querySelector("[data-active='true']");
      if (activeItem) {
        const activeItemWidth = activeItem.offsetWidth;
        const activeItemLeft = activeItem.offsetLeft;
        const activeItemCenter = activeItemLeft + activeItemWidth / 2;
        const menuWidth = menuRef.current.offsetWidth;
        const scrollLeft = activeItemCenter - menuWidth / 2;
        menuRef.current.scrollLeft = scrollLeft;
      }
    }
  }, [menuRef]);

  return (
    <>
      <div className="max-w-7xl mx-auto px-4">
        <VersionChecker />
      </div>
      <div
        className={clsx(
          "sticky z-[100] flex justify-between items-center gap-5 py-1 text-primary px-4 backdrop-blur-lg hc:border-b hc:border-primary hc:bg-accent"
        )}
        style={{
          left: "env(titlebar-area-x, 0)",
          top: "env(titlebar-area-y, 0)",
          width: "env(titlebar-area-width, 100%)",
          height: "env(titlebar-area-height, auto)",
          webkitAppRegion: "drag",
        }}
      >
        <div className="flex items-center justify-between mx-auto w-full gap-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => navigate("/")}
            className="shrink-0"
          >
            <PiggyBankIcon size={30} />
            {/* <div className="flex h-12 items-center font-bold  leading-none whitespace-nowrap text-foreground">
              <div className="pr-2 group-hover:hidden font-normal font-mono flex items-center">
                budget <span className="opacity-60 font-black">baboy</span>
              </div>
            </div> */}
          </Button>
          <div
            className="gap-2 flex overflow-scroll h-full no-scrollbar"
            ref={menuRef}
          >
            {!hideMenu &&
              MENU_ITEMS.map((item) => (
                <Button
                  key={item.path}
                  variant={
                    window.location.pathname !== item.path ? "ghost" : "outline"
                  }
                  onClick={() => navigate(item.path)}
                  data-active={window.location.pathname === item.path}
                  className="text-sm rounded-full uppercase data-[active='true']:font-semibold"
                >
                  {item.label}
                </Button>
              ))}
          </div>
          <div className="flex items-center gap-2">
            <div
              className={clsx("flex items-center")}
              style={{
                webkitAppRegion: "no-drag",
              }}
            >
              {/* <Button
                variant="ghost"
                size="icon"
                className="rounded-full"
                data-canny-link
                target="_blank"
                href="https://the-baboy-collective.canny.io/budget-baboy"
              >
                <MessageSquarePlus size={20} />
              </Button> */}
              <Button
                variant="outline"
                size="icon"
                className="rounded-full"
                onClick={() => navigate("/app/settings")}
              >
                <User size={20} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
