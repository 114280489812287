import {
  ChevronRight,
  RefreshCw,
} from "feather-icons-react/build/IconComponents";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Transaction from "../components/transaction";
import useGlobalContext from "../contexts/globalContext";
import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import clsx from "clsx";
import { Shapes } from "lucide-react";

export default function Transactions() {
  const { entries, getEntries } = useGlobalContext();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const limitEntries = entries.slice(0, 5);

  const getNewEntries = () => {
    setLoading(true);
    getEntries(true, () => setLoading(false));
  };

  return (
    <Card>
      <CardHeader className=" mb-0">
        <CardTitle className="flex items-center justify-between">
          Recent Transactions
          <div className="flex items-center gap-2">
            <Button
              variant="secondary"
              size="icon"
              onClick={getNewEntries}
              disabled={loading}
            >
              <RefreshCw
                size={16}
                className={clsx(loading && "animate-spin")}
              />
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => navigate("entries")}
            >
              View all <ChevronRight size={18} />
            </Button>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="px-0 md:px-0 pb-0 md:pb-0">
        {limitEntries.length > 0 &&
          limitEntries.map((data) => {
            return (
              <Transaction
                key={data.id}
                data={data}
                className="border-t border-border"
              />
            );
          })}

        {limitEntries.length <= 0 && (
          <div className="flex flex-col items-center justify-center h-full py-12">
            <Shapes size={48} className="text-primary" />
            <p className="text-primary text-xs">No recent transactions</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
