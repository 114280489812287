import React, { useEffect } from "react";
import { themeChange } from "theme-change";
import NavHeader from "../components/navHeader";
import { useNavigate } from "react-router-dom";
import useGlobalContext from "../contexts/globalContext";
import Page from "components/page";
import PageContent from "components/page-content";
import PageNav from "components/page-nav";
import { TypographyH2 } from "components/typography";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { motion } from "framer-motion";
import { Button } from "components/ui/button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Label } from "components/ui/label";
import { Arrow } from "@radix-ui/react-dropdown-menu";
import { ArrowRight } from "lucide-react";

export default function Settings() {
  const themeValues = ["light", "dark", "beta", "high-contrast"];
  const navigate = useNavigate();

  useEffect(() => {
    themeChange(false);
    window.Canny("initChangelog", {
      appID: "657806c58e329536f1483763",
      position: "bottom",
      align: "left",
      theme: "light", // options: light [default], dark, auto
    });
  }, []);

  const { user, logoutUser } = useGlobalContext();

  return (
    <motion.main
      initial={{ opacity: 0, x: "50%" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "50%" }}
      transition={{ duration: 0.1 }}
    >
      <Page>
        <PageNav backTitle="Back"></PageNav>
        <PageContent>
          <TypographyH2 className="mt-4">Settings</TypographyH2>
          <Card>
            <CardHeader>
              <CardTitle>Manage</CardTitle>
            </CardHeader>
            <CardContent className="gap-8">
              <Button
                variant="outline"
                size="lg"
                className="w-full justify-between mb-4"
                onClick={() => navigate("/app/wallets")}
              >
                See Wallets
                <ArrowRight size={20} className="ml-2" />
              </Button>
              <Button
                variant="outline"
                size="lg"
                className="w-full justify-between"
                onClick={() => navigate("/app/categories")}
              >
                See Categories
                <ArrowRight size={20} className="ml-2" />
              </Button>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>About app</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex items-center justify-between">
                <strong>
                  Budget Baboy
                  {/* pill */}
                  <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    Closed Beta
                  </span>
                </strong>
                <Button
                  variant="secondary"
                  data-canny-link
                  target="_blank"
                  href="https://the-baboy-collective.canny.io/budget-baboy"
                >
                  Give feedback
                </Button>
              </div>
              <hr className="my-4" />
              <div className="flex items-center justify-between">
                Version 0.9.0 - Adobo
                <Button variant="outline" className="ml-2" data-canny-changelog>
                  See what's new
                </Button>
              </div>
              <hr className="my-4" />
              <div>
                <span>Developed and maintained by</span>{" "}
                <Button
                  variant="link"
                  href="https://andreitrinidad.dev"
                  className="text-right"
                >
                  Andrei Trinidad
                </Button>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>App settings</CardTitle>
            </CardHeader>
            <CardContent>
              <Label>Theme</Label>

              <div className="flex gap-2">
                {themeValues.map((theme, index) => (
                  <Button
                    variant="outline"
                    key={index}
                    size="sm"
                    data-set-theme={theme}
                    data-act-class="border-4"
                    className="capitalize"
                    onClick={() => {
                      themeChange(theme);
                      // change meta theme
                      document
                        .querySelector("meta[name='theme-color']")
                        .setAttribute(
                          "content",
                          theme !== "dark" ? "#fff" : "#000"
                        );
                    }}
                  >
                    {theme === "light" ? "Default" : theme}
                  </Button>
                ))}
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Account settings</CardTitle>
            </CardHeader>
            <CardContent className="form-control w-full">
              <div className="text-sm pb-4">
                <p>Name: {user.name}</p>
                <p>Username: {user.username}</p>
                <pre>
                  <code>{JSON.stringify(user, null, 2)}</code>
                </pre>
              </div>
              <Button variant="destructive" onClick={logoutUser}>
                Logout
              </Button>
            </CardContent>
          </Card>
        </PageContent>
      </Page>
    </motion.main>
  );
}
