import classNames from "classnames";
import {
  format,
  startOfDay,
  startOfMonth,
  subMinutes,
  subMonths,
} from "date-fns";
import { endOfMonth } from "date-fns/esm";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PieChart, pieChartDefaultProps } from "react-minimal-pie-chart";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import LocaleAmount from "../components/locale-amount";
import { pb } from "../utils/pbConfig";
import Icon from "../components/entry-icon";
import MonthlyInsight from "../cards/your-insights";
import TwoColumnLayoutContainer from "../layout/twoColumnLayoutContainer";
import MainContainer from "components/main-container";
import { Alert, AlertDescription, AlertTitle } from "components/ui/alert";
import {
  ChevronLeft,
  ChevronRight,
  ExternalLink,
  Megaphone,
  PieChartIcon,
} from "lucide-react";
import { Button } from "components/ui/button";
import { Tabs, TabsList, TabsTrigger } from "components/ui/tabs";
import { Skeleton } from "components/ui/skeleton";
import IncomeExpenseTrend from "cards/income-expense-trend";
import AppHeader from "components/app-header";
import colors from "tailwindcss/colors";
import { ArrowRight } from "feather-icons-react/build/IconComponents";

const INCOME_COLORS = [
  colors.blue[500],
  colors.sky[500],
  colors.cyan[500],
  colors.teal[500],
  colors.emerald[500],
  colors.green[500],
  colors.lime[500],
  colors.yellow[500],
  colors.amber[500],
  colors.orange[500],
  colors.red[500],
  colors.rose[500],
  colors.pink[500],
  colors.fuchsia[500],
  colors.purple[500],
  colors.violet[500],
  colors.indigo[500],
];

const EXPENSE_COLORS = [
  colors.red[500],
  colors.orange[500],
  colors.amber[500],
  colors.yellow[500],
  colors.lime[500],
  colors.green[500],
  colors.emerald[500],
  colors.teal[500],
  colors.cyan[500],
  colors.sky[500],
  colors.blue[500],
  colors.indigo[500],
  colors.violet[500],
  colors.purple[500],
  colors.fuchsia[500],
  colors.pink[500],
  colors.rose[500],
];

const CategoryItem = ({
  entry,
  handleSelect,
  selected,
  index,
  viewPerCategory,
  total,
}) => {
  return (
    <li
      className={classNames(
        "p-3 flex gap-4 items-center justify-between hover:bg-base-200 rounded-xl transition ease-in-out duration-200 hover:bg-muted",
        selected === index && "outline-primary/50 outline outline-1"
      )}
      onClick={() => handleSelect(index, entry.id)}
    >
      <div className="relative">
        <Icon
          emoji={entry.emoji}
          style={{
            borderColor: entry.color,
            borderWidth: 2,
            borderStyle: "solid",
          }}
        />
        <span
          className="absolute -bottom-0.5 -right-0.5 w-4 h-4 border rounded-full"
          style={{ background: entry.color }}
        ></span>
      </div>
      <div className="text-left min-w-0 flex-1 flex gap-2 items-center">
        <p
          className="text-ellipsis whitespace-nowrap overflow-hidden hover:underline cursor-pointer"
          onClick={() => viewPerCategory(entry)}
        >
          {entry.title}
        </p>

        <span className="bg-primary/10 text-xs p-2 py-1 rounded-full mr-2">
          {((entry.value / total) * 100).toFixed(2)}%
        </span>
      </div>
      <span>
        <LocaleAmount value={entry.value} />
      </span>
    </li>
  );
};

export default function MonthlySpending() {
  const navigate = useNavigate();
  const location = useLocation();
  const [data] = useState(location.state?.data);
  const stateDate = data?.date || "";
  const stateType = data?.type;
  const [entries, setEntries] = useState([]);
  const previousMonth = subMonths(new Date(), 1);
  const [date, setDate] = useState(
    stateDate !== ""
      ? stateDate
      : format(startOfMonth(previousMonth), "yyyy-MM-dd")
  );
  const [type, setType] = useState(stateType || "expense");
  const [selected, setSelected] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [primaryColor, setPrimaryColor] = useState("");
  const [selectedId, setSelectedId] = useState(null);

  const toggleType = () => {
    if (type === "income") {
      setType("expense");
    } else {
      setType("income");
    }
  };

  // colors
  const randomColors = () => {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  };

  const generateColors = (index, colorGroup, itemsLength) => {
    // get one from last color, then one from first color and so on
    const colorIndex = index % colorGroup.length;
    return colorGroup[colorIndex];
  };

  const prevMonth = () => {
    const currentDate = new Date(date);
    const newDate = subMonths(currentDate, 1);
    setDate(format(startOfMonth(new Date(newDate)), "yyyy-MM-dd"));
  };

  const nextMonth = () => {
    const currentDate = new Date(date);
    const newDate = subMonths(currentDate, -1);
    setDate(format(startOfMonth(new Date(newDate)), "yyyy-MM-dd"));
  };

  const mountRef = useRef();

  const getEntries = useCallback(async () => {
    setLoading(true);

    const initialDate = startOfDay(new Date(date));
    const startdate = format(
      subMinutes(initialDate, -initialDate.getTimezoneOffset()),
      "yyyy-MM-dd HH:mm:ss"
    );
    const endDate = format(endOfMonth(new Date(date)), "yyyy-MM-dd HH:mm:ss");

    try {
      // you can also fetch all records at once via getFullList
      // console.log('getDBDate(startdate) :>> ', getDBDate(startdate));
      const records = await pb
        .collection("entries")
        .getFullList(200 /* batch size */, {
          sort: "-date",
          filter: `date <= "${endDate}" && date >= "${startdate}" && category != ""`,
          expand: "wallet,category",
        });
      setEntries(records);
      setLoading(false);
    } catch (error) {
      console.log("error :>> ", error);
      setLoading(false);
    }
  }, [date, setLoading]);

  const groups = useMemo(() => {
    const data = entries
      .filter((x) => x.expand.category.type === type)
      .reduce((groups, entry) => {
        const key = entry.category;
        if (!groups[key]) {
          groups[key] = [];
        }
        groups[key].push(entry);
        return groups;
      }, {});
    return data;
  }, [type, entries]);

  const groupEntriesLength = Object.keys(groups).length;

  // Edit: to add it in the array format instead
  const groupEntries = useMemo(
    () =>
      Object.keys(groups)
        .map((category, i) => {
          const catEntries = groups[category];
          const total = catEntries.reduce((acc, cur) => {
            return acc + cur.amount;
          }, 0);
          return {
            category,
            value: total,
            title: catEntries[0].expand.category.name,
            id: catEntries[0].expand.category.id,
            type: catEntries[0].expand.category.type,
            emoji: catEntries[0].expand.category.emoji,
            entries: catEntries,
          };
        })
        .sort((a, b) => b.value - a.value)
        .map((item, index) => {
          const colorGroup =
            item.type === "expense" ? EXPENSE_COLORS : INCOME_COLORS;
          return {
            ...item,
            color: generateColors(index, colorGroup, groupEntriesLength),
          };
        }),
    [groups, selected]
  );

  const total = groupEntries.reduce((acc, cur) => {
    // console.log('cur :>> ', cur.value);
    return acc + cur.value;
  }, 0);

  const viewPerCategory = (entry) => {
    const slug = entry.title.toLowerCase().split(" ").join("-");

    navigate(`/app/entries/category/${slug}`, {
      state: {
        data: {
          id: entry.id,
          name: entry.title,
          type: entry.type,
          date,
          backTo: "/reports/monthly-spending",
        },
      },
    });
  };

  const handleSelect = (i, id) => {
    setSelected(i === selected ? undefined : i);
    setSelectedId(id);
  };

  useEffect(() => {
    getEntries();
  }, [date, getEntries]);

  const randomKey = useMemo(() => Math.random(), [entries]);

  useEffect(() => {
    // get css variables
    const root = document.querySelector(":root");
    const styles = getComputedStyle(root);
    const primary = styles.getPropertyValue("--primary");
    setPrimaryColor(primary);
  }, []);

  const isCurrentMonth =
    format(new Date(date), "yyyy-MM") === format(new Date(), "yyyy-MM");

  const isLaterMonth = format(new Date(date), "MM") >= format(new Date(), "MM");

  return (
    <>
      <MainContainer className="bg-paper">
        <AppHeader />
        <TwoColumnLayoutContainer
          rightComponents={
            <>
              <MonthlyInsight
                key={randomKey}
                data={entries}
                date={date}
                noFetch
                className="lg:sticky lg:top-24"
              />
              <IncomeExpenseTrend />
              <Alert className="w-full">
                <Megaphone className="mr-2" size={16} />
                <AlertTitle>Help shape Budget Baboy</AlertTitle>
                <AlertDescription>
                  Have a suggestion? Found a bug? Or have a feature request?
                  <Button
                    variant="outline"
                    onClick={() => navigate("/feedback")}
                    className="mt-2 flex"
                  >
                    Contribute now!
                    <ExternalLink size={16} />
                  </Button>
                </AlertDescription>
              </Alert>
            </>
          }
          leftComponents={
            <Card className="w-full">
              <CardHeader className="flex gap-2  md:flex-row md:justify-between md:items-center">
                <CardTitle>Categories</CardTitle>
                <Tabs className="w-full md:w-max mb-4" defaultValue={type}>
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger
                      value="income"
                      onClick={() => setType("income")}
                    >
                      Income
                    </TabsTrigger>
                    <TabsTrigger
                      value="expense"
                      onClick={() => setType("expense")}
                    >
                      Expense
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
              </CardHeader>

              {loading ? (
                <div className="flex flex-col gap-2 items-center justify-center ">
                  <Skeleton className="h-[220px] w-[220px] mt-6 rounded-full" />
                  <div className="w-full px-6 mt-10 gap-4 flex flex-col mb-4">
                    <Skeleton className="h-8 w-full" />
                    <Skeleton className="h-8 w-full" />
                    <Skeleton className="h-8 w-full" />
                  </div>
                </div>
              ) : (
                <>
                  {groupEntries.length > 0 ? (
                    <div className=" mx-auto flex justify-center mb-9">
                      <PieChart
                        radius={pieChartDefaultProps.radius - 10}
                        // segmentsStyle={{
                        //   transition: "stroke .3s",
                        //   cursor: "pointer",
                        // }}
                        segmentsShift={(index) => (index === selected ? 2 : 0)}
                        segmentsStyle={(index) => ({
                          transition: "stroke .3s",
                          cursor: "pointer",
                          // fill: groupEntries[index].color,
                        })}
                        data={groupEntries}
                        style={{ height: "300px" }}
                        // onClick={(event, index) => handleSelect(index)}
                        onMouseOver={(event, index) =>
                          handleSelect(index, groupEntries[index].id)
                        }
                        onMouseOut={() => handleSelect(undefined)}
                        labelStyle={(index) => ({
                          fontSize: "0.3rem",
                          fontFamily: "sans-serif",
                        })}
                        label={({ dataEntry }) =>
                          // if percentage is less than 5, don't show
                          dataEntry.percentage > 5
                            ? `${dataEntry.percentage.toFixed(2)}%`
                            : ""
                        }
                        labelPosition={112}
                        startAngle={180}
                        animate
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center h-full py-12 pb-24">
                      <PieChartIcon size={48} className="text-primary" />
                      <p className="text-primary text-xs">
                        You have no {type} entries for this month
                      </p>
                    </div>
                  )}
                  <CardContent>
                    <ul ref={mountRef}>
                      {/* selected category */}
                      {selected !== undefined && (
                        <>
                          <p className="text-sm font-bold text-muted-foreground mb-4">
                            Current selection
                          </p>
                          <CategoryItem
                            entry={groupEntries[selected]}
                            handleSelect={handleSelect}
                            selected={selected}
                            index={selected}
                            viewPerCategory={viewPerCategory}
                            total={total}
                          />
                        </>
                      )}
                      {groupEntries.length > 0 && (
                        <p className="text-sm font-bold text-muted-foreground my-4">
                          All categories
                        </p>
                      )}
                      {groupEntries.length > 0 &&
                        groupEntries.map((entry, i) => {
                          return (
                            <CategoryItem
                              entry={entry}
                              handleSelect={handleSelect}
                              selected={selected}
                              key={i}
                              index={i}
                              viewPerCategory={viewPerCategory}
                              total={total}
                            />
                          );
                        })}
                    </ul>
                  </CardContent>
                </>
              )}
            </Card>
          }
        >
          <div class="relative flex items-center flex-col py-10 pb-20">
            <p className="font-bold">Monthly Report</p>
            <div className="flex items-center gap-4">
              <Button
                variant="outline"
                size="icon"
                onClick={prevMonth}
                disabled={loading}
              >
                <ChevronLeft />
              </Button>

              <div className="font-mono text-4xl text-center">
                <span className="font-bold">
                  {format(new Date(date), "MMMM")}
                </span>
                '{format(new Date(date), "yy")}
              </div>
              <Button
                variant="outline"
                size="icon"
                onClick={nextMonth}
                disabled={loading}
              >
                <ChevronRight />
              </Button>
            </div>
            {/* shortcuts */}
            {!isCurrentMonth && (
              <div className="absolute bottom-0 right-0 left-0 flex text-sm gap-2 text-muted-foreground items-center justify-between md:justify-end mb-4">
                You are viewing a {isLaterMonth ? "future" : "past"} month.{" "}
                <Button
                  // onClick={toggleType}
                  onClick={() => {
                    setDate(format(startOfMonth(new Date()), "yyyy-MM-dd"));
                  }}
                  className="gap-1 pl-4 pr-2"
                  size="sm"
                >
                  Go to current month
                  <ArrowRight size={16} className="" />
                </Button>
              </div>
            )}
          </div>
        </TwoColumnLayoutContainer>
      </MainContainer>
    </>
  );
}
