"use client";

import * as React from "react";
import { Card, CardContent } from "./../components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./../components/ui/carousel";
import clsx from "clsx";

export function MobileCardCarousel({ cards = [], customCardClass = "", opts }) {
  if (!cards.length) {
    return null;
  }

  return (
    <div className="w-full relative">
      <Carousel
        opts={
          opts || {
            align: "center",
            // loop: true,
            skipSnaps: true,
            inViewThreshold: 0.7,
          }
        }
        className="w-full "
      >
        <CarouselContent className="-ml-2">
          {cards.map((cardContent, index) => (
            <CarouselItem
              key={index}
              className={clsx(
                "pl-1 min-w-min last:mr-8 basis-auto",
                customCardClass
              )}
            >
              <div className="p-1">{cardContent}</div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="absolute left-4 top-1/2 -translate-y-1/2 -translate-x-1/2" />
        <CarouselNext className="absolute right-4 top-1/2 -translate-y-1/2 translate-x-1/2" />
      </Carousel>
    </div>
  );
}
