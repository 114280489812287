"use client";

import { useState, useMemo, useEffect } from "react";
import {
  Banknote,
  Check,
  ChevronsUpDown,
  Coins,
  Landmark,
  Pencil,
  Wallet2,
} from "lucide-react";

import { cn } from "utils/lib";
import { Button } from "components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { ScrollArea } from "components/ui/scroll-area";
import { Separator } from "./separator";
import { ArrowRight } from "feather-icons-react/build/IconComponents";
import { useNavigate } from "react-router-dom";
import useBreakpoint from "hooks/useBreakpoint";
import { Drawer, DrawerContent, DrawerTrigger } from "./drawer";

export function Combobox(props) {
  const {
    name = "item",
    onSelect = () => {},
    items = [],
    resetTrigger = "",
    managePage = "",
    manageText = "",
    className,
    defaultValue = "",
    edit = false,
  } = props;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const handleSelect = (currentValue) => {
    setValue(currentValue === value ? "" : currentValue);
    onSelect(currentValue === value ? "" : currentValue);
    setOpen(false);
  };

  const currentItem = useMemo(
    () => items?.find((item) => item.id === value),
    [items, value]
  );

  const navigate = useNavigate();

  const ICONS = {
    wallet: Wallet2,
    cash: Banknote,
    savings: Landmark,
    coins: Coins,
  };

  const { isTabletAndAbove } = useBreakpoint();

  if (isTabletAndAbove) {
    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild className={className} disabled={edit}>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
            tabIndex={0}
          >
            {value && currentItem ? (
              <div className="flex gap-x-2">
                <span>{currentItem.emoji}</span>
                {currentItem.name}
              </div>
            ) : (
              <span className="capitalize">{name}</span>
            )}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className=" p-0">
          <Command>
            <CommandInput placeholder={`Search ${name}...`} />
            <ScrollArea className="max-h-[200px] overflow-auto">
              <CommandEmpty>No {name} found.</CommandEmpty>
              <CommandGroup>
                {items.map((item) => {
                  const IconComponent = ICONS[item.icon] || Wallet2;
                  return (
                    <CommandItem
                      key={item.id}
                      value={item.name}
                      onSelect={() => handleSelect(item.id)}
                    >
                      <div className="flex justify-between w-full">
                        <div className="flex gap-x-2 items-center">
                          {item.emoji}
                          {name === "wallet" && <IconComponent className="" />}
                          <span>{item.name}</span>
                        </div>

                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            value === item.id ? "opacity-100" : "opacity-0"
                          )}
                        />
                      </div>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </ScrollArea>
            <Separator />
            <Button
              variant="ghost"
              size="sm"
              className="text-sm"
              onClick={() =>
                navigate(managePage, { state: { type: resetTrigger } })
              }
            >
              {manageText || `Manage ${name}`}
              <ArrowRight size={16} className="ml-2" />
            </Button>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild className={className} disabled={edit}>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {value && currentItem ? (
            <div className="flex gap-x-2">
              <span>{currentItem.emoji}</span>
              {currentItem.name}
            </div>
          ) : (
            <span className="capitalize">{name}</span>
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </DrawerTrigger>
      <DrawerContent className=" p-0 pb-4">
        <Command>
          <CommandInput placeholder={`Search ${name}...`} />
          <ScrollArea className="max-h-[50vh] overflow-auto">
            <CommandEmpty>No {name} found.</CommandEmpty>
            <CommandGroup>
              {items.map((item) => {
                const IconComponent = ICONS[item.icon] || Wallet2;
                return (
                  <CommandItem
                    key={item.id}
                    value={item.name}
                    onSelect={() => handleSelect(item.id)}
                    className="px-2 py-4"
                  >
                    <div className="flex justify-between w-full">
                      <div className="flex gap-x-4 items-center">
                        {item.emoji}
                        {name === "wallet" && (
                          <IconComponent className="h-4 w-4" />
                        )}
                        <span>{item.name}</span>
                      </div>

                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          value === item.id ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </div>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </ScrollArea>
          <Separator />
          <Button
            variant="ghost"
            size="sm"
            className="text-sm"
            onClick={() =>
              navigate(managePage, { state: { type: resetTrigger } })
            }
          >
            {manageText || `Manage ${name}`}
            <ArrowRight size={16} className="ml-2" />
          </Button>
        </Command>
      </DrawerContent>
    </Drawer>
  );
}
