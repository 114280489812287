import { Settings } from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useGlobalContext from "../contexts/globalContext";
import Card from "./card";
import ResolverInput from "./resolverInput";
import { TypographyP } from "./typography";
import {
  MessageSquarePlus,
  PiggyBankIcon,
  Rocket,
  Sparkle,
  Sparkles,
  SunMoonIcon,
  User,
  UserCircle2,
} from "lucide-react";
import { Button } from "./ui/button";
import { themeChange } from "theme-change";
import { CommandBar } from "./command-bar";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import { VersionChecker } from "./version-checker";
import clsx from "clsx";
export default function MainHeader({ onLoginPage }) {
  const { isLoggedIn } = useGlobalContext();
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const navigate = useNavigate();

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  if ("windowControlsOverlay" in navigator) {
    navigator.windowControlsOverlay.addEventListener(
      "geometrychange",
      debounce((e) => {
        // Detect if the Window Controls Overlay is visible.
        const isOverlayVisible = navigator.windowControlsOverlay.visible;

        // Get the size and position of the title bar area.
        // const titleBarRect = e.titlebarAreaRect;

        setIsOverlayVisible(isOverlayVisible);

        // console.log(`The overlay is ${isOverlayVisible ? 'visible' : 'hidden'}, the title bar width is ${titleBarRect.width}px`);
      }, 200)
    );
  }

  useEffect(() => {
    themeChange(false);
  }, []);

  return (
    <>
      <div className="max-w-7xl mx-auto">
        <VersionChecker />
      </div>
      <div
        className={clsx(
          " sticky z-[100]  backdrop-blur-lg bg-background/50 flex justify-between items-center  gap-5 text-primary pl-3",
          !isOverlayVisible ? "border-b" : "border-b-0"
        )}
        style={{
          left: "env(titlebar-area-x, 0)",
          top: "env(titlebar-area-y, 0)",
          width: "env(titlebar-area-width, 100%)",
          height: "env(titlebar-area-height, 60px)",
          // -webkit-app-region: drag;
          webkitAppRegion: "drag",
        }}
      >
        <div className="flex items-center justify-between max-w-7xl mx-auto w-full">
          <Button
            variant="transparent"
            className=" px-0 items-center flex gap-3"
            onClick={() => navigate("/")}
          >
            <PiggyBankIcon size={25} />
            <div className="flex h-12 items-center font-bold  leading-none whitespace-nowrap text-foreground">
              {/* <div className="pr-2 text-xl font-caveat w-0 scale-x-0 overflow-hidden group-hover:scale-x-100 group-hover:w-auto transition-all ease-out">
                Ang
              </div> */}
              <div className="pr-2 group-hover:hidden font-bold  items-center">
                Budget <span className="opacity-60">Baboy</span>
              </div>
              {/* <div className='pr-2 hidden group-hover:inline'>
                Budget Tracker App
              </div>
              <div className="pr-2 text-xl font-caveat w-0 scale-x-0 overflow-hidden group-hover:scale-x-100 group-hover:w-auto transition-all ease-out">
                na walang maisip na pangalan™️
              </div> */}
            </div>
          </Button>
          <div className="flex items-center gap-2">
            {onLoginPage && (
              <>
                <Button
                  variant="ghost"
                  size="icon"
                  data-toggle-theme="dark,light"
                  data-act-class="ACTIVECLASS"
                >
                  <SunMoonIcon size={18} />
                </Button>
                <Button
                  variant="secondary"
                  size=""
                  onClick={() => navigate("/")}
                >
                  Join the waitlist
                </Button>
              </>
            )}
            {!onLoginPage && (
              <>
                {isLoggedIn ? (
                  <div
                    className={clsx(
                      "flex items-center",
                      !isOverlayVisible ? "w-full" : ""
                    )}
                    style={{
                      webkitAppRegion: "no-drag",
                    }}
                  >
                    <CommandBar />

                    <Button
                      variant="ghost"
                      size="icon"
                      className="rounded-full"
                      data-canny-link
                      target="_blank"
                      href="https://the-baboy-collective.canny.io/budget-baboy"
                    >
                      <MessageSquarePlus size={20} />
                    </Button>
                    {/* <Button variant="secondary" size="icon" className="rounded-full" onClick={() => navigate('/app/settings')}><User size={20} /></Button> */}
                  </div>
                ) : (
                  <>
                    <div className=" items-center gap-2 hidden md:flex">
                      <div className="flex items-center gap-4 uppercase text-sm font-semibold mr-4">
                        <Button variant="link-hover" size="sm" href="#faq">
                          FAQs
                        </Button>
                        <Button variant="link-hover" size="sm" href="#team">
                          The Team
                        </Button>
                        <Button variant="link-hover" size="sm" href="#waitlist">
                          Join the waitlist
                        </Button>
                      </div>
                    </div>
                    <Button
                      variant=""
                      className="mr-4"
                      onClick={() => navigate("/login")}
                    >
                      LOGIN
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
