import { IconComponent } from "components/icon";
import { Button } from "components/ui/button";
import { Card, CardHeader, CardTitle } from "components/ui/card";
import {
  Activity,
  BarChart2,
  Database,
  DollarSign,
  Grid,
  List,
  MinusSquare,
  MoreHorizontal,
  Package,
  PlusSquare,
  RefreshCcw,
  Settings,
} from "feather-icons-react/build/IconComponents";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function More() {
  const navigate = useNavigate();
  const [seeMore, setSeeMore] = useState(false);

  const shortcutButtons = [
    {
      location: "create-entry",
      icon: "plus-circle",
      label: "Income",
      state: { type: "income" },
    },
    {
      location: "create-entry",
      icon: "minus-circle",
      label: "Expense",
      state: { type: "expense" },
    },
    {
      location: "create-entry",
      icon: "transfer",
      label: "Transfer",
      state: { type: "transfer" },
    },
    {
      location: "entries",
      icon: "book-heart",
      label: "All Entries",
    },
    {
      location: "wallets",
      icon: "wallet",
      label: "Wallets",
    },
    {
      location: "reports/monthly-spending",
      icon: "pie",
      label: "Reports",
    },
    // {
    //   location: "stash",
    //   icon: "bookmark",
    //   label: "Later",
    // },
    {
      location: "categories",
      icon: "shapes",
      label: "Categories",
    },
    {
      location: "settings",
      icon: "settings",
      label: "Settings",
    },
  ];

  return (
    <Card className="">
      <CardHeader>
        <CardTitle className="py-2 text-center">Shortcuts</CardTitle>
      </CardHeader>
      <div className="px-4 pb-4">
        {shortcutButtons.map((button, index) => (
          <button
            key={index}
            variant="ghost"
            className="group gap-2 flex-col justify-center inline-flex items-center p-2 w-1/4 md:w-1/5"
            // className={`btn btn-xs btn-ghost gap-2 flex-col justify-start p-2 h-20 w-1/4 ${button.type === 'entries' && 'btn-active'}`}
            onClick={() => navigate(button.location, { state: button.state })}
          >
            <div className="block h-10 w-10 flex items-center justify-center rounded-full bg-primary/80 group-hover:bg-primary transition-all">
              <IconComponent
                name={button.icon}
                className={`text-primary-foreground group-hover:text-primary-foreground`}
              />
            </div>

            <span className="text-xs ">{button.label}</span>
          </button>
        ))}
      </div>
    </Card>
  );
}
