import classNames from 'classnames';
import { ArrowLeft, ChevronLeft } from 'feather-icons-react/build/IconComponents'
import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate, useNavigation } from 'react-router-dom'

export default function NavHeader({ title = 'Header', navigateTo, rightComponent, isPage = false, handlers = {}, navigateState = {} }) {
  const navigate = useNavigate();

  const handleNavigate =
    () => navigate(navigateTo || -1, navigateState);
  // console.log('navigateState', navigateState)
  // useHotkeys('esc', handleNavigate, { preventDefault: true, enableOnFormTags: true });
  return (
    <div className={classNames(
      "flex gap-4 text-xl items-center p-3  sm:px-6 h-16   backdrop-blur-lg bg-base-100/50 rounded-xl z-50 w-full  ",
      isPage ? "fixed top-0 left-0" : "py-0 sm:py-0 "
    )} {...handlers}>
      <button className='btn min-w-max btn-ghost max-lg:btn-circle' onClick={handleNavigate}>
        <ChevronLeft />
        <span className='hidden lg:block'>Back</span>

      </button>
      <h2 className='flex-1'>{title}</h2>
      {rightComponent}
    </div>
  )
}
