import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import ListItem from "components/listItem";
import MainHeader from "components/mainHeader";
import { TypographyH1, TypographyH2 } from "components/typography";
import { Button } from "components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { DialogFooter, DialogHeader } from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Skeleton } from "components/ui/skeleton";
import { useOnboarding } from "contexts/onboardingContext";
import EmojiPicker from "emoji-picker-react";
import {
  ArrowLeft,
  ArrowRight,
  Check,
  Edit2,
  Minus,
  Plus,
  Shapes,
  Trash,
  X,
} from "lucide-react";
import { add } from "mathjs";
import { useState } from "react";
import { set } from "date-fns";

const randomId = () => Math.random().toString(36).substring(2, 11);

const EditableListItem = ({
  placeholder = "List item",
  saveAction = () => {},
  deleteAction = () => {},
  id,
  type,
  emoji,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(placeholder);
  const [selectedEmoji, setSelectedEmoji] = useState(emoji);
  const [showPicker, setShowPicker] = useState(false);
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDelete = () => {
    deleteAction(id);
  };

  const handleSave = () => {
    setIsEditing(false);
    saveAction({ name: value, id, type, emoji: selectedEmoji });
  };

  const handleCancel = () => {
    setIsEditing(false);
    setValue(placeholder); // Reset to original value
  };

  const onEmojiClick = (emojiObject) => {
    setSelectedEmoji(emojiObject.emoji);
    setShowPicker(false);
  };

  return (
    <li className="flex items-center gap-4 p-2 border rounded-md mt-3">
      {isEditing ? (
        <>
          <Dialog
            open={showPicker}
            onOpenChange={setShowPicker}
            className="shrink-0"
          >
            <DialogTrigger asChild className="shrink-0">
              <Button
                variant="secondary"
                size="icon"
                className="rounded-full outline outline-1"
              >
                {selectedEmoji || (
                  <Shapes className="stroke-primary" size={18} />
                )}
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>Pick an icon</DialogTitle>
              </DialogHeader>
              <EmojiPicker
                onEmojiClick={onEmojiClick}
                emojiStyle="native"
                skinTonesDisabled
                suggestedEmojisMode=""
              />
              <DialogFooter>
                <DialogClose asChild>
                  <Button className="btn btn-error mr-2">Done</Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <Input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="flex-grow"
            autoFocus
            // on keydown, if enter, save
            // if esc, cancel
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSave();
              } else if (e.key === "Escape") {
                handleCancel();
              }
            }}
          />

          <div className="flex gap-2 shrink-0">
            <Button
              onClick={handleSave}
              size="icon"
              variant="ghost"
              aria-label="Save"
            >
              <Check className="h-4 w-4" />
            </Button>
            <Button
              onClick={handleCancel}
              size="icon"
              variant="ghost"
              aria-label="Cancel"
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </>
      ) : (
        <>
          <Button
            variant="secondary"
            size="icon"
            className="rounded-full disabled:opacity-100 shrink-0"
            disabled
          >
            {selectedEmoji || <Shapes className="stroke-primary" size={18} />}
          </Button>
          <span className="flex-grow truncate">{value}</span>
          <div className="flex gap-2">
            <Button
              onClick={handleEdit}
              size="icon"
              variant="secondary"
              aria-label="Edit"
            >
              <Edit2 className="h-4 w-4" />
            </Button>
            <Button
              onClick={handleDelete}
              size="icon"
              variant="secondary"
              aria-label="Edit"
            >
              <Trash className="h-4 w-4" />
            </Button>
          </div>
        </>
      )}
    </li>
  );
};

export default function Categories() {
  const { categories, addCategory, deleteCategory, setActiveTab } =
    useOnboarding();

  const saveCategory = (category) => {
    addCategory(category);
  };

  const createNewCategory = (type) => {
    addCategory({
      name: "New category",
      emoji: "",
      id: randomId(),
      type,
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl">Setting up Categories</CardTitle>
        <p className="text-foreground text-sm">
          First, let's set up your income and expense categories. Create custom
          categories or use the default ones we provide for you. You can edit
          these later.
        </p>
      </CardHeader>
      <hr className="my-6 mb-3" />
      <CardContent>
        <CardTitle>Income categories</CardTitle>
        {categories
          .filter((category) => category.type === "income")
          .map((category) => {
            return (
              <EditableListItem
                id={category.id}
                key={category.id}
                type={category.type}
                placeholder={category.name}
                emoji={category.emoji}
                saveAction={saveCategory}
                deleteAction={deleteCategory}
              />
            );
          })}
        <Button
          variant="secondary"
          className="mt-2 w-full"
          onClick={() => createNewCategory("income")}
        >
          Add income category
          <Plus size={18} />
        </Button>

        <hr className="my-6" />
        <CardTitle>Expense categories</CardTitle>
        {categories
          .filter((category) => category.type === "expense")
          .map((category) => {
            return (
              <EditableListItem
                id={category.id}
                key={category.id}
                type={category.type}
                placeholder={category.name}
                emoji={category.emoji}
                saveAction={saveCategory}
                deleteAction={deleteCategory}
              />
            );
          })}
        <Button
          variant="secondary"
          className="mt-2 w-full"
          onClick={() => createNewCategory("expense")}
        >
          Add expense category
          <Plus size={18} />
        </Button>
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button variant="ghost" onClick={() => setActiveTab("welcome")}>
          <ArrowLeft size={20} />
          Back
        </Button>
        <Button size="lg" onClick={() => setActiveTab("wallets")}>
          Next
          <ArrowRight size={20} />
        </Button>
      </CardFooter>
    </Card>
  );
}
