import useGlobalContext from "../contexts/globalContext";
import { useEffect, useState } from "react";
import { pb } from "../utils/pbConfig";
import classNames from "classnames";
import LoadingOverlay from "../components/loadingOverlay";
import Wallets from "./wallets";
import TwoColumnLayoutContainer from "../layout/twoColumnLayoutContainer";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import MainContainer from "components/main-container";
import Transaction from "components/transaction";
import MainHeader from "components/mainHeader";
import { Sidebar } from "components/sidebar";
import { TypographyH2 } from "components/typography";
import { Button } from "components/ui/button";
import {
  Info,
  MoreHorizontalIcon,
  RefreshCw,
  RocketIcon,
  Sparkle,
  Sparkles,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { EntryDialog } from "components/entry-dialog";
import { Skeleton } from "components/ui/skeleton";
import { Alert, AlertDescription, AlertTitle } from "components/ui/alert";
import { optimizedFetch } from "utils/helpers";
import { clsx } from "clsx";

export default function Stash() {
  const { wallets, categories, stash, setStash, getStash, getEntries } =
    useGlobalContext();
  // const [localStash, setLocalStash] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleStashSelect = (entry) => {
    const prevState = [...stash];
    const selectedEntryIndex = prevState.findIndex((e) => e.id === entry.id);
    prevState[selectedEntryIndex].selected =
      !prevState[selectedEntryIndex].selected;
    setStash(prevState);
  };

  const selectedStashLength = stash.filter((e) => e.selected).length;

  const deselectAll = () => {
    const prevState = [...stash];
    const newState = prevState.map((e) => {
      e.selected = false;
      return e;
    });
    setStash(newState);
  };

  const selectAll = () => {
    const prevState = [...stash];
    const newState = prevState.map((e) => {
      e.selected = true;
      return e;
    });
    setStash(newState);
  };

  const recordAllSelectedEntry = async () => {
    if (
      window.confirm("Are you sure you want to record all selected entries?") ==
      true
    ) {
      try {
        const selectedEntries = stash.filter((e) => e.selected);
        const selectedEntriesIds = selectedEntries.map((e) => e.id);
        setLoading(true);
        for (const id of selectedEntriesIds) {
          await pb.collection("entries").update(id, {
            type: "",
          });
        }
      } catch (error) {
        console.log("error :>> ", error);
        alert("Something went wrong");
        setLoading(false);
      } finally {
        await getStash(true);
        await getEntries(true);
        setLoading(false);
      }
    }
  };

  const deleteAllSelectedEntry = async () => {
    if (
      window.confirm("Are you sure you want to delete all selected entries?") ==
      true
    ) {
      try {
        const selectedEntries = stash.filter((e) => e.selected);
        const selectedEntriesIds = selectedEntries.map((e) => e.id);
        setLoading(true);
        for (const id of selectedEntriesIds) {
          await pb.collection("entries").delete(id);
        }
      } catch (error) {
        console.log("error :>> ", error);
        alert("Something went wrong");
        setLoading(false);
      } finally {
        await getStash(true);
      }
    }
  };

  // useHotkeys('ctrl+enter', createEntry, { preventDefault: true, enableOnFormTags: true })
  // @TODO Refactor and move out in here
  const FloatingMenu = () => (
    <Card
      className={classNames(
        "flex justify-between items-center bg-background p-2 px-6 shadow-lg z-100 fixed bottom-6 left-1/2 -translate-x-1/2 max-w-md w-[98%] rounded-full transition-all ease-in-out delay-200 ",
        selectedStashLength > 0
          ? "opacity-100 pointer-events-auto translate-y-0"
          : "opacity-0 -translate-y-full pointer-events-none"
      )}
    >
      <div className="flex gap-2 items-center">
        <Info size={12} />
        <div>
          <h3 className="font-bold">{selectedStashLength} selected</h3>
          {/* <div className="text-xs">You have 1 unread message</div> */}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Button size="sm" onClick={recordAllSelectedEntry}>
          {/* <CheckCircle/> */}
          Record
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button size="sm">
              <MoreHorizontalIcon size={16} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem onClick={selectAll}>
                Select All
                <DropdownMenuShortcut>⌘A</DropdownMenuShortcut>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={deselectAll}>
                Deselect All
                <DropdownMenuShortcut>⌘⇧A</DropdownMenuShortcut>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={deleteAllSelectedEntry}>
                Delete All
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </Card>
  );

  useEffect(() => {
    getStash(false);
  }, []);

  const groupEntriesByWallet = stash.reduce((groups, entry) => {
    const walletId = entry.wallet;
    if (!groups[walletId]) {
      groups[walletId] = [];
    }
    const data = {
      ...entry,
      type: categories.find((c) => c.id === entry.category)?.type,
    };
    groups[walletId].push(data);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupEntries = Object.keys(groupEntriesByWallet).map((wallet) => {
    const walletId = wallet;
    const walletName = wallets.find((w) => w.id === walletId)?.name;
    const entries = groupEntriesByWallet[wallet];

    console.log("entries :>> ", entries);
    const incomeEntries = entries.filter((e) => e.type === "income");
    const expenseEntries = entries.filter((e) => e.type === "expense");
    const income = incomeEntries.reduce((a, b) => a + (b["amount"] || 0), 0);
    const expense = expenseEntries.reduce((a, b) => a + (b["amount"] || 0), 0);

    return {
      walletId,
      walletName,
      entries,
      incomeEntries,
      expenseEntries,
      income,
      expense,
    };
  });
  console.log("groupEntries :>> ", groupEntries);

  const showNoStashText = !loading && stash && stash.length <= 0;

  return (
    <>
      {/* <MainHeader /> */}
      <MainContainer>
        {/* <Sidebar /> */}
        <TwoColumnLayoutContainer
          variant="sidebarRight"
          navComponents={
            <div className="flex items-center justify-between w-full">
              <TypographyH2>Stash + Projections</TypographyH2>
              <EntryDialog callback={() => getStash(true)} />
            </div>
          }
          rightComponents={<Wallets isProjection projections={groupEntries} />}
          leftComponents={
            <>
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center justify-between">
                    Upcoming Entries
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        setLoading(true);
                        getStash(true, () => {
                          console.log("this");
                          setLoading(false);
                        });
                      }}
                      disabled={loading}
                    >
                      <RefreshCw
                        size={18}
                        className={clsx(loading && "animate-spin")}
                      />
                    </Button>
                  </CardTitle>
                </CardHeader>
                {showNoStashText && (
                  <CardContent>
                    <p className="p-6 text-center text-muted-foreground text-sm">
                      No stashed entries yet.
                    </p>
                  </CardContent>
                )}
                {!!loading && (
                  <>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <div
                        className="flex gap-4 items-center px-6 mb-4"
                        key={index}
                      >
                        <Skeleton className="h-4 w-4" />
                        <Skeleton className="h-10 w-10 rounded-full" />
                        <Skeleton className="h-12 flex-1" />
                      </div>
                    ))}
                  </>
                )}
                <div>
                  {!loading &&
                    stash.length > 0 &&
                    stash.map((entry) => (
                      <Transaction
                        clickable
                        selectable
                        selected={entry.selected}
                        handleSelect={() => handleStashSelect(entry)}
                        // hideDate
                        stashedEntry
                        data={entry}
                        key={entry.id}
                      />
                    ))}
                </div>
              </Card>
              {showNoStashText && (
                <Alert>
                  <Sparkles className="h-4 w-4" />
                  <AlertTitle>Stashed entry?</AlertTitle>
                  <AlertDescription>
                    Stash entries are used to plan your upcoming expenses and
                    income. You can stash entries from the{" "}
                    <strong>Create new stash</strong> button above.
                  </AlertDescription>
                </Alert>
              )}
            </>
          }
        />

        <FloatingMenu />
      </MainContainer>
    </>
  );
}
