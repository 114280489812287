import { addMinutes } from "date-fns";
import { format } from "date-fns/esm";
import { pb } from "./pbConfig";

const getDBDate = (date) => {
  const output = format(
    addMinutes(date, -date.getTimezoneOffset()),
    "yyyy-MM-dd HH:mm:ss"
  );
  return output;
};

// function to save the data to the local storage
const saveToLocalStorage = (key, value) => {
  const data = {
    [key]: {
      data: value,
      timestamp: new Date().getTime(),
    },
  };
  // convert the value to a string
  const valueAsString = JSON.stringify(data);

  // save the value to the local storage
  localStorage.setItem(key, valueAsString);
};

const clearLocalStorage = () => {
  localStorage.clear();
};

const getFromLocalStorage = (key) => {
  // get the value from the local storage
  const value = localStorage.getItem(key);

  // if the value is null, return null
  if (!value) return null;

  // convert the value to an object
  const parsedValue = JSON.parse(value);

  // return the parsed value
  return parsedValue[key];
};

// only for initial load
const optimizedFetch = ({
  key,
  collection,
  stateSetter,
  perPage = 100,
  options,
  force,
  callback,
}) => {
  const previousData = getFromLocalStorage(key);

  // console.log('force :>> ', force);
  if (previousData?.data && !force) {
    // console.log('this happened');
    stateSetter(previousData?.data);
    if (callback) {
      callback();
    }
    return;
  }

  pb.collection(collection)
    .getList(1, perPage, options)
    .then((records) => {
      // console.log(`${key} :>> `, records.items);
      saveToLocalStorage(key, records.items);
      stateSetter(records.items);
      if (callback) {
        callback();
      }
    });
};

const handleError = (error, toast) => {
  const data = error?.data?.data;
  const mainMessage = error?.message;
  const keys = Object.keys(data);
  const message =
    data &&
    keys
      .map((key) => {
        return `${key.charAt(0).toUpperCase() + key.slice(1)}: ${
          data[key].message
        } \n`;
      })
      .join();
  toast({
    variant: "destructive",
    title: mainMessage || "Something went wrong",
    description: message,
  });

  console.error(error);
};

export {
  getDBDate,
  saveToLocalStorage,
  getFromLocalStorage,
  clearLocalStorage,
  optimizedFetch,
  handleError,
};
