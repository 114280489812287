// custom hook to check the breakpoint

// custom hook that returns current breakpoint from tailwind config without using next-themes

import { useMediaQuery } from 'react-responsive';

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};


export default function useBreakpoint() {

  const isMobile = useMediaQuery({ maxWidth: breakpoints.sm - 1 });
  const isTablet = useMediaQuery({ minWidth: breakpoints.sm, maxWidth: breakpoints.md - 1 });
  const isLaptop = useMediaQuery({ minWidth: breakpoints.md, maxWidth: breakpoints.lg - 1 });
  const isDesktop = useMediaQuery({ minWidth: breakpoints.lg });
  const isTabletAndAbove = useMediaQuery({ minWidth: breakpoints.sm });

  return {
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    isTabletAndAbove,
  };

}



